<template>
  <div>
    <span class="title-stepper" v-text="'Étape 4 - Synthèse et validation'" />
    <ValidateForm :showFieldObl="false">
      <v-row>
        <v-col class="col-4">
          <div class="d-flex align-center justify-space-between mb-4">
            <span class="text-subtitle">Informations générales</span>
            <v-icon color="#88AEF6" @click.stop="isGeneralInfoReadOnly = false">
              mdi-square-edit-outline</v-icon
            >
          </div>
          <div class="container-label">
            <span class="label"> Nom de la structure</span>
            <InputText
              v-model="generalInfo.name"
              :readonly="isGeneralInfoReadOnly"
            />
          </div>
          <div class="container-label">
            <span class="label"> Numero de SIRET</span>
            <InputText
              v-model="generalInfo.siretNumber"
              :readonly="isGeneralInfoReadOnly"
            />
          </div>
          <div class="container-label">
            <span class="label"> Chiffre d'affaire</span>
            <InputText
              v-model="generalInfo.CA"
              :readonly="isGeneralInfoReadOnly"
            />
          </div>
        </v-col>
        <v-col class="col-4">
          <div class="d-flex align-center justify-space-between mb-4">
            <span class="text-subtitle">Représentant de la structure</span>
            <v-icon
              color="#88AEF6"
              @click.stop="isRepresentantInfoReadOnly = false"
            >
              mdi-square-edit-outline</v-icon
            >
          </div>
          <div class="container-label">
            <span class="label"> Nom</span>
            <InputText
              v-model="representantInfo.representantFirstName"
              :readonly="isRepresentantInfoReadOnly"
            />
          </div>
          <div class="container-label">
            <span class="label"> Prénom</span>
            <InputText
              v-model="representantInfo.representantLastName"
              :readonly="isRepresentantInfoReadOnly"
            />
          </div>
          <div class="container-label">
            <span class="label"> Email</span>
            <InputText
              v-model="representantInfo.representantEmail"
              :readonly="isRepresentantInfoReadOnly"
            />
          </div>
        </v-col>
        <v-col class="col-4">
          <div class="d-flex align-center justify-space-between mb-4">
            <span class="text-subtitle">Adresse de correspondance</span>
            <v-icon
              color="#88AEF6"
              @click.stop="isRepresentantAdressReadOnly = false"
            >
              mdi-square-edit-outline</v-icon
            >
          </div>
          <div class="container-label">
            <span class="label"> Adresse</span>
            <InputText
              v-model="representantInfo.representantAdress"
              :readonly="isRepresentantAdressReadOnly"
            />
          </div>
          <div class="container-label">
            <span class="label"> Complément</span>
            <InputText
              v-model="representantInfo.representantAdressSupp"
              :readonly="isRepresentantAdressReadOnly"
            />
          </div>
          <div class="container-label">
            <span class="label"> Code postal</span>
            <InputText
              v-model="representantInfo.representantCP"
              :readonly="isRepresentantAdressReadOnly"
            />
          </div>
          <div class="container-label">
            <span class="label">Ville</span>
            <InputText
              v-model="representantInfo.representantCountry"
              :readonly="isRepresentantAdressReadOnly"
            />
          </div>
        </v-col>
      </v-row>
      <div class="d-flex align-center">
        <span class="text-subtitle">Description de la transaction</span>
        <v-divider class="mx-3" color="#DFE5F9"></v-divider>
      </div>
      <div
        class="text-original text2 text3 d-flex align-center justify-space-between my-3"
        style="width: 500px;"
      >
        <span class="mr-4"> Objet de la transaction</span>
        <span
          class="text4"
          v-text="`Affiliation pour la saison ${generalInfo.seasonValid}`"
        />
      </div>
      <div
        class="text-original text2 text3 d-flex align-center justify-space-between my-3"
        style="width: 500px;"
      >
        <span>Montant: </span>
        <span class="text4" style="color: #082b6e;">{{ tarifAffiliation / 100 }} €</span>
      </div>
      <div
        class="text-original text2 text3 d-flex align-center justify-space-between my-3"
        style="width: 500px;"
      >
        <span>Frais bancaires: </span>
        <span class="text4" style="color: #082b6e;">{{ commission / 100 }} € </span>
      </div>
      <div style="position: relative; height: 58px;">
        <div class="text-original alert-msg d-flex align-center mb-2">
          <span v-text="'Information :'" class="font-weight-bold mx-2" />
          <span
            v-text="
              'Suite à la validation du dossier d\'affiliation, vous receverez des instructions pour procéder au paiement par carte bancaire'
            "
          />
        </div>
      </div>
      <div class="d-flex align-center ml-3 flex-wrap">
        <v-checkbox style="margin: 0px !important;" v-model="isAccepted" />
        <span class="text-subtitle font-weight-normal text3"
          >Je soussigné,
        </span>
        <InputText class="mx-1" v-model="fullName" style="min-width: 230px;" />
        <span class="text-subtitle font-weight-normal text3"
          >, atteste l'exactitude des informations portées sur ce formulaire et
          demande l'affiliation à la FFME.</span
        >
      </div>

      <v-row class="my-4" no-gutters>
        <v-btn
          text
          class="btn btn-gray btn-font"
          @click.prevent="$emit('onPrevious')"
          >Étape précédente</v-btn
        >
        <v-btn
          class="btn btn-primary btn-font"
          style="margin-left: auto;"
          :disabled="!isAccepted"
          @click="$emit('onNext')"
          >Confirmer les informations et valider la demande d'affiliation</v-btn
        >
      </v-row>
    </ValidateForm>
  </div>
</template>

<script>
import InputText from "@/components/utils/input";
import ValidateForm from "@/components/utils/form";
import {mapGetters, mapActions, mapState} from "vuex";
import {
  PRODUCT_AFFILIATION_MA,
  OPTION_AFFILIATION_0,
  getPrices
} from "@/plugins/productService";

export default {
  components: { ValidateForm, InputText },
  data: () => ({
    generalInfo: {},
    isAccepted: false,
    representantInfo: {},
    fullName: "",
    isGeneralInfoReadOnly: true,
    isRepresentantInfoReadOnly: true,
    isRepresentantAdressReadOnly: true,
    tarifStructure: null,
  }),
  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapState({
      MAaffiliation: (state) => state.structure.MAaffiliation,
    }),
    tarifAffiliation() {
      return this.tarifStructure;
    },
    commission() {
      return Math.ceil((this.tarifAffiliation * 0.0072) + 16);
    },
  },
  created() {
    this.setData();
  },
  methods: {
    ...mapActions("basics", ["getCurrentAffiliationTarif"]),

    async setData() {
      this.generalInfo = {...this.MAaffiliation.structure};
      this.representantInfo = {...this.MAaffiliation.contact};
      this.fullName = `${this.representantInfo.representantFirstName} ${this.representantInfo.representantLastName}`;

      let prices = await getPrices({
        'seasonId': parseInt(this.generalInfo.seasonValid, 10),
        'structureId': 1318,
        'productId': PRODUCT_AFFILIATION_MA,
        'options': OPTION_AFFILIATION_0
      });

      this.tarifStructure = prices.reduce((carry, price) => carry + price.value, 0);

      this.$forceUpdate()
    },
  },
};
</script>

<style scopped>
.container-label {
  position: relative;
}
.label {
  position: absolute;
  bottom: 40px;
  font-size: 12px;
  color: #e0e5ed;
  font-weight: bold;
}
.alert-msg {
  position: absolute;
  width: 100%;
  height: 40px;
  color: #3182ce !important;
  background: #bee3f8;
  border-left: 2px solid #3182ce;
  font-size: 14px !important;
}
</style>
