<template>
  <div v-if="!isLoading">
    <h3>Etape 4 - Récapitulatif</h3>
    <ValidateForm @validated="next" :showFieldObl="false">
      <v-row>
        <v-col cols="12">
          <h5>
            Coordonnées -
            <a
              href=""
              style="font-size: 0.8rem"
              @click.prevent="$emit('goToStep', 1)"
            >
              Modifier</a
            >
          </h5>
          <v-row>
            <v-col cols="12" md="6">
              <div>
                <span class="font-weight-bold">Nom: </span> {{ user.CT_Nom }}
              </div>
              <div>
                <span class="font-weight-bold">Prénom: </span>
                {{ user.CT_Prenom }}
              </div>
              <div>
                <span class="font-weight-bold">Date de naissance: </span>
                {{ $moment(user.DN_DateNaissance).format("DD/MM/YYYY") }}
              </div>
              <div>
                <span class="font-weight-bold">Sexe: </span> {{ civilite }}
              </div>
              <div>
                <span class="font-weight-bold">Nationalité: </span>
                {{ paysNationalite.NomPays }}
                <country-flag
                  v-if="paysNationalite"
                  style="margin: -16px"
                  :country="paysNationalite.CodePays"
                  size="small"
                />
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div>
                <span class="font-weight-bold">Adresse: </span>
                {{ user.ADR_Adresse1 }}
              </div>
              <div>
                <span class="font-weight-bold">Complément adresse: </span>
                {{ user.ADR_Adresse2 }}
              </div>
              <div>
                <span class="font-weight-bold">Code postal: </span>
                {{ user.ADR_CodePostal }}
              </div>
              <div>
                <span class="font-weight-bold">Ville: </span>
                {{ user.ADR_Ville }}
              </div>
              <div>
                <span class="font-weight-bold">Departement: </span>
                {{ userDepartement }}
              </div>
              <div>
                <span class="font-weight-bold">Pays: </span>
                {{ paysAdresse.NomPays }}
                <country-flag
                  v-if="paysAdresse"
                  style="margin: -16px"
                  :country="paysAdresse.CodePays"
                  size="small"
                />
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <div>
                <span class="font-weight-bold">Téléphone fixe: </span>
                {{ user.TelephoneFixe }}
              </div>
              <div>
                <span class="font-weight-bold">Téléphone mobile: </span>
                {{ user.TelephoneMobile }}
              </div>
              <div>
                <span class="font-weight-bold">Courriel: </span>
                {{ user.CT_Email }}
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <h5>
            Licence et Assurances -
            <a
              href=""
              style="font-size: 0.8rem"
              @click.prevent="$emit('goToStep', 2)"
              >Modifier</a
            >
          </h5>
          <v-row>
            <v-col cols="12" md="12">
              <div v-if="licenceType">
                <span class="font-weight-bold">Type de licence: </span>
                {{ licenceType.label }}
                ({{ prices.find((price) => price.product && price.product.id === licenceType.id && price.type === 'principal' ).value / 100 }}
                €)
              </div>
              <div v-if="assurance" class="mb-3">
                <span class="font-weight-bold">Assurance: </span>
                <v-chip small class="mx-1" color="blue" text-color="white">
                  {{
                    assurance.label
                  }}
                  ({{ prices.find((price) => price.option && price.option.id === assurance.id).value / 100 }}
                  €)
                </v-chip>
              </div>
              <div v-if="assuranceOptions">
                <span class="font-weight-bold">Option(s) d'assurance: </span>
                <span>
                  <optionAssuranceChips
                    v-for="(option) in assuranceOptions"
                    :key="option.id"
                    :option="option"
                    :price="prices.find((price) => price.option && price.option.id === option.id)"
                  />
                </span>
              </div>
              <div class="mt-2">
                <span class="font-weight-bold">MONTANT PANIER: </span>
                <span class="font-weight-bold"> {{ montantTotal / 100 }} € </span>
              </div>
              <div class="mt-2">
                <span class="font-weight-bold">FRAIS BANCAIRES: </span>
                <span class="font-weight-bold">
                  {{ commissionLemonway / 100 }} €
                </span>
              </div>
              <div class="mt-2" v-if="cotisationCT > 0">
                <span class="font-weight-bold">Cotisation CT: </span>
                <span class="font-weight-bold">
                  {{ cotisationCT / 100 }} €
                </span>
              </div>
              <div class="mt-2" v-if="cotisationLigue > 0">
                <span class="font-weight-bold">Cotisation Ligue: </span>
                <span class="font-weight-bold">
                  {{ cotisationLigue / 100 }} €
                </span>
              </div>
              <div class="mt-2" v-if="cotisationClub > 0">
                <span class="font-weight-bold">Cotisation licence HORS CLUB : </span>
                <span class="font-weight-bold">
                  {{ cotisationClub / 100 }} €
                </span>
              </div>
              <div class="mt-2">
                <span class="font-weight-bold">MONTANT TOTAL: </span>
                <span class="font-weight-bold"> {{ totalPanier / 100 }} € </span>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <h5>
            Certificat médical -
            <a
              href=""
              style="font-size: 0.8rem"
              @click.prevent="$emit('goToStep', 3)"
              >Modifier</a
            >
          </h5>
          <v-row>
            <v-col v-if="user.file" cols="12">
              <div>
                <span class="font-weight-bold">Type de certificat: </span
                >{{ user.file.cmSelected }}
              </div>
              <!-- <div>
                <span class="font-weight-bold">Certificat: </span>{{ user.file.file.name }}
              </div> -->
              <div v-if="user.file.ID_DocumentType !== 60">
                <span class="font-weight-bold"
                  >Date de délivrance du certificat médical: </span
                >{{ $moment(user.file.validationDate).format("DD/MM/YYYY") }}
              </div>

              <v-alert
                v-if="
                  $moment(user.file.validationDate) <
                  $moment().subtract(1, 'years')
                "
                border="top"
                color="red lighten-2"
                dark
                class="mt-2"
              >
                Le certificat doit dater de moins d'un an
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-checkbox style="width: 100%" v-model="confirm">
            <template v-slot:label>
              <div>
                En cochant cette case, je reconnais avoir pris connaissance de
                la

                <a
                  target="_blank"
                  :href="noticeTemplate"
                  @click.stop
                >
                  notice d'information d'assurance
                </a>

                de la FFME et d'en accepter les termes sans aucune réserve.
              </div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-4">
        <v-btn text class="btn btn-gray" @click.prevent="$emit('onPrevious')"
          >Retour</v-btn
        >
        <v-btn
          type="submit"
          class="btn btn-primary ml-auto"
          :loading="loading"
          :disabled="!canContinue"
          >Valider</v-btn
        >
      </v-row>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import { mapActions, mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";
import optionAssuranceChips from "./assuranceChips.vue";
import {
  PRODUCT_CATEGORY_LICENCE,
  getProducts,
  getOptions,
  getPrices
} from "@/plugins/productService";
import {
  getStructuresFromZipcode,
} from "@/plugins/structureService";


export default {
  components: { ValidateForm, CountryFlag, optionAssuranceChips },

  data: () => ({
    isLoading: true,
    confirm: false,
    loading: false,
    dataOpt: null,
    listMontant: [],
    noticeTemplate: null,
    products: [],
    options: [],
    prices: [],
  }),

  async created() {
    await this.setData();

    let payload = {
      ID_Saison: this.currentSaison.id,
      SlugNomTemplate: "Notice_assurance",
    };

    const template = await this.getTemplatesBySlug(payload);

    if (template.length) {
      this.noticeTemplate = template[0]?.CheminDocument;
    }

    this.isLoading = false;
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("saisons", ["currentSaison"]),

    assuranceOption() {
      return this.filterTarifs.find(
        (tarif) => tarif.LIST_TarifCategorie.AssuranceType.id == this.dataOpt
      );
    },
    canContinue() {
      if (
        this.confirm &&
        this.$moment(this.user.file.validationDate) >
          this.$moment().subtract(1, "years")
      ) {
        return true;
      }
      return false;
    },

    currentFileName() {
      return "Veuillez choisir un fichier";
    },

    currentFileDate() {
      return "";
    },

    userDepartement() {
      if (!this.user.ADR_Departement) return "-";
      return this.user.ADR_Departement.NomDepartement;
    },

    civilite() {
      if (this.user.EST_Civilite == 1) return "Homme";
      if (this.user.EST_Civilite == 0) return "Femme";
      return "";
    },

    paysNationalite() {
      if (!this.user.nationalite) return "";
      return this.getList.ADR_Pays.find(
        (pays) => pays.id == this.user.nationalite
      );
    },

    paysAdresse() {
      if (!this.user.ADR_Pays) return "";
      return this.getList.ADR_Pays.find(
        (pays) => pays.id == this.user.ADR_Pays.id
      );
    },

    licenceType() {
      return this.products.find((product) => product.id === this.user.licence);
    },

    assurance() {
      return this.options.find((option) => option.id === this.user.assurance);
    },

    assuranceOptions() {
      return this.options.filter((option) =>
        this.user.optionAssurance.includes(option.id)
      );
    },

    montantTotal() {
      let price = 0;

      if (this.licenceType) {
        this.prices.reduce((acc, curr) => {
          if (curr.product && curr.product.id === this.licenceType.id && curr.type === 'principal') {
            price += curr.value;
          }
        }, 0);
      }

      if (this.assurance) {
        price += this.prices.find(
          (price) => price.option && price.option.id === this.assurance.id
        ).value;
      }

      if (this.assuranceOptions) {
        this.assuranceOptions.forEach((option) => {
          price += this.prices.find(
            (price) => price.option && price.option.id === option.id
          ).value;
        });
      }
      return price;
    },
    totalPanier() {
      if (!this.montantTotal) {
        return 0;
      }

      return Math.ceil(this.montantTotal + this.commissionLemonway + this.cotisationCT + this.cotisationLigue + this.cotisationClub);
    },
    commissionLemonway() {
      return Math.ceil(((this.montantTotal + this.cotisationCT + this.cotisationLigue + this.cotisationClub) * 0.0072) + 16);
    },
    cotisationCT() {
      return this.prices.find((price) => price.type === 'cotisation_ct')?.value ?? 0;
    },
    cotisationLigue() {
      return this.prices.find((price) => price.type === 'cotisation_ligue')?.value ?? 0;
    },
    cotisationClub() {
      return this.prices.find((price) => price.type === 'cotisation_club')?.value ?? 0;
    },
  },

  methods: {
    ...mapActions("basics", [
      "getTarifsFFME",
      "getTemplatesBySlug",
    ]),
    next() {
      this.loading = true;
      this.$emit("validate");

      this.$emit("onSetData", {});

      this.$emit("onSetPrice", this.totalPanier);
    },

    async setData() {
      this.products = await getProducts({
        'productCategorie.slug': PRODUCT_CATEGORY_LICENCE,
        'order[order]': 'asc',
      });

      this.options = await getOptions({
        'order[order]': 'asc',
      });

      let options = [
        this.user.assurance,
        ...this.user.optionAssurance,
      ]

      const structures = await getStructuresFromZipcode({
        zipcode: this.user.ADR_CodePostal,
      })

      const structureId = structures[0];

      this.prices = await getPrices({
        'itemsPerPage': 1000,
        'seasonId': this.currentSaison.id,
        'structureId': structureId,
        'productId': this.user.licence,
        'options': options.join(','),
      });
    },
  },

  props: {
    user: {
      type: Object,
    },
    montantOptions: {
      type: Number,
    },
  },
};
</script>

<style scopped>
h5 {
  background-color: rgb(218, 218, 218);
  padding: 10px;
}
label {
  margin-bottom: 0 !important;
  font-size: 0.8rem;
}
.v-input__slot {
  max-width: 100% !important;
  padding: 0 !important;
}
</style>
