<template>
  <div id="print-attestation" ref="print-attestation">
    <div class="source1">
      <div class="header-container">
        <div class="logo-header">
          <div class="logo">
            <img src="~@/assets/Logo_Allianz_Assistance_BE.png" alt="" />
          </div>
          <div class="sub-logo">
            <img src="~@/assets/cabinet.png" alt="" />
          </div>
        </div>
        <div class="contact">
          <div class="content-coordonnees">
            <p>
              17 Boulevard de la Gare - 31500 TOULOUSE<br />
              Tél. : 05 61 52 88 60 - Fax : 05 61 32 11 77<br />
              E-mail :
              <a style="color: blue;" href="5R09151@Agents.Allianz.fr"
                >5R09151@Agents.Allianz.fr</a
              ><br />
              Site :
              <a style="color: blue;" href="https://www.cabinet-gomis-garrigues.fr/"
                >https://www.cabinet-gomis-garrigues.fr/</a
              ><br />
            </p>
            <br /><br />
          </div>
          <div class="content-official-info">
            <p>
              N° ORIAS : 07/020 818 - 08/045 968 -
              <a style="color: blue;" href="www.orias.fr">www.orias.fr</a>
              <br />
              ACPR Autorité Contrôle Prudentiel et Résolution<br />
              4 Place de Budapest - 75436 Paris
            </p>
          </div>
        </div>
      </div>
      <h1 class="title">Attestation d'assurance</h1>
      <p style="text-decoration-color: black;">
        Nous soussignés Cabinet GOMIS - GARRIGUES, Agents Généraux Allianz - 1
        Cours Michelet - CS 30051 - 92076 PARIS LA DEFENSE CEDEX et Allianz
        ASSISTANCE, 7 rue Dora Maar CS 60001 93488 SAINT OUEN CEDEX attestent garantir :
      </p>
      <div class="content">
        <table>
          <tr>
            <th>Nom Prénom</th>
            <th>N° licence</th>
          </tr>
          <tr>
            <td>{{ nom }} {{ prenom }}</td>
            <td>{{ licence }}</td>
          </tr>
        </table>

        <div class="risk-content">
          <p>
            Par contrat <span>N° {{selectedSaison && selectedSaison.config && selectedSaison.config.insuranceContractNumber || 60337812}} </span>
            pour les activités statutaires de la Fédération Française de la Montagne et de l’Escalade et pour les risques suivants :
          </p>
          <ul class="a">
            <li>- Responsabilité Civile activités sportives FFME</li>
            <li>- Individuelle accident activités sportives FFME</li>
            <li>- Assistance – Rapatriement activités sportives FFME</li>
          </ul>
        </div>
        <div class="d-flex justify-content-end contact-container">
          <h2 style="font-family: 'Roboto Condensed';">
            ALLIANZ ASSISTANCE<br />
            (Téléphone à partir de l'étranger<br />
            33 (1) 40.25.15.24)<br />
            selon tableaux en annexe<br />
          </h2>
        </div>
        <div class="content-period">
          <p class="presentation">
            La présente attestation est valable du {{ $moment(selectedSaison.DateDebutSaison).format("DD/MM/YYYY") }} au {{ $moment(selectedSaison.DateFinSaison).format("DD/MM/YYYY") }} et pour le voyage
            en/au {{ nomPays }} du {{ $moment(date_depart).format("DD/MM/YYYY") }} au
            {{ $moment(date_retour).format("DD/MM/YYYY") }} et ne peut engager Allianz et ALLIANZ ASSISTANCE en dehors des
            clauses et conditions du contrat auquel elle se réfère.
          </p>
        </div>
        <div class="compagnie">
          <p style="margin-bottom: 0;">
            Fait à Toulouse, le
            {{ $moment(date_creation).format("DD/MM/YYYY") }}.<br />
            Cabinet GOMIS-GARRIGUES<br />
          </p>
          <p style="margin-bottom: 0;">P/La Compagnie</p>
        </div>
        <div class="signature-content">
          <img src="~@/assets/signature.png" alt="" />
        </div>
        <div class="bas-signature">
          <img src="~@/assets/bas-signature.png" alt="" />
        </div>
        <div class="content-footer">
          <p class="condition">
            Toute adjonction autre que le cachet et signature du représentant
            de la Compagnie est réputée non écrite.
          </p>
          <p style="display: flex; justify-content: center; font-size: 16px;">
            Le présent document est composé de 4 feuillets.
          </p>
        </div>
      </div>
    </div>
    <div class="corporelle-content">
      <img src="~@/assets/attestation-page2.png" alt="" />
    </div>
    <div class="corporelle-content">
      <img src="~@/assets/attestation-page3.png" alt="" />
    </div>
    <div class="prestation-content">
      <img src="~@/assets/attestation-page4.png" alt="" />
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { mapGetters, mapActions } from "vuex";
import { error_notification } from "@/plugins/notifications.js";
export default {
  data: () => ({
    printDisabled: false,
    voyagedetails: [],
    currentUser: [],
    nomPays: "",
    date_creation: "",
    nom: "",
    prenom: "",
    licence: "",
    date_depart: Object,
    date_retour: Object,
  }),
  props: {
    print: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("structure", ["currentStructure"]),
    ...mapGetters("saisons", ["selectedSaison"]),
  },
  methods: {
    printAttestation(voyagedetails, name, currentuser) {
      this.nomPays = voyagedetails.UTI_AssuranceVoyage
        ? voyagedetails.UTI_AssuranceVoyage.ADR_Pays.NomPays
        : voyagedetails.ADR_Pays.NomPays;
      this.date_creation = voyagedetails.UTI_AssuranceVoyage
        ? voyagedetails.UTI_AssuranceVoyage.Z_DateCreation
        : voyagedetails.Z_DateCreation;
      this.voyagedetails = voyagedetails;
      this.currentUser = currentuser;
      this.nom = this.currentUser.CT_Nom
        ? this.currentUser.CT_Nom
        : this.currentUser.UTI_Utilisateur.CT_Nom;
      this.prenom = this.currentUser.CT_Prenom
        ? this.currentUser.CT_Prenom
        : this.currentUser.UTI_Utilisateur.CT_Prenom;
      this.licence = this.currentUser.LicenceNumero
        ? this.currentUser.LicenceNumero
        : this.currentUser.UTI_Utilisateur.LicenceNumero;
      this.date_depart = voyagedetails.UTI_AssuranceVoyage
        ? voyagedetails.UTI_AssuranceVoyage.DateDepart
        : voyagedetails.DateDepart;
      this.date_retour = voyagedetails.UTI_AssuranceVoyage
        ? voyagedetails.UTI_AssuranceVoyage.DateRetour
        : voyagedetails.DateRetour;
      this.$nextTick(() => {
        if (this.printDisabled)
          return error_notification(
            "Il manque des informations à votre licence pour pouvoir l'imprimer."
          );
        this.$refs["print-attestation"].style.display = "block";
        let source = document.querySelector("#print-attestation");
        html2canvas(source, {
          scrollX: -window.scrollX,
          scrollY: -window.scrollY,
          windowWidth: document.documentElement.offsetWidth,
          windowHeight: source.scrollHeight,
          letterRendering: 1,
          scale: 2,
          logging: true,
          useCORS: true,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg");
          const pdf = new jsPDF({
            orientation: "portrait",
          });

          var position = 0;
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, "JPEG", 0, position, pdfWidth, pdfHeight);
          var requiredPages = 3;
          for (var i = 0; i < requiredPages; i++) {
            position = position - 290;
            pdf.addPage();
            pdf.addImage(imgData, "JPEG", 0, position, pdfWidth, pdfHeight);
          }
          pdf.save(name);
          this.$refs["print-attestation"].style.display = "none";
        });
      });
    },
  },
};
</script>
<style lang="scss">
#print-attestation {
  z-index: -3;
  position: absolute;
  display: none;
  width: 750px;
  padding: 2em;
  margin: 50px;
  margin-top: 70px;
  p {
    font-family: "Roboto Condensed";
    text-decoration-color: black;
    font-size: 15px;
  }
  .source1 {
    height: 1050px;
  }
  .header-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    height: 150px;
    border-style: solid;
    border-width: 2px;
    border-color: blue;
    /*background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("~@/assets/header.png");*/
  }
  .logo-header {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 55%;
  }
  .logo {
    overflow: hidden;
    display: flex;
    height: 70px;
    width: 100%;
    margin-left: 10px;
    margin-right: 15px;
    img {
      width: 350px;
    }
  }
  .sub-logo {
    overflow: hidden;
    height: 85px;
    width: 100%;
    margin-left: 10px;
    margin-right: 15px;
    img {
      width: 200px;
      height: 50px;
    }
  }
  .contact {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 45%;
  }
  .content-coordonnees {
    overflow: hidden;
    display: flex;
    height: 60px;
    width: 100%;
    margin-right: 15px;
    margin-bottom: 10px;
    height: 60%;
    margin-top: 10px;
    padding-left: 15px;
    justify-content: flex-start;
    p {
      font-family: "Roboto Condensed";
      font-size: 11px;
      margin-left: 0;
      padding-left: 0;
    }
  }
  .content-official-info {
    overflow: hidden;
    height: 90px;
    width: 100%;
    margin-right: 15px;
    margin-bottom: 10px;
    display: flex;
    height: 40%;
    padding-left: 15px;
    justify-content: flex-start;
    p {
      font-family: "Roboto Condensed";
      font-size: 11px;
      margin-left: 0;
      padding-left: 0;
    }
  }
  .title {
    display: flex;
    justify-content: center;
    text-decoration: underline;
    text-decoration-color: black;
    font-size: 16px;
    margin-top: 20px;
    font-family: "Roboto Condensed";
  }
  .content {
    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
      width: 400px;
      font-family: "Roboto Condensed";
      text-decoration-color: black;
      font-size: 16px;
      text-align: center;
    }
  }
  .risk-content {
    margin-top: 15px;
    font-family: "Roboto Condensed";
    text-decoration-color: black;
  }
  .contact-container {
    margin-top: 10px;
    margin-right: 60px;
    h2 {
      text-decoration-color: black;
      font-size: 15px;
    }
  }
  .content-period {
    margin-top: 20px;
    .presentation {
      display: flex;
      justify-content: left;
      font-size: 15px;
      font-weight: bold;
      font-family: "Roboto Condensed";
    }
  }
  .signature-content {
    display: flex;
    justify-content: flex-end;
    padding-right: 150px;
    width: 100%;
  }
  .compagnie {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    align-items: center;
    padding-right: 100px;
    padding-bottom: 0;
    line-height: 15px;
    height: fit-content !important;
    font-family: "Roboto Condensed";
  }
  .bas-signature {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 110px;
  }
  .content-footer {
    margin-top: 15px;
    .condition {
      display: flex;
      justify-content: center;
      font-size: 15px;
      font-weight: bold;
    }
  }
  .corporelle-content {
    height: 920px;
    margin-bottom: 100px;
  }
  .prestation-content {
    height: 1080px;
  }
  /*.change-font{
      font-family: "Times New Roman";
    }*/
}
</style>
