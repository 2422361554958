<template>
  <div>
    <v-dialog
      v-if="modalIsOpen"
      v-model="modalIsOpen"
      @keydown.esc="closeModal"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <div style="height: 100vh;">
        <v-tabs background-color="blue-dark" dark grow>
          <v-tabs-slider></v-tabs-slider>
          <v-tab style="display: flex; flex-direction: column;">
            <span style="font-size: 1.2rem;">
              Déclarer un voyage à l'étranger
            </span>
          </v-tab>
          <v-icon class="close-icon" @click="closeModal">mdi-close</v-icon>
        </v-tabs>
        <v-stepper alt-labels v-model="step" style="height: calc(100vh - 48px); overflow: scroll;">
          <v-row style="font-size: 0.8rem; padding: 25px;">
            <v-col cols="12">
              <ul
                style="
                  margin: 0;
                  padding: 0;
                  font-weight: bold;
                  font-size: 0.9rem;
                "
              >
                <li>
                  ETAPE 1 : Prise de connaissance des informations générales de
                  l'extension d'assurance pour les voyages à l'étranger
                </li>
                <li class="mt-4">ETAPE 2 : Déclaration du voyage</li>
                <li class="mt-4">ETAPE 3 : Validation et paiement en ligne</li>
              </ul>
            </v-col>
            <!-- <v-col cols="6" class="d-flex; align-items:center;">
            <div
              class="logo__ffme"
              style="width:20%;height:190%; transform:rotate(90deg); margin-top:-120px; margin-left:auto; margin-right:35px"
            ></div
          ></v-col> -->
          </v-row>

          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1"
              >Informations</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2"
              >Déclaration</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3"
              >Paiement</v-stepper-step
            >
            <!-- <v-stepper-step :complete="step > 4" step="4"
            >Confirmation</v-stepper-step
          > -->
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <Step1 @on-next="step += 1" />
            </v-stepper-content>

            <v-stepper-content step="2">
              <Step2
                @on-next="step += 1"
                @set-data="setAssuranceData"
                @on-previous="step -= 1"
              />
            </v-stepper-content>
            <v-stepper-content step="3">
              <Step3 @on-submit="setPayment" @on-previous="step -= 1" :alreadyPaid="alreadyPaid" />
            </v-stepper-content>
            <!-- <v-stepper-content step="4">
            <Step4 @on-confirm="closeModal"
          /></v-stepper-content> -->
          </v-stepper-items>
        </v-stepper>
        <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </div>
    </v-dialog>
    <PrintFacture ref="facture" :print="print"></PrintFacture>
  </div>
</template>

<script>
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
// import Step4 from "./Step4";
import { mapGetters, mapActions } from "vuex";
import PrintFacture from "@/components/Structure/Finance/PrintFacture.vue";
import { error_notification, success_notification } from '@/plugins/notifications.js';
import { createVoyage } from "@/plugins/transactionService";
import {ASSURANCE_VOYAGE, getOptions, getPrices} from "@/plugins/productService";
export default {
  name: "VoyageModal",
  components: { Step1, Step2, Step3, PrintFacture },
  data: () => ({
    step: 1,
    assuranceData: null,
    voyages: [],
    loading: false,
    alreadyPaid: false,
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
  }),
  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
    },
  },
  async created() {
    this.voyages = await this.getVoyagesUser({ ID_Utilisateur: this.currentUserId });

    this.alreadyPaid = this.voyages.some((voyage) => voyage.UTI_AssuranceVoyage.ID_Saison === this.currentSaison.id);
  },

  computed: {
    ...mapGetters("user", ["currentUserId"]),
    ...mapGetters("saisons", ["currentSaison"]),
  },
  methods: {
    ...mapActions("voyage", [
      "getVoyagesUser",
    ]),
    ...mapActions("utilisateurs", [
      "createFluxFinancierDetail",
      "createLemonwayPaiement",
    ]),


    setAssuranceData(item) {
      this.assuranceData = { ...item };
    },

    closeModal() {
      this.$emit("on-close");
      this.step = 1;
    },

    async setPayment() {
      this.loading = true;

      try {
        let optionVoyage = await getOptions({
          slug: ASSURANCE_VOYAGE,
        });
        const voyageTarif = await getPrices({
          options: optionVoyage[0]['id'],
          seasonId: this.currentSaison.id,
          structureId: 1318,
        });


        const commissionLemonway = Math.ceil((+((voyageTarif?.[0]?.value / 100) * 0.0072) + 0.16) * 100) / 100;

        const activites = JSON.parse(this.assuranceData.Activites).map((item) => item.id);

        const response = await createVoyage(
          (((voyageTarif?.[0]?.value / 100) * 1000) / 10) + (commissionLemonway * 100),
            this.assuranceData.ID_Pays,
            this.currentUserId,
            this.currentSaison.id,
            this.assuranceData.DateDepart,
            this.assuranceData.DateRetour,
            this.assuranceData.Description,
            activites,
        );

        if (response.data['@type'] === 'Transaction') {
          window.location.href = `${process.env.VUE_APP_FRONT_NEXT_URL}/paiement/${response.data.id}?appMode=${localStorage.getItem("ffme_user_mode")}`;
        } else {
          this.$router.push({ path: "/" });
          success_notification('Votre voyage a bien été créé');
        }
      } catch (error) {
        error_notification('Une erreur est survenue lors de la création de votre voyage');
      }

      this.loading = false;
    },

    printFacture(item) {
      this.$refs.facture.printFacture(item);
    },
  },
};
</script>
<style scoped>
.logo__ffme {
  height: 100%;
  margin-top: 0px;
  background: url("~@/assets/images/FFME_LOGO_INSTIT_PRINCIPAL_FOND_CLAIR_RVB.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;
  width: 100%;
}

.dialog-payment {
  height: 90%;
}
</style>
