<template>
  <v-chip
    small
    color="green"
    text-color="white"
    class="mx-1"
    v-if="mounted"
  >
    {{ option.label }} ({{price.value / 100}} €)
  </v-chip>
</template>

<script>

export default {
  data: () => ({
    mounted: false,
  }),

  async created() {
    this.mounted = true;
  },

  props: {
    option: {
      type: Object,
    },
    price: {
      type: Object,
    },
  },
};
</script>
