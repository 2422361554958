<template>
  <div class="d-flex flex-column">
    <v-btn
      type="submit"
      class="btn btn-primary"
      style="margin-left: auto; margin-top:auto"
      @click.prevent="$emit('on-next')"
      >Suivant</v-btn
    >
  <div class="d-flex form__informations scroll_hidden">
    <h3>Principe</h3>
    <p>
      Pour être couvert par l'assurance de la FFME lors d'un voyage à vocation
      sportive à l'étranger de <b>90 jours consécutifs maximum</b> , déclaration
      préalable du voyage est obligatoire à chaque départ. Vous devrez vous
      acquitter d’une prime de {{ TarifVoyage }}€ par saison sportive et par personne.
    </p>
    <p>
      Les ressortissants étrangers domiciliés hors de France et titulaires d'une
      licence FFME bénéficient des garanties (responsabilité civile, atteinte
      corporelle, assistance rapatriement) sur le territoire de la France
      Métropolitaine pour les activités statutaires de la FFME qu'elles soient
      ou non pratiquées au sein des clubs, associations affiliées, comités
      départementaux ou régionaux. En revanche, ils ne sont garantis hors de
      France métropolitaine que si ces activités sont organisées directement par
      la FFME (clubs, associations affiliées, comités départementaux ou
      régionaux).
    </p>
    <h6 style="color:blue;">Déclaration pour quelles destinations ?</h6>
    <p>
      Tous les pays hors union européenne (UE élargie à Monaco, Andorre, Suisse
      et du Royaume-uni)
    </p>
    <h6 style="color:blue;">Pour quelles activités sportives ?</h6>
    <p>
      L'extension des garanties de l'assurance concerne les ativités statutaires
      (alpinisme, canyonisme, escalade, expédition lointaine, randonnée et
      trekking, raquette à neige et ski alpinisme).
    </p>
    <span style="color:blue;">
      Attention ! Les raids multisports ne font pas partie des activités
      garanties par le contrat d'assurance ALLIANZ/MONDIAL ASSISTANCE. Attention
      ! Après souscription des options, les activités slackline au-dessus de 60
      cm, highline, trail et VTT ne sont garanties uniquement en France
      métropolitaine et dans les pays de l’Union Européenne élargie à Monaco,
      Andorre et Suisse.</span
    >
    <h3>Assistance rapatriement</h3>
    <p>
      En cas de rapatriement d'un lieu médicalisé le plus proche de l'accident
      vers votre domicile ou un autre centre hospitalier, contactez le centre
      d'assistance 24h/24 (ALLIANZ ASSISTANCE) :
    </p>
    <ul style="list-style-type:disc; margin-left:20px">
      <li>à partir de la France : 01 40 25 15 24</li>
      <li>à partir de l'étranger : 33 1 40 25 15 24</li>
    </ul>
    <p>
      Il faudra préciser le numéro de contrat FFME : {{selectedSaison.config.insuranceContractNumber || 60337812}} et le numéro de
      convention FFME : {{selectedSaison.config.insuranceConventionNumber || 922892}}. Le numéro de votre licence FFME vous sera
      également demandé.
    </p>
  </div>
</div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ASSURANCE_VOYAGE, getOptions, getPrices} from "@/plugins/productService";

export default {
  name: "VoyageStep1",

  data: () => ({
    tarifVoyage: null,
  }),
  async created() {
    let optionVoyage = await getOptions({
      slug: ASSURANCE_VOYAGE,
    });
    this.tarifVoyage = await getPrices({
      options: optionVoyage[0]['id'],
      seasonId: this.selectedSaison.id,
      structureId: 1318,
    });
  },
  computed: {
    ...mapGetters("saisons", ["selectedSaison"]),
    TarifVoyage() {
      return this.tarifVoyage?.[0]?.value / 100;
    },
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.form__informations {
  flex-direction: column;
  text-align: justify;
}

.scroll_hidden {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}
</style>
