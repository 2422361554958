<template>
  <div>
    <!-- <div v-if="loading">
      Chargement ...
    </div> -->
    <div>
      <span class="title-stepper">
        Étape 2 - Saisie des informations de contact
      </span>
      <div class="d-flex align-center" style="margin: 32px 0px;">
        <span class="text-subtitle">Représentant de la structure</span>
        <v-divider class="mx-3" color="#dfe5f9"></v-divider>
      </div>
      <ValidateForm @validated="next" :showFieldObl="false">
        <v-row class="step-label">
          <v-col cols="12" md="6">
            <div>
              <InputText
                v-model="contactInfo.representantLastName"
                rules="required|min:2|max:250|regexname|dashCounter"
                label="Nom"
                isLabelSecondary
                is-red-label
              />
              <br />
              <InputText
                v-model="contactInfo.representantEmail"
                rules="required|email"
                label="Email"
                isLabelSecondary
                is-red-label
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <InputText
              v-model="contactInfo.representantFirstName"
              rules="required|min:2|max:250|regexname"
              label="Prénom"
              isLabelSecondary
              is-red-label
            />
          </v-col>
        </v-row>
        <div class="d-flex align-center" style="margin: 32px 0px;">
          <span class="text-subtitle">Adresse de correspondance</span>
          <v-divider class="mx-3" color="#dfe5f9"></v-divider>
        </div>
        <v-row>
          <v-col cols="12" md="6">
            <div>
              <AddressAutocomplete
                v-model="contactInfo.representantAdress"
                rules="required|min:6|max:50"
                label="Adresse"
                :minimum_characters="3"
                ref="adresseAutoMA"
                @onSelected="adresseSelected"
                :restrictCountry="restrictCountry"
                is-red-label
              ></AddressAutocomplete>
              <br />
              <InputText
                v-model="contactInfo.representantCP"
                rules="required|min:0|max:50"
                is-red-label
                label="Code postal"
                :key="refreshCodePostal"
                isLabelSecondary
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <InputText
              v-model="contactInfo.representantAdressSupp"
              rules="max:50"
              label="Complément d’adresse"
              is-red-label
              isLabelSecondary
            />
            <br />
            <InputText
              v-model="contactInfo.representantCountry"
              rules="required|min:0|max:50"
              is-red-label
              label="Ville"
              :key="refreshVille"
              isLabelSecondary
            />
          </v-col>
        </v-row>
        <v-row no-gutters style="margin: 32px 0px;">
          <v-btn
            class="btn btn--primary ml-1 btn-font"
            @click.prevent="$emit('onPrevious')"
            text-color="white"
            rounded
            height="42"
            >Étape précédente</v-btn
          >
          <v-btn
            type="submit"
            class="btn btn-primary btn-font"
            style="margin-left: auto;"
            :disabled="$v.contactInfo.$invalid"
            >Suivant</v-btn
          >
        </v-row>
      </ValidateForm>
    </div>
    <!-- <PrintConventionAffiliationMA /> -->
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import InputText from "@/components/utils/input";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import AddressAutocomplete from "@/components/utils/adresseAutocomplete";

export default {
  mixins: [validationMixin],
  components: {
    ValidateForm,
    InputText,
    AddressAutocomplete,
  },

  data: () => ({
    contactInfo: {
      representantFirstName: null,
      representantEmail: null,
      representantLastName: null,
      representantAdress: null,
      representantCountry: null,
      representantCP: null,
      representantAdressSupp: null,
    },
    refreshCodePostal: 99,
    refreshVille: 0,
    restrictCountry: "FR",
  }),
  methods: {
    next() {
      this.$store.commit("structure/SET_MA_AFFILIATION", {
        contact: this.contactInfo,
      });
      this.$emit("onNext");
    },
    async adresseSelected(item) {
      let adresse1 = `${item[0].long_name} ${item[1].long_name}`;
      this.$refs.adresseAutoMA.currentValue = adresse1.toUpperCase();
      this.contactInfo.representantAdress = adresse1;
      this.contactInfo.representantCountry = item[2].long_name.toUpperCase();
      const CP = item.find((t) => t.types.includes("postal_code"));
      this.contactInfo.representantCP = CP ? CP.long_name : "";
      this.refreshCodePostal++;
      this.refreshVille++;
    },
  },
  validations: {
    contactInfo: {
      representantFirstName: {
        required,
      },
      representantEmail: {
        required,
        email,
      },
      representantLastName: {
        required,
      },
      representantAdress: {
        required,
      },
      representantCountry: {
        required,
      },
      representantCP: {
        required,
      },
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
.readonly__info {
  background-color: rgba(0, 0, 0, 0.3);
}
.protected__form_inscription {
  margin-top: 9px;
  background-color: rgba(0, 0, 0, 0.1);
  font-style: italic;
}
</style>
<style>
.protected__form_inscription input {
  background: none;
}
.select___country__licence span {
  margin-right: 2px !important;
}
.select__pays___side span {
  margin-right: 2px !important;
}
</style>
