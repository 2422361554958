<template>
  <div>
    <h3>Validation</h3>

    <v-row>
      <v-col cols="12"
        >Votre demande a bien été prise en compte. Un mail vous a été envoyé afin de pouvoir accéder à votre espace MyFFME et de procéder au règlement de votre licence.</v-col
      >
    </v-row>
  </div>
</template>

<script>
// import selectInput  from "@/components/utils/select"
// import InputTelephone  from "@/components/utils/inputTelephone"
// import DateDeNaissance  from "@/components/utils/datePicker"
// import countrySelect  from "@/components/utils/select__countries"

export default {
  components: {},

  data: () => ({}),

  async created() {
    await this.setData();
  },

  computed: {},

  methods: {
    async setData() {
      return;
    },
  },

  props: {
    user: {
      type: Object,
    },
  },
};
</script>

<style scopped>
label {
  margin-bottom: 0 !important;
  font-size: 0.8rem;
}
</style>
