<template>
  <div class="d-flex flex-column">
    <div class="content-panel">
      <div class="d-flex" v-on="$listeners">
        <v-tooltip
          bottom
          color="#90a1be"
          :disabled="isInfoToolTipDisabled"
          max-width="400"
        >
          <template v-slot:activator="{ on }">
            <v-icon small color="#082B6E" v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span style="width: 300px; min-height: 70px;"
            >Le certificat médical téléversé doit avoir moins de 6
            mois</span
          >
        </v-tooltip>
        <span
          class="download-link ml-2 panel-title panel-content"
          style="color: #082b6e !important; font-weight: 500;"
          v-text="headerContent"
          @click="$emit('download')"
        />
      </div>
      <div
        class="panel-title panel-content"
        style="padding: 12px 0px;"
      >
        {{ content }}
      </div>
    </div>

    <v-btn
      class="btn btn-primary px-5"
      style="border-radius: 0px !important;"
      @click.prevent="$emit('clicked')"
    >
      <span
        class="panel-title"
        style="color: white !important;"
        v-text="btnTitle"
      />
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CertificatMedicalPanel",
  props: {
    headerTitle: {
      type: String,
      default: "",
    },
    headerContent: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    btnTitle: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInfoToolTipDisabled: {
      type: Boolean,
      default: false,
    },
    panel: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({ hasValidQS: (state) => state.utilisateurs.hasValidQS }),
    helpText() {
      if (this.hasValidQS)
        return "Un questionnaire santé a été validé pour cette saison";
      return "Pour valider un questionnaire santé, le dernier certificat médical téléversé doit avoir 3 ans maximum";
    },
  },
  created() {
    console.log("ato creer:>> ");
  },
};
</script>

<style scoped>
.panel-certificat {
  border: 2px solid #e7effd;
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
}
.header-panel {
  border-bottom: 2px solid #e7effd;
}
.header-panel >>> .v-expansion-panel-header__icon i {
  color: #020918 !important;
}
.certificat-medical-content >>> .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
.panel-title {
  font-family: Roboto;
  font-style: normal;
  /* font-weight: normal; */
  font-size: 1rem;
  line-height: 24px;
  color: #000000;
}
.panel-content {
  font-size: 0.75rem !important;
  line-height: 16px !important;
}
.content-panel {
  background: rgba(0, 171, 231, 0.02);
  height: 115px;
  padding: 12px;
  border-left: solid 2px #00abe7;
}
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
.download-link {
  color: #1976d2;
  text-decoration: underline !important;
  cursor: pointer;
}
</style>
