<template>
  <v-row>
    <v-col class="text-right">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            class="mx-2"
            fab
            small
            dark
            color="indigo"
            @click="openDeclareTripModal"
          >
            <v-icon dark :disabled="!canAccess">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span v-if="!canAccess"
          >Pour déclarer un voyage, vous devez avoir une licence active pour la
          saison en cours et posséder à minima une assurance BASE.</span
        ><span v-else>Déclarer un voyage</span>
      </v-tooltip>
    </v-col>

    <v-col cols="12" class="px-0">
      <v-data-table
        dense
        fixed-header
        height="500px"
        :headers="headers"
        :items="allVoyageUser"
        item-key="id"
        class="elevation-1 list__table"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                v-on="on"
                v-bind="attrs"
                fab
                x-small
                color="green"
                outlined
                @click.prevent="openVisualVoyage(item)"
                key="edit"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Visualiser la déclaration de voyage</span>
          </v-tooltip>
        </template>
        <template v-slot:item.factures="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                v-on="on"
                v-bind="attrs"
                fab
                x-small
                color="light-blue"
                outlined
                @click.prevent="
                  printFacture(
                    item,
                    `facture-${currentUser.CT_Prenom}-${currentUser.CT_Nom}-saison${currentSaison.id}`,
                    currentUser
                  )
                "
                key="edit"
              >
                <v-icon>mdi-file-pdf</v-icon>
              </v-btn>
            </template>
            <span>Télécharger la facture de paiement</span>
          </v-tooltip>
        </template>
        <template v-slot:item.attestations="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                v-on="on"
                v-bind="attrs"
                fab
                x-small
                color="light-blue"
                outlined
                @click.prevent="
                  printAttestation(
                    item,
                    `attestation-${currentUser.CT_Prenom}-${currentUser.CT_Nom}-saison${currentSaison.id}`,
                    currentUser
                  )
                "
                key="edit"
              >
                <v-icon>mdi-file-cloud</v-icon>
              </v-btn>
            </template>
            <span>Télécharger l'attestation</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
    <VoyageModal
      :modalIsOpen="openModal"
      @on-close="closeDetailModal"
      :context="{
        currentUserId: this.currentUserId,
        currentUser: this.currentUser,
        currentSaison: this.currentSaison,
        currentStructure: this.currentStructure,
        allVoyageUser: this.getVoyageUser,
      }"
    />
    <VoyageDetailModal
      :openModal="openDetailModal"
      @on-close="() => (openDetailModal = false)"
      :context="voyageDetail"
      @onPrintAttestation="printAttestation"
      @onPrintFacture="printFacture"
      :key="voyageDetail.id"
    />
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <PrintAttestation ref="attestation" :print="print"></PrintAttestation>
    <PrintFacture ref="facture" :print="print"></PrintFacture>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VoyageModal from "../../../components/utils/modals/Voyage/VoyageModal";
import VoyageDetailModal from "../../../components/utils/modals/Voyage/VoyageDetailModal";
import PrintAttestation from "../../../components/Assurances/PrintAttestation.vue";
import PrintFacture from "../../../components/Assurances/PrintFacture.vue";

import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import {ASSURANCE_BASE, ASSURANCE_BASE_PLUS, ASSURANCE_BASE_PLUS_PLUS} from "@/plugins/productService";
export default {
  components: {
    VoyageModal,
    VoyageDetailModal,
    PrintAttestation,
    PrintFacture,
  },

  data: () => ({
    mounted: false,
    headers: [
      {
        text: "Saison",
        value: "UTI_AssuranceVoyage.ID_Saison",
      },
      {
        text: "N° Dossier",
        value: "NumeroDossier",
      },
      {
        text: "Pays de destination ",
        value: "UTI_AssuranceVoyage.ADR_Pays.NomPays",
      },
      // { text: "Date de la saisie", value: "Z_DateCreation" },
      { text: "Date de départ", value: "DateDepart" },
      // { text: "Date de retour", value: "DateRetour" },
      { text: "Attestation", value: "attestations" },
      { text: "Facture", value: "factures" },
      {
        text: "Etat",
        value: "UTI_AssuranceVoyage.LIST_EtatValidationVoyage.Description",
      },
      {
        text: "Action",
        value: "actions",
      },
    ],
    openModal: false,
    voyageDetail: {},
    openDetailModal: false,
    currentVoyage: [],
    loading: true,
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
    canAccess: false,
    currentlicence: null,
  }),

  computed: {
    ...mapGetters("voyage", ["getVoyageUser"]),
    ...mapGetters("user", ["currentUserId", "currentUser"]),
    ...mapGetters("saisons", ["currentSaison", "SelectedSaison"]),
    ...mapGetters("structure", ["currentStructure"]),

    allVoyageUser() {
      if (!this.getVoyageUser.length) return [];

      const voyages = this.getVoyageUser.map((voyage) => ({
        ...voyage,
        DateRetour: this.$moment(voyage.UTI_AssuranceVoyage.DateRetour).format(
          "DD/MM/YYYY"
        ),
        DateDepart: this.$moment(voyage.UTI_AssuranceVoyage.DateDepart).format(
          "DD/MM/YYYY"
        ),
        NumeroDossier: voyage.UTI_AssuranceVoyage.NumeroDossier + 8500,
        Z_DateCreation: this.$moment(
          voyage.UTI_AssuranceVoyage.Z_DateCreation
        ).format("DD/MM/YYYY"),
      }));
      return voyages;
    },
  },
  methods: {
    ...mapActions("utilisateurs", [
      "getLicenceUtilisateur",
      "getUtilisateurFullData",
    ]),
    ...mapActions("voyage", ["getVoyagesUser"]),

    ...mapActions("user", [
      "getCertificatsMedicaux",
    ]),

    openDeclareTripModal() {
      if (!this.canAccess) this.openModal = false;
      else this.openModal = true;
    },
    closeDetailModal() {
      this.openModal = false;
      this.step = 1;
    },
    openVisualVoyage(item) {
      this.voyageDetail = { ...item, ...this.currentUser };
      this.openDetailModal = true;
    },
    printAttestation(item, name, utilisateur) {
      this.$refs.attestation.printAttestation(item, name, utilisateur);
    },
    printFacture(item, name, utilisateur) {
      this.$refs.facture.printFacture(item, name, utilisateur);
    },

    async setPrintLicenceInfo() {
      let assurancesType = ["BASE", "BASE +", "BASE ++", "ASSURANCE RC"];
      let complentaireTypes = [
        "Option indemnités journalières 1",
        "Option indemnités journalières 2",
        "Option indemnités journalières 3",
      ];
      this.print.cm = await this.getCertificatsMedicaux({
        ID_Utilisateur: this.currentUserId,
        ID_Saison: this.currentSaison.id,
      });
      this.print.user = (
        await this.getUtilisateurFullData(this.currentUserId)
      ).UTI_Utilisateurs[0];
      this.print = { ...this.print };
    },
    async setData() {
      this.currentlicence = await this.getLicenceUtilisateur({
        user: this.currentUserId,
        season: parseInt(this.currentSaison?.id),
        'order[season]': 'desc',
        'order[dateEnd]': 'desc',
      });

      if (!this.currentlicence[0] || this.currentlicence[0].active === false) {
        this.canAccess = false
      } else {
        let eligibility = this.currentlicence[0].licenceOptions.some(
          (licenceOptions) =>
            licenceOptions.option.slug === ASSURANCE_BASE ||
            licenceOptions.option.slug === ASSURANCE_BASE_PLUS ||
            licenceOptions.option.slug === ASSURANCE_BASE_PLUS_PLUS
        );

        if (eligibility) {
          this.canAccess = true
        }
      }
    },
  },
  async created() {
    await this.getVoyagesUser({ ID_Utilisateur: this.currentUserId });
    await this.setData();
    setTimeout(() => (this.loading = false), [500]);
  },

  props: {
    // context: {
    //   type: Object,
    // },
  },
};
</script>
<style lang="scss">
#table .v-data-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
}

#table .v-data-table__wrapper {
  margin-bottom: 60px;
}
</style>
