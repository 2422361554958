<template>
  <v-dialog
    v-model="showDialog"
    :max-width="maxWidth"
    class="ffme__confirm-dialog"
    @click:outside="$emit('answerNo')"
    origin
  >
    <v-card>
      <v-card-title
        v-if="title"
        class="confirm-dialog__headline headline headline grey lighten-2 justify-content-center font-weight-bold"
      >
        {{ title }}
      </v-card-title>

      <v-card-text v-if="text" class="mt-3">
        {{ text }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="red font-weight-bold"
          rounded
          class="__full mt-0 mr-3 text-uppercase text-white"
          @click="$emit('answerNo')"
        >
          {{ text_no }}
        </v-btn>

        <v-btn
          color="blue-dark text-white font-weight-bold"
          rounded
          class="__full __icon-left"
          @click="$emit('answerYes')"
          :loading="isLoading"
        >
          {{ text_yes }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Êtes-vous sûr?",
    },
    text: {
      type: String,
      default: "",
    },
    text_yes: {
      type: String,
      default: "Oui",
    },
    text_no: {
      type: String,
      default: "Non",
    },
    maxWidth: {
      type: [Number, String],
      default: 400,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.confirm-dialog__headline {
  word-break: break-word;
}
</style>
