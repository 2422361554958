<template>
  <div>
    <div v-if="loading">Chargement ...</div>
    <div v-else>
      <h3>Etape 1 - Saisie de vos coordonnées</h3>
      <ValidateForm @validated="next" v-slot="{ errors, invalid }">
        <v-row>
          <v-col cols="12" md="6">
            <InputText
              v-model="user.CT_Nom"
              rules="required|min:2|max:250|regexname|dashCounter"
              label="Nom"
            />
            <br />
            <InputText
              v-model="user.CT_Prenom"
              rules="required|min:2|max:250|regexname"
              label="Prénom"
            />
            <br />
            <!-- <DateDeNaissance
              v-model="DN_DateNaissanceReal"
              rules="required|min:0|max:50"
              label="Date de naissance"
              :maxToday="true"
            /> -->
            <v-row align-content="center">
              <v-col cols="12" md="3" class="label_column">
                <label for="">Date de naissance*: </label>
              </v-col>

              <v-col cols="12" md="9">
                <inputText
                  v-model="DN_DateNaissance"
                  rules="required"
                  type="date"
                  :maxToday="true"
                ></inputText>
              </v-col>
            </v-row>

            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="civilité"
            >
              <v-row align-content="center">
                <v-col cols="12" md="3" class="label_column">
                  <label for="">Sexe*: </label>
                </v-col>

                <v-col cols="12" md="9">
                  <v-radio-group v-model="user.EST_Civilite" row dense>
                    <v-radio label="Homme" :value="1"></v-radio>
                    <v-radio label="Femme" :value="0"></v-radio>
                  </v-radio-group>
                  <span class="input__error-message">{{ errors[0] }}</span>
                </v-col>
              </v-row>
            </validation-provider>
            <br />
            <countrySelect
              v-model="user.nationalite"
              rules="required|min:0|max:50"
              label="Nationalité"
              key="countrynationalite"
            />
          </v-col>
          <v-col cols="12" md="6">
            <AddressAutocomplete
              :item="user.ADR_Adresse1"
              rules="required|min:6|max:50"
              label="Adresse"
              :minimum_characters="3"
              ref="adresseAuto"
              @onSelected="adresseSelected"
              :restrictCountry="restrictCountry"
            ></AddressAutocomplete>
            <br />
            <InputText
              v-model="user.ADR_Adresse2"
              rules="min:3|max:50"
              label="Complément adresse"
            />
            <InputText
              v-model="user.ADR_CodePostal"
              :key="refreshCodePostal"
              rules="required|min:0|max:50"
              label="Code postal"
              @input="watchCodePostal"
            />
            <br />
            <InputText
              v-model="user.ADR_Ville"
              :key="refreshVille"
              rules="required|min:0|max:50"
              label="Ville"
            />
            <br />
            <selectInput
              :items="departementsList"
              v-model="user.ADR_Departement"
              label="Département"
              rules="required"
              item_text="NomCombined"
              item_value="id"
            />
            <br />
            <countrySelect
              v-model="user.ADR_Pays"
              rules="required|min:0|max:50"
              label="Pays"
              :only="'FR'"
              key="countryResidence"
              @onChange="onPaysChanged"
            />
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider
                :rules="isEmpty(user.TelephoneMobile) && isEmpty(user.TelephoneFixe) ? 'required' : ''"
                name="Téléphone fixe"
            >
            <InputTelephone
              v-model="user.TelephoneFixe"
              label="Téléphone fixe"
                :required="isEmpty(user.TelephoneMobile)"
            />
            <br />
            <InputTelephone
              v-model="user.TelephoneMobile"
              label="Téléphone mobile"
              :required="isEmpty(user.TelephoneFixe)"
            />
            </validation-provider>
            <br />
            <InputText
              v-model="user.CT_Email"
              rules="required|min:0|max:254|email"
              label="Courriel"
            />
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-btn type="submit" class="btn btn-primary" style="margin-left: auto" :disabled="invalid"
            >Suivant</v-btn
          >
        </v-row>
      </ValidateForm>
    </div>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import InputText from "@/components/utils/input";
import InputTelephone from "@/components/utils/inputTelephone";
// import DateDeNaissance from "@/components/utils/datePicker";
import countrySelect from "@/components/utils/select__countries";
import AddressAutocomplete from "@/components/utils/adresseAutocomplete";
import selectInput from "@/components/utils/selectWithInput.vue";
import { sleep } from "@/plugins/utils";
import { mapActions } from "vuex";
import { bus } from "@/main";

export default {
  components: {
    ValidateForm,
    InputText,
    // DateDeNaissance,
    countrySelect,
    InputTelephone,
    AddressAutocomplete,
    selectInput,
    // DateDeNaissance: () =>
    //   import(
    //     /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
    //   ),
  },

  props: {
    userRenouvellement: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  async created() {
    this.departementsListTemp = await this.getDepartementsList();
    console.log("userRenouvellement info step1==>>", this.userRenouvellement);
    this.departementsList = this.departementsListTemp?.map((departement) => {
      return {
        ...departement,
        NomCombined: `${departement.CodeDepartement} - ${departement.NomDepartement}`,
      };
    });
    if (this.userRenouvellement && this.userRenouvellement.id) {
      this.user.EST_Civilite = this.userRenouvellement.CT_EST_Civilite;
      this.user.CT_Nom = this.userRenouvellement.CT_Nom;
      this.user.CT_NomDeNaissance = this.userRenouvellement.CT_NomDeNaissance;
      this.user.CT_Prenom = this.userRenouvellement.CT_Prenom;

      this.DN_DateNaissance = this.$moment(
        this.userRenouvellement?.DN_DateNaissance
      ).format("YYYY-MM-DD");

      // this.user.DN_DateNaissance = "19/08/1996";
      this.user.nationalite = this.userRenouvellement.ID_PaysNationalite;
      this.user.ADR_Adresse1 = this.userRenouvellement.ADR_Adresse[0].Adresse1;
      this.user.ADR_Adresse2 = this.userRenouvellement.ADR_Adresse[0].Adresse2;
      this.user.ADR_CodePostal =
        this.userRenouvellement.ADR_Adresse[0].CodePostal;
      this.user.ADR_Ville = this.userRenouvellement.ADR_Adresse[0].Ville;
      this.user.ADR_Pays = this.userRenouvellement.ADR_Adresse[0].ID_Pays;
      this.user.TelephoneFixe = this.userRenouvellement.CT_TelephoneFixe;
      this.user.TelephoneMobile = this.userRenouvellement.CT_TelephoneMobile;
      this.user.CT_Email = this.userRenouvellement.CT_Email;
      this.$emit("onSetData", this.user);
      console.log("user dataaa====>>", this.user);
    }
  },

  data: () => ({
    user: {
      EST_Civilite: 2,
      CT_Nom: "",
      CT_Prenom: "",
      CT_NomDeNaissance: "",
      ADR_Adresse2: "",
      ADR_Adresse1: "",
    },
    DN_DateNaissance: "",
    refreshCodePostal: 99,
    refreshVille: 0,
    restrictCountry: "FR",
    departementsList: [],
    test: 1,
    DN_DateNaissanceReal: "",
  }),
  methods: {
    ...mapActions("basics", ["getDepartementsList"]),
    isEmpty(field) {
      return field === undefined || field === null || field === '';
    },
    next() {
      this.$emit("onNext");
      this.user = {
        ...this.user,
        DN_DateNaissance: this.$moment(this.DN_DateNaissance).format(
          "YYYY-MM-DD"
        ),
      };
      this.$emit("onSetData", this.user);
    },

    async watchCodePostal(item) {
      let codePostal = item.toString().slice(0, 2);
      let depts = await this.getDepartementsList();
      let departement = depts.find((dep) => dep.CodeDepartement == codePostal);
      this.user.ADR_Departement = {...departement, NomCombined: `${departement.CodeDepartement} - ${departement.NomDepartement}`};
      this.$forceUpdate()
    },

    async adresseSelected(item) {
      if (typeof item === "string") return (this.user.ADR_Adresse1 = item);
      await sleep(10);
      let adresse1 = item[0].long_name + " " + item[1].long_name;
      this.$refs.adresseAuto.currentValue = adresse1.toUpperCase();
      this.user.ADR_Adresse1 = adresse1.toUpperCase();
      this.user.ADR_Adresse2 = "";
      this.user.ADR_Ville = item[2].long_name.toUpperCase();
      this.user.ADR_CodePostal = item[6] ? item[6].long_name.toUpperCase() : "";
      this.refreshCodePostal++;
      this.refreshVille++;
    },

    onPaysChanged(item) {
      this.user.ID_Pays = item.id;
      this.restrictCountry = item.CodePays;
      this.$refs.adresseAuto?.setRestriction(this.restrictCountry);
    },
  },
  watch: {
    DN_DateNaissance() {
      bus.$emit(
        "load-pdf-age",
        this.$moment(this.DN_DateNaissance).toISOString()
      );
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
