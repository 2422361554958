<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 mx-auto mt-5">
        <div class="payment">
          <div class="payment_header">
            <div class="check">
              <v-icon>check</v-icon>
            </div>
          </div>
          <div class="content">
            <h6>
              Vous accédez au paiement en ligne de votre licence 2021/2022.
              Munissez-vous de votre carte bancaire.
            </h6>
            <v-btn class="btn btn-primary mt-5" @click.prevent="setPayment()"
              >Suite</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // homePage() {
    //   window.open(
    //     "https://sandbox-webkit.lemonway.fr/ffme/dev/?moneyintoken=260432136z6tilhdQD9mRsmp24snXbw1jJee"
    //   );
    // },
    async setPayment() {
      try {
        const fetch = require('node-fetch');
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Content-Type',
        };
        const total = this.getTotal * 100;

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/webinit/16500`,
          {
            method: 'get',
            headers: headers,
          }
        );
        const json = await response.json();
        if (json.webKitToken != null)
          window.open(
            `${process.env.VUE_APP_LEMONWAY_WEBKIT}/?moneyintoken=${json.webKitToken}`
          );
        console.log('json.webKitToken', json.webKitToken);
        return { ok: true };
      } catch (error) {
        console.log('erro r:', error);
        return { ok: false };
      }
    },
  },
};
</script>

<style scoped>
body {
  background: #f2f2f2;
  border-color: #002755 !important;
}

.payment {
  border: 1px solid #f2f2f2;
  height: 280px;
  border-radius: 20px;
  background: #fff;
}
.payment_header {
  background: #002755 !important;
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}

.check {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
}

.check i {
  vertical-align: middle;
  line-height: 50px;
  font-size: 30px;
}

.content {
  text-align: center;
}

.content h6 {
  /* font-size: 15px; */
  padding-top: 25px;
  line-height: 2;
}

.content a {
  width: 200px;
  height: 35px;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px;
  background: rgba(255, 102, 0, 1);
  transition: all ease-in-out 0.3s;
}

.content a:hover {
  text-decoration: none;
  background: #000;
}
</style>
