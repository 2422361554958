<template>
  <div>
    <h3>Etape 4 - Certificat médical</h3>
    <ValidateForm @validated="next">
      <v-row>
        <v-col cols="12" md="6" class="mx-3">
          <h6>Type de certificat médical</h6>
          <v-radio-group v-model="typeCertificatSelected" row dense>
            <v-radio
              v-for="(cm, index) in certficatsMedicaux"
              :key="index"
              :label="cm.NomTypeDocument.replace('Certificat médical : ', '')"
              :value="cm.id"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12" md="6">
          <h6>Téléverser le certificat médical</h6>
          <v-chip @click.prevent="modalIsOpen = true" class="ma-2" small>{{
            currentFileName
          }}</v-chip>
          <v-chip v-if="currentFileDate" class="ma-2" small
            ><span class="font-weight-bold mr-2">Date de délivrance:</span
            >{{ currentFileDate }}</v-chip
          >
        </v-col>
      </v-row>
      <v-row class="my-4" no-gutters>
        <v-btn text class="btn btn-gray" @click.prevent="$emit('onPrevious')"
          >Retour</v-btn
        >
        <v-btn
          type="submit"
          class="btn btn-primary"
          :disabled="!canContinue"
          style="margin-left: auto;"
          >Suivant</v-btn
        >
      </v-row>
    </ValidateForm>

    <ModalFiles
      :key="modalKey"
      :isOpen="modalIsOpen"
      @onClose="closeModal"
      @onFileSelected="fileSelected"
      withValidationDate
      isCertifHC
      :isShow="isShow"
      :showIcon="false"
    ></ModalFiles>
  </div>
</template>

<script>
import ModalFiles from "@/components/utils/modals/fileUpload/fileUpload.vue";

import ValidateForm from "@/components/utils/form";
import { mapActions, mapGetters } from "vuex";
// import selectInput  from "@/components/utils/select"
// import InputTelephone  from "@/components/utils/inputTelephone"
// import DateDeNaissance  from "@/components/utils/datePicker"
// import countrySelect  from "@/components/utils/select__countries"

export default {
  components: { ValidateForm, ModalFiles },

  data: () => ({
    selectedFile: null,
    modalIsOpen: false,
    documentType: null,
    modalKey: 0,
    certficatsMedicaux: [],
    typeCertificatSelected: null,
    medecinTraitant: null,
    isShow: false,
  }),

  async created() {
    await this.getListTypesDocument();
    await this.setData();
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    canContinue() {
      console.log();
      if (
        this.selectedFile &&
        this.typeCertificatSelected
        //this.medecinTraitant
      ) {
        return true;
      }
      return false;
    },

    currentFileName() {
      if (this.selectedFile) return this.selectedFile.file.name;
      //if(this.document) return this.document.NomDocument
      return "Veuillez choisir un fichier";
    },

    currentFileDate() {
      if (this.selectedFile)
        return this.$moment(this.selectedFile.validationDate).format(
          "DD/MM/YYYY"
        );
      //if(this.document) return this.$moment(this.document.DateValidation).format('DD/MM/YYYY')
      return "";
    },
  },

  methods: {
    ...mapActions("basics", ["getListTypesDocument"]),

    next() {
      this.$emit("onNext");

      this.$emit("onSetData", {
        file: this.selectedFile,
        certicatsMedicalType: this.typeCertificatSelected,
        nomMedecinTraitant: this.medecinTraitant,
      });
    },

    async fileSelected(file) {
      this.selectedFile = file;
      console.log("fileselected", file);
    },

    closeModal() {
      this.modalIsOpen = false;
    },

    async setData() {
      this.certficatsMedicaux = this.getList.LIST_DocumentType.filter(
        (cm) =>
          cm.LIST_DocumentCategory &&
          cm.LIST_DocumentCategory.SlugCategorieDocument ==
            "certificats_medicaux"
      ).filter(
        (cm) =>
          cm.SlugTypeDocument == "certificat_medical_competition" ||
          cm.SlugTypeDocument == "certificat_medical_loisir"
      );
      return;
    },
  },

  props: {
    user: {
      type: Object,
    },
  },
};
</script>

<style scopped>
label {
  margin-bottom: 0 !important;
  font-size: 0.8rem;
}
</style>
