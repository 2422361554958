<template>
  <div>
    <div>
      <v-alert
        class="mt-4 telecharger_certificat"
        color="orange lighten-5"
        icon="info"
        v-if="pdfType"
      >
        Télécharger le questionnaire santé jeune
        <span class="download" @click="downloadQS('QS_mineur')">ICI</span>
      </v-alert>
      <v-alert
        class="mt-4 telecharger_certificat"
        color="orange lighten-5"
        icon="info"
        v-if="!pdfType"
      >
        Télécharger le questionnaire santé adulte
        <span class="download" @click="downloadQS('QS_majeur')">ICI</span>
      </v-alert>
    </div>
    <v-row>
      <div>
        <v-radio-group
          @change="onQuestionnaireChange"
          v-model="questionnaire"
          style="width: 100%;"
          :disabled="disableRadio"
        >
          <v-radio
            class="theme_radio"
            :label="`J'atteste avoir répondu NON à toutes les questions présentes dans le questionnaire santé pour ma licence de la saison ${
              this.currentSaisonId - 1
            } - ${this.currentSaisonId}.`"
            :value="0"
          >
          </v-radio>
          <v-radio
            class="theme_radio"
            :label="`J'ai répondu OUI à une ou plusieurs questions du questionnaire santé pour ma licence de la saison ${
              this.currentSaisonId - 1
            } - ${
              this.currentSaisonId
            }. Je dois consulter un médecin et fournir un certificat médical.`"
            :value="1"
          >
          </v-radio>
        </v-radio-group>
      </div>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="showButton"
          @click.prevent="onValidate"
          :disabled="canValid"
          class="btn btn-primary px-5"
          >Valider</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  components: {},

  data: () => ({
    questionnaire: null,
    canValid: true,
    disableRadio: false,
  }),

  async created() {
    console.log("this.getLastDateOfSeason()", this.getLastDateOfSeason());
    console.log(this.qs_non);
    console.log(this.qs_oui);
  },

  computed: {
    ...mapGetters("saisons", ["currentSaisonId"]),
  },

  methods: {
    ...mapActions("basics", ["getTemplatesBySlug"]),

    async downloadQS(type) {
      let payload = {
        ID_Saison: this.currentSaisonId,
        SlugNomTemplate: type,
      };
      let template = await this.getTemplatesBySlug(payload);

      const link = document.createElement("a");
      link.href = template[0].CheminDocument;
      link.download = template[0].NomDocument;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    onQuestionnaireChange() {
      console.log("questionnaire !!!!!!!!!!", this.questionnaire);
      this.canValid = false;
      this.$emit("onQuestionnaireChange", this.questionnaire);
    },
    dateFinValidite() {},

    onValidate() {
      let newItem = {
        Categorie: "questionnaire",
        CheminDocument: null,
        DateFinValiditeDocument: new Date(`08/31/${this.currentSaison.id}`),
        DateValidation: null,
        EST_Actif: true,
        EST_DocumentValide: true,
        ID_Type_Document: 60,
        ID_Utilisateur: null,
        LIST_DocumentType: {
          EST_Image: false,
          EST_SaisirDateFinValidite: null,
          EST_SaisirDateValidation: null,
          ID_DocumentCategorie: null,
          NomTypeDocument:
            "Gestion des certificats médicaux / Questionnaire santé",
          SlugTypeDocument: null,
          id: null,
        },
      };
      if (this.questionnaire === 0) {
        this.$emit("onValidateQS", newItem);
      }
      this.disableRadio = true;
      this.canValid = true;
    },

    getLastDateOfSeason() {
      var today = new Date();
      var lastDayOfMonth = new Date(today.getFullYear(), 8, 0);
      return lastDayOfMonth;
    },
  },

  props: {
    pdfType: {
      type: Boolean,
    },
    showButton: {
      type: Boolean,
    },
  },
};
</script>
<style>
.theme_radio {
  padding-left: 20px;
}
</style>
