import axios from "axios";

const TOKEN_NAME = process.env.VUE_APP_AUTH_TOKEN_NAME;

const getHeaders = () => {
  if (localStorage.getItem(TOKEN_NAME)) {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_NAME),
    };
  }

  return {
    'Content-Type': 'application/json',
  };
}

export const getStructuresFromZipcode = async (payload) => {
  return await axios
    .post(process.env.VUE_APP_FFME_BACK_URL + "/api/addresses/zipcode/ct_ligue",
      payload,
      {
        headers: getHeaders()
      }
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
    });
}

export const getDepartement = async (idDepartement) => {
  return await axios
    .get(process.env.VUE_APP_FFME_BACK_URL + `/api/departements/${idDepartement}`,
      {
        headers: getHeaders()
      }
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
    });
}

export const getDepartmentsByStructureId = async (structureId) => {
  return await axios
    .post(process.env.VUE_APP_FFME_BACK_URL + `/api/addresses/structure/department`,
      {
        structureId,
      },
      {
        headers: getHeaders(),
      }
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
    });
}

export const getLicenseeCount = async (seasonId, structureId) => {
  return await axios
    .post(process.env.VUE_APP_FFME_BACK_URL + `/api/licences/structure/count`,
      {
        season: `/api/saison/${seasonId}`,
        structure: `/api/structure/${structureId}`
      },
      {
        headers: getHeaders(),
      }
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
    });
}

export const insertStructureDocumentFromDocDocumentUrl = async (fileUrl, structureId, seasonId, structureDocumentType) => {
  return await axios.post(process.env.VUE_APP_FFME_BACK_URL + '/api/files/create-from-url',
    {
      fileUrl,
      fileType: 'structure_document',
      structure: `/api/structure/${structureId}`,
      season: `/api/saison/${seasonId}`,
      documentType: structureDocumentType
    },
    {
      headers: getHeaders(),
    }
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
    });
}

export const getStructureDocumentsByStructureId = async (structureId, docTypes, params) => {
  return await axios
    .post(`${process.env.VUE_APP_FFME_BACK_URL}/api/structure_documents/structure`,
      {
        structure: `/api/structure/${structureId}`,
        docTypes,
        ...params
      },
      {
        headers: getHeaders(),
      }
    ).then((response) => {
      return response?.data;
    }).catch((error) => {
    });
}

export const uploadStructureDocument = async (file, structureId, seasonId, documentType) => {
  const bodyFormData = new FormData();

  bodyFormData.append('file', file);
  bodyFormData.append('fileType', 'structure_document');
  bodyFormData.append('structure', structureId);
  bodyFormData.append('season', seasonId);
  bodyFormData.append('documentType', documentType);

  return await axios.post(process.env.VUE_APP_FFME_BACK_URL + '/api/files',
    bodyFormData,
    {
      headers: getHeaders(),
    }
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
    });
}
