<template>
  <div>
    <v-row no-gutters>
      <SubBar
        title="Déclarer un voyage"
        route_name="mes_assurances__dashboard"
      />
    </v-row>
    <v-row class="px-6">
      <v-col cols="12">
        <AssuranceVoyage></AssuranceVoyage>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";
import AssuranceVoyage from "@/components/Assurances/Voyages/Assurances_Voyage.vue";

export default {
  components: { SubBar, AssuranceVoyage },

  data: () => ({}),

  methods: {},

  computed: {},
};
</script>
