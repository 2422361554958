<template>
  <validation-provider
    :rules="rules"
    v-slot="{ errors }"
    :name="getName"
    :ref="customRef"
  >
    <div v-if="noLine" class="ffme-form__select">
      <v-autocomplete
        :id="getId"
        :items="countries"
        :label="label"
        v-model="currentValue"
        @change="change"
        :class="getClass(errors)"
        :disabled="disabled"
        :multiple="multiple"
        :small-chips="chips"
        :append-icon="appendIcon"
        item-text="name"
        item-value="id"
        :placeholder="placeholder"
        solo
        dense
        style="padding: 0 !important; height: 35px;"
        :readonly="readonly"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content style="padding: 0;">
            <v-list-item-title class="d-flex align-center">
              <country-flag :country="item.CodePays" />
              {{ item.NomPays }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <template v-slot:selection="{ item }">
          <div class="v-select__selections" style="padding: 0 !important;">
            <div
              class="v-select__selection v-select__selection--comma d-flex align-center"
              style="padding: 0;"
            >
              <country-flag :country="item.CodePays" />
              {{ item.NomPays }}
            </div>
          </div>
        </template>
      </v-autocomplete>
    </div>

    <v-row
      v-else
      align-content="center"
      :no-gutters="label ? false : true"
      class="ffme-form__select"
    >
      <v-col
        cols="12"
        :md="labelCols"
        v-if="label"
        :class="{ 'field__label-column': true, 'py-1': twoLines }"
      >
        <font-awesome-icon
          :icon="{ prefix: 'fab', iconName: icon }"
          class="mr-2"
          v-if="icon"
        />
        <label for>{{ label }}{{ isRequired ? "*" : "" }}</label>
      </v-col>

      <v-col
        cols="12"
        :md="inputCols"
        style="flex: 1;"
        :class="{ 'field__value-column': true, 'pt-0 pb-1': twoLines }"
      >
        <v-autocomplete
          :id="getId"
          :items="countries"
          :label="label"
          v-model="currentValue"
          @change="change"
          :class="getClass(errors)"
          :disabled="disabled"
          :multiple="multiple"
          :small-chips="chips"
          :append-icon="appendIcon"
          item-text="name"
          item-value="id"
          :placeholder="placeholder"
          :filter="customFilter"
          solo
          dense
          style="padding: 0 !important; height: 35px;"
          :readonly="readonly"
        >
          <template v-slot:item="{ item }" style="height: 10px;">
            <v-list-item-content style="padding: 0;">
              <v-list-item-title class="d-flex align-center">
                <country-flag :country="item.CodePays" />
                {{ item.NomPays }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-slot:selection="{ item }">
            <div class="v-select__selections" style="padding: 0 !important;">
              <div
                class="v-select__selection v-select__selection--comma d-flex align-center"
                style="padding: 0;"
              >
                <country-flag :country="item.CodePays" />
                {{ item.NomPays }}
              </div>
            </div>
          </template>
        </v-autocomplete>
        <br />

        <span class="form__error-message" style="bottom:-11px">{{
          errors[0]
        }}</span>
      </v-col>
    </v-row>
  </validation-provider>
</template>

<script>
import CountryFlag from "vue-country-flag";
import { string_to_slug } from "@/plugins/utils.js";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { CountryFlag },

  mounted() {},

  data: () => ({
    currentValue: "",
    countries: [],
  }),

  async created() {
    await this.getListPays();
    this.setData();
  },

  methods: {
    ...mapActions("basics", ["getListPays"]),

    setData() {
      let pays = [...this.getList.ADR_Pays];
      if (this.only) {
        const country = pays.filter((c) => c.CodePays == this.only);
        this.countries = country.sort((a, b) =>
          a.NomPays > b.NomPays ? 1 : -1
        );
        this.currentValue = this.countries[0];
        this.$emit("onChange", this.currentValue);
      } else {
        this.countries = pays.sort((a, b) => (a.NomPays > b.NomPays ? 1 : -1));
      }

      if (this.value) {
        if (typeof this.value == "number") {
          this.currentValue = this.countries.find(
            (c) => c.id == this.value
          )?.id;
        } else {
          this.currentValue = this.countries.find(
            (c) => c.NomPays == this.value.NomPays
          )?.id;
        }
      }
    },

    getClass(errors) {
      let futurClasses = "";
      if (errors[0]) {
        futurClasses += " is-invalid ";
      }

      futurClasses += this.custom_class;

      if (this.fullWidth) {
        futurClasses += " full-width ";
      }

      return futurClasses;
    },

    getValue(item) {
      return item;
    },
    change(item) {
      if (!item) return;
      this.$emit(
        "onChange",
        this.countries.find((c) => c.id == item)
      );
    },

    customFilter(item, queryText) {
      const textOne = item.NomPays.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
  },

  computed: {
    ...mapGetters("basics", ["getList"]),

    getId() {
      if (this.id) {
        return this.id;
      }
      return (
        "input_" +
        this.type +
        "_" +
        Math.floor(Math.random() * 100) +
        "_" +
        string_to_slug(this.getName)
      );
    },

    getName() {
      if (this.name) {
        return this.name;
      }
      return this.label.toLowerCase();
    },

    isRequired() {
      return this.rules.indexOf("required") !== -1;
    },

    inputCols() {
      if (this.twoLines) {
        return "12";
      }
      return this.label ? "9" : "12";
    },

    labelCols() {
      return this.twoLines ? "12" : "3";
    },
  },

  watch: {
    currentValue(val) {
      // allows us to use v-model on our input.
      this.$emit("input", val);
    },
    value(val) {
      this.currentValue = val;
    },
  },

  props: {
    id: {
      type: String,
      default: "",
    },
    customRef: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number, Object, Array],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    custom_class: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    autocomplete: {
      type: String,
      default: "false",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },

    items: {
      type: [Object, Array],
    },

    twoLines: {
      type: Boolean,
      default: false,
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },

    noLine: {
      type: Boolean,
      default: false,
    },

    // SELECT SPECIFIC
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: "$dropdown",
    },
    only: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}

input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}
</style>

<style lang="scss" scoped>
.ffme-form__select {
  .v-input__control {
    min-height: 30px !important;
  }
  .v-input__slot {
    margin: 0 !important;
    box-shadow: none !important;
    min-height: 20px;
    border: 1px solid #dfe5f9;
    //padding: 2px 5px 1px !important;
    font-weight: 200;
    font-size: 0.8rem;
    border-radius: 0;
  }
  v

      // CHIPS
      .v-select--chips {
    .v-input__slot {
      border: none;
    }
    .v-input__icon .v-icon {
      font-size: 2rem;
      color: #0045ab;
    }
  }
  .v-chip.v-size--small {
    font-size: 0.8rem;
    background: #0045ab;
    color: white;
    font-weight: bold;
  }

  .v-input__slot .v-select__slot {
    border: none;

    .v-label {
      font-size: 0.8rem;
      padding-left: 4px;
    }
  }
  .v-select__selections {
    padding: 4px 5px !important;
  }
  .v-select__selection.v-select__selection--comma {
    margin: 0;
  }
  input {
    max-height: 20px;
  }
  .v-text-field__details {
    display: none;
  }
}
.v-select__selections {
  padding: 0 !important;
  height: 30px !important;
}
</style>
