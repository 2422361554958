<template>
  <validation-provider
    :rules="rules"
    v-slot="{ errors }"
    :name="getName"
    :ref="customRef"
  >
    <v-row align-content="center">
      <v-col
        cols="12"
        :md="labelCols"
        v-if="label"
        :class="{ label_column: true, 'py-1': twoLines }"
      >
        <label :class="{ 'label-secondary': isLabelSecondary }" for=""
          >{{ label }}
          <span :class="{ 'red-label': isRedLabel }">
            {{ isRequired && !disabled ? "*" : "" }}
          </span></label
        >
      </v-col>

      <v-col
        cols="12"
        :md="inputCols"
        style="flex: 1;"
        :class="twoLines ? 'pt-0 pb-1' : ''"
      >
        <textarea
          :id="getId"
          v-model="currentValue"
          :placeholder="placeholder"
          :class="getClass(errors)"
          :autocomplete="autocomplete"
          :disabled="disabled"
        ></textarea>
        <span class="error-message">{{ errors[0] }}</span>
      </v-col>
    </v-row>
  </validation-provider>
</template>

<script>
import { string_to_slug } from "@/plugins/utils.js";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    customRef: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    custom_class: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    autocomplete: {
      type: String,
      default: "false",
    },
    label: {
      type: String,
      default: "",
    },
    twoLines: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLabelSecondary: {
      type: Boolean,
      default: false,
    },
    colLabel: {
      type: String,
      default: "",
    },
    isRedLabel: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getClass(errors) {
      let futurClasses = "";
      if (errors[0]) {
        futurClasses += " is-invalid ";
      }

      futurClasses += this.custom_class;
      return futurClasses;
    },
  },

  computed: {
    getId() {
      if (this.id) {
        return this.id;
      }
      return (
        "input_" +
        this.type +
        "_" +
        Math.floor(Math.random() * 100) +
        "_" +
        string_to_slug(this.getName)
      );
    },

    getName() {
      if (this.name) {
        return this.name;
      }
      return this.label.toLowerCase();
    },

    isRequired() {
      return this.rules.indexOf("required") !== -1;
    },

    inputCols() {
      if (this.twoLines) {
        return "12";
      }
      if (this.colLabel) {
        return `${11 - parseInt(this.colLabel)}`;
      }
      return this.label ? "9" : "12";
    },

    labelCols() {
      if (this.colLabel !== "") return this.colLabel;
      return this.twoLines ? "12" : "3";
    },
  },

  mounted() {
    this.currentValue = this.value;
  },

  data: () => ({
    currentValue: "",
  }),

  watch: {
    currentValue(val) {
      // allows us to use v-model on our input.
      this.$emit("input", val);
    },
    value(val) {
      this.currentValue = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.red-label {
  color: red;
}
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}

textarea {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  //min-height: 150px;
  resize: none;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }
  &.full-width {
    max-width: 100%;
  }
}

.error-message {
  display: block;
  position: relative;
  font-weight: 200;
  font-size: 0.7rem;
  font-style: italic;
  color: red;
}
.label-secondary {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px;
  color: #000000;
}
</style>
