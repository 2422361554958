<template>
  <div>
    <div>
      <span class="title-stepper">
        Étape 1 - Saisie des informations générales
      </span>
      <div class="d-flex align-center" style="margin: 32px 0px;">
        <span class="text-subtitle">Informations générales</span>
        <v-divider class="mx-3" color="#dfe5f9"></v-divider>
      </div>
      <ValidateForm @validated="next" :showFieldObl="false">
        <v-row>
          <v-col cols="12" md="6" lg="6">
            <div>
              <InputText
                v-model="structure.name"
                rules="required|min:0|max:50"
                label="Nom de structure"
                isLabelSecondary
                is-red-label
              />
              <br />
              <InputText
                v-model="structure.siretNumber"
                rules="required"
                label="Numéro de SIRET"
                isLabelSecondary
                :error-message="emailError"
                is-red-label
                @blur="$v.structure.siretNumber.$touch()"
                @change="$v.structure.siretNumber.$touch()"
              />

              <br />
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <InputText
              v-model="structure.acronyme"
              label="Acronyme"
              isLabelSecondary
            />
            <br />

            <InputText
              v-model="structure.CA"
              rules="required|min:0|max:50"
              label="Chiffre d’affaire"
              isLabelSecondary
              is-red-label
            />
          </v-col>
          <v-col cols="12">
            <Textarea
              v-model="structure.textIntroduction"
              rules="required|min:3"
              label="Texte de présentation de la structure"
              isLabelSecondary
              twoLines
              is-red-label
            ></Textarea>
          </v-col>
        </v-row>
        <div class="d-flex align-center" style="margin: 32px 0px;">
          <span class="text-subtitle">Sélection de la saison</span>
          <v-divider class="mx-3" color="#dfe5f9"></v-divider>
        </div>
        <v-row no-gutters class="my-4">
          <v-col cols="12" md="6" lg="6">
            <InputSelect
              v-model="structure.seasonValid"
              :items="availableSeason"
              label="Sélection de la saison"
              isLabelSecondary
            />
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span
              class="text-subtitle font-weight-normal text3 ml-4"
              v-text="
                `Période de validité : 01/09/${structure.seasonValid -
                  1} - 31/08/${structure.seasonValid}`
              "
            >
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-4">
          <v-btn
            type="submit"
            class="btn btn-primary"
            style="margin-left: auto;"
            :disabled="$v.structure.$invalid"
            >Étape suivante</v-btn
          >
        </v-row>
      </ValidateForm>
    </div>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import { validationMixin } from "vuelidate";

import InputText from "@/components/utils/input";
import Textarea from "@/components/utils/textarea.vue";
import InputSelect from "@/components/utils/select.vue";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

import { mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  components: {
    ValidateForm,
    InputSelect,
    InputText,
    Textarea,
  },

  data: () => ({
    structure: {
      name: null,
      siretNumber: null,
      acronyme: null,
      CA: null,
      textIntroduction: null,
      seasonValid: null,
    },
  }),
  validations: {
    structure: {
      name: {
        required,
      },
      siretNumber: {
        required,
        numeric,
        minLength: minLength(14),
        maxLength: maxLength(14),
      },
      CA: {
        required,
        numeric,
      },
      textIntroduction: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters("saisons", ["currentSaisonId"]),
    availableSeason() {
      return [this.currentSaisonId];
    },
    emailError() {
      if (this.$v.structure.siretNumber.$dirty) {
        if (this.structure.siretNumber.length != 14)
          return "Le champ doit contenir 14 caractères";
      }
      return "";
    },
  },

  created() {
    this.structure.seasonValid = this.currentSaisonId;
  },
  methods: {
    next() {
      this.$store.commit("structure/SET_MA_AFFILIATION", {
        structure: this.structure,
      });
      this.$emit("onNext");
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
.readonly__info {
  background-color: rgba(0, 0, 0, 0.3);
}
.protected__form_inscription {
  margin-top: 9px;
  background-color: rgba(0, 0, 0, 0.1);
  font-style: italic;
}
.error-msg {
  position: absolute;
  left: 150;
  bottom: 0;
  color: red;
  font-family: "roboto";
  font-style: normal;
  font-size: 14px;
}
</style>
<style>
.protected__form_inscription input {
  background: none;
}
.select___country__licence span {
  margin-right: 2px !important;
}
.select__pays___side span {
  margin-right: 2px !important;
}
</style>
