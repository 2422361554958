<template>
  <v-row
    no-gutters
    class="gestion_des_licences__dashboard-layout"
    :class="{ loading: loading }"
  >
    <v-col cols="12" lg="10" class="mx-auto">
      <v-row style="height: 100%;">
        <v-col cols="12" class="p-4 licences-adherents-content">
          <v-row style="height: 100%;" class="d-flex justify-center">
            <v-col
              cols="4"
              style="display: flex; align-items: center; margin-top: -200px;"
            >
              <card
                module_name="mes_licences__ajouter_des_options"
                title_card="Ajouter des options d'assurance"
                :ID_User="currentUserId"
                auth
              />
            </v-col>

            <v-col
              cols="4"
              style="display: flex; align-items: center; margin-top: -200px;"
            >
              <card
                module_name="mes_assurances__declarer_un_voyage"
                title_card="Déclarer un voyage"
                :ID_User="currentUserId"
              />
            </v-col>

            <v-col
              @click="sendToAccident"
              cols="4"
              style="display: flex; align-items: center; margin-top: -200px;"
            >
              <card title_card="Déclarer un accident" />
            </v-col>

            <!-- <v-col cols="6">
              <card
                module_name="mes_licences__historique_des_licences"
                title_card="Attestation assurance"
                :ID_User="currentUserId"
                :auth="false"
              />
            </v-col> -->

            <form
              class="accident-form"
              method="post"
              action="https://ffme.cabinet-gomis-garrigues.fr/"
              name="formALLIANZ"
              id="formALLIANZ"
            >
              <input
                type="hidden"
                name="source"
                id="source"
                v-model="accidentForm.source"
              />
              <input
                type="hidden"
                name="nom"
                id="nom"
                v-model="accidentForm.nom"
              />
              <input
                type="hidden"
                name="prenom"
                id="prenom"
                v-model="accidentForm.prenom"
              />
              <input
                type="hidden"
                name="adresse"
                id="adresse"
                v-model="accidentForm.adresse"
              />
              <input
                type="hidden"
                name="cp"
                id="cp"
                v-model="accidentForm.cp"
              />
              <input
                type="hidden"
                name="ville"
                id="ville"
                v-model="accidentForm.ville"
              />
              <input
                type="hidden"
                name="telephone"
                id="telephone"
                v-model="accidentForm.telephone"
              />
              <input
                type="hidden"
                name="email"
                id="email"
                v-model="accidentForm.email"
              />
              <input
                type="hidden"
                name="licence"
                id="licence"
                v-model="accidentForm.licence"
              />
              <input
                type="hidden"
                name="datenaissance"
                id="datenaissance"
                v-model="accidentForm.datenaissance"
              />
              <input
                type="hidden"
                name="sexe"
                id="sexe"
                v-model="accidentForm.sexe"
              />
              <input
                type="hidden"
                name="qualite"
                id="qualite"
                v-model="accidentForm.qualite"
              />
              <input
                type="hidden"
                name="base"
                id="base"
                v-model="accidentForm.base"
              />
              <input
                type="hidden"
                name="ij"
                id="ij"
                v-model="accidentForm.ij"
              />
              <input
                type="hidden"
                name="options0"
                id="options0"
                v-model="accidentForm.options0"
              />
              <input
                type="hidden"
                name="options1"
                id="options1"
                v-model="accidentForm.options1"
              />
              <input
                type="hidden"
                name="options2"
                id="options2"
                v-model="accidentForm.options2"
              />
              <input
                type="hidden"
                name="options3"
                id="options3"
                v-model="accidentForm.options3"
              />
              <input
                type="hidden"
                name="club"
                id="club"
                v-model="accidentForm.club"
              />
              <input
                type="hidden"
                name="clubaffiliation"
                id="clubaffiliation"
                v-model="accidentForm.clubaffiliation"
              />
              <input
                type="hidden"
                name="clubtelephone"
                id="clubtelephone"
                v-model="accidentForm.clubtelephone"
              />
              <input
                type="hidden"
                name="clubemail"
                id="clubemail"
                v-model="accidentForm.clubemail"
              />
              <input
                type="hidden"
                name="clubcorrespondant"
                id="clubcorrespondant"
                v-model="accidentForm.clubcorrespondant"
              />
              <input
                type="hidden"
                name="clubadresse"
                id="clubadresse"
                v-model="accidentForm.clubadresse"
              />
              <input
                type="hidden"
                name="clubcp"
                id="clubcp"
                v-model="accidentForm.clubcp"
              />
              <input
                type="hidden"
                name="clubville"
                id="clubville"
                v-model="accidentForm.clubville"
              />
              <input type="submit" ref="submitAccident" value="" />
            </form>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import card from "@/components/Common/DashboardCard.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "GestionDesLicences__Dashboard",

  components: { card },

  data: () => ({
    loading: true,
    currentUserData: null,
    licences: null,
    accidentForm: {
      source: "FROMFFME",
      nom: "",
      prenom: "",
      adresse: "",
      cp: null,
      ville: "",
      email: "",
      telephone: "",
      licence: null,
      datenaissance: "",
      sexe: null,
      qualite: null,
      base: null,
      ij: 0,
      options0: 0,
      options1: 0,
      options2: 0,
      options3: 0,
      club: "",
      clubaffiliation: null,
      clubtelephone: "",
      clubemail: "",
      clubcorrespondant: "",
      clubadresse: "",
      clubcp: "",
      clubville: "",
    },
  }),
  async created() {
    await this.setData();
    await this.setCurrentStructureFederal();
    await this.setAppTitle({ title: "Gestion de mes assurances" });
    this.loading = false;
  },

  methods: {
    ...mapActions("utilisateurs", [
      "getUtilisateurFullData",
      "getUtilisateur",
      "getUserBasicInfos",
      "getLicenceUtilisateur",
    ]),
    ...mapActions("structure", ["setCurrentStructureFederal", "getStructureAdresses"]),
    ...mapActions("app", ["setAppTitle"]),



    async setData() {
      this.licences = await this.getLicenceUtilisateur({
        user: this.currentUser.id,
        season: this.currentSaison.id,
        'groups[1]': 'licence_structure_accident:read',
        'order[season]': 'desc',
        'order[dateEnd]': 'desc',
      });
    },

    async sendToAccident() {
      this.currentUserData = (
        await this.getUserBasicInfos(this.currentUserId)
      )?.UTI_Utilisateurs[0];
      await this.formatDataToSend();
      this.$refs.submitAccident.click();
    },
    async formatDataToSend() {
      await this.formatUserDataToSend();
      await this.formatAssuranceDataToSend();
      await this.formatStructureDataToSend();
    },
    async formatUserDataToSend() {
      let {
        ADR_Adresse,
        CT_EST_Civilite,
        CT_Email,
        CT_Nom,
        CT_Prenom,
        CT_TelephoneFixe,
        CT_TelephoneMobile,
        DN_DateNaissance,
        LicenceNumero,
      } = this.currentUserData;
      this.accidentForm.nom = CT_Nom;
      this.accidentForm.prenom = CT_Prenom;
      this.accidentForm.adresse = ADR_Adresse[0] ? ADR_Adresse[0].Adresse1 : "";
      this.accidentForm.cp = ADR_Adresse[0] ? ADR_Adresse[0].CodePostal : "";
      this.accidentForm.ville = ADR_Adresse[0] ? ADR_Adresse[0].Ville : "";
      this.accidentForm.email = CT_Email ? CT_Email : "";
      this.accidentForm.telephone = CT_TelephoneMobile || CT_TelephoneFixe;
      this.accidentForm.licence = LicenceNumero;
      this.accidentForm.datenaissance = this.$moment(DN_DateNaissance).format(
        "DD/MM/YYYY"
      );
      this.accidentForm.sexe = CT_EST_Civilite;
    },
    async formatStructureDataToSend() {
      const currentStructure = this.currentActiveLicence.structure;

      const correspondant = await this.getUtilisateur({ ID_Utilisateur: currentStructure.idNomCorrespondant });

      const addresses = await this.getStructureAdresses({
        ID_Structure: currentStructure.id,
      })

      const ADR_Adresse_Structure = addresses.reduce((acc, curr) => {
        if (!acc) return curr;
        return new Date(curr.Z_DateCreation) > new Date(acc.Z_DateCreation) ? curr : acc;
      }, null);

      const NomCorrespondant = {
        CT_Nom: correspondant.CT_Nom,
        CT_Prenom: correspondant.CT_Prenom,
      };

      const {
        idCodeFederal: ID_CodeFederal,
        infoEmail: INFO_Email,
        infoTelephone: INFO_Telephone,
        nomStructure: NomStructure,
      } = currentStructure;

      this.accidentForm.club = NomStructure ? NomStructure : "";
      this.accidentForm.clubaffiliation = ID_CodeFederal ? ID_CodeFederal : "";
      this.accidentForm.clubtelephone = INFO_Telephone ? INFO_Telephone : "";
      this.accidentForm.clubemail = INFO_Email ? INFO_Email : "";
      this.accidentForm.clubcorrespondant = NomCorrespondant
        ? `${NomCorrespondant.CT_Nom} ${NomCorrespondant.CT_Prenom}`
        : "";
      this.accidentForm.clubadresse = ADR_Adresse_Structure
        ? ADR_Adresse_Structure.Adresse1
        : "";
      this.accidentForm.clubcp = ADR_Adresse_Structure
        ? ADR_Adresse_Structure.CodePostal
        : "";
      this.accidentForm.clubville = ADR_Adresse_Structure
        ? ADR_Adresse_Structure.Ville
        : "";
    },
    async formatAssuranceDataToSend() {
      if (!this.currentActiveLicence) return;

      this.currentActiveLicence.licenceOptions.forEach((licenceOption) => {
        switch (licenceOption.option.slug) {
          case 'ski_piste':
            this.accidentForm.options0 = 1;
            break;
          case "slackline_highline":
            this.accidentForm.options1 = 1;
            break;
          case "vtt":
            this.accidentForm.options2 = 1;
            break;
          case "trail":
            this.accidentForm.options3 = 1;
            break;
          case "ij1":
            this.accidentForm.ij = 1;
            break;
          case "ij2":
            this.accidentForm.ij = 2;
            break;
          case "ij3":
            this.accidentForm.ij = 3;
            break;
          case "base":
            this.accidentForm.base = 1;
            break;
          case "base_plus":
            this.accidentForm.base = 2;
            break;
          case "base_plus_plus":
            this.accidentForm.base = 3;
            break;
          case "rc":
            this.accidentForm.base = 0;
            break;
          default:
            break;
        }
      });
    },
  },

  computed: {
    ...mapGetters("structure", ["getStructure"]),
    ...mapGetters("user", ["currentUser", "currentUserId"]),
    ...mapGetters("saisons", ["selectedSaison", "currentSaison"]),

    currentActiveLicence() {
      return this.licences.find(
        (lic) => lic.season.id == this.currentSaison.id && new Date(lic.dateEnd) > new Date()
      );
    },
  },
};
</script>

<style lang="scss">
.gestion_des_licences__dashboard-layout {
  position: relative;
  height: calc(100vh - 80px);
  &.loading {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
  .accident-form {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
</style>
