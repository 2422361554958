<template>
  <div>
    <div v-if="loading">Chargement ...</div>
    <div v-else>
      <h3>Etape 1- Saisie de vos coordonnées</h3>
      <ValidateForm @validated="next" :showFieldObl="mandatory">
        <v-row>
          <v-col cols="12" md="6">
            <div :class="{ protected__form_inscription: isReadOnly }">
              <InputText
                v-model="user.CT_Nom"
                rules="required|min:2|max:250|regexname|dashCounter"
                label="Nom"
                :readonly="isReadOnly"
              />
              <br />
              <InputText
                v-model="user.CT_Prenom"
                rules="required|min:2|max:250|regexname"
                label="Prénom"
                :readonly="isReadOnly"
              />
              <br />

              <InputText
                v-if="isReadOnly"
                v-model="userRenouvellement.LicenceNumero"
                rules="min:0|max:50"
                label="N° de licence:"
                :readonly="isReadOnly"
              />
              <br />

              <v-row align-content="center">
                <v-col cols="12" md="3" class="label_column">
                  <label for="">Date de naissance* </label>
                </v-col>

                <v-col cols="12" md="9">
                  <!-- <inputText
                    v-model="DN_DateNaissance"
                    rules="required"
                    type="date"
                    :maxToday="true"
                    :readonly="isReadOnly"
                  ></inputText> -->
                  <datePicker
                    v-model="DN_DateNaissance"
                    rules="date|required"
                    withIcon="false"
                  />
                </v-col>
              </v-row>

              <validation-provider
                rules="required"
                v-slot="{ errors }"
                name="civilité"
              >
                <v-row align-content="center">
                  <v-col cols="12" md="3" class="label_column">
                    <label for="">Sexe* </label>
                  </v-col>

                  <v-col cols="12" md="9">
                    <v-radio-group
                      v-model="user.EST_Civilite"
                      row
                      dense
                      :readonly="isReadOnly"
                    >
                      <v-radio label="Homme" :value="1"></v-radio>
                      <v-radio label="Femme" :value="0"></v-radio>
                    </v-radio-group>
                    <span class="input__error-message">{{ errors[0] }}</span>
                  </v-col>
                </v-row>
              </validation-provider>
            </div>
            <br />

            <countrySelect
              v-model="user.nationalite"
              rules="required|min:0|max:50"
              label="Nationalité"
              key="countrynationalite"
              class="select___country__licence"
            />
          </v-col>
          <v-col cols="12" md="6">
            <AddressAutocomplete
              :item="user.ADR_Adresse1"
              rules="min:6|max:50"
              label="Adresse"
              :minimum_characters="3"
              ref="adresseAuto1"
              @onSelected="adresseSelected"
              :restrictCountry="restrictCountry"
            ></AddressAutocomplete>
            <br />
            <InputText
              v-model="user.ADR_Adresse2"
              rules="min:3|max:50"
              label="Complément adresse"
            />
            <InputText
              v-model="codePostal"
              :key="refreshCodePostal"
              rules="required|min:0|max:50"
              label="Code postal"
            />
            <br />
            <InputText
              v-model="user.ADR_Ville"
              :key="refreshVille"
              rules="required|min:0|max:50"
              label="Ville"
            />
            <br />
            <!-- <selectInput
              :items="departementsList"
              v-model="user.ADR_Departement"
              @change="departementSelected"
              label="Département"
              rules="required"
              item_text="NomCode"
              item_value="id"
            />
            <br /> -->
            <countrySelect
              v-model="user.ADR_Pays"
              rules="required|min:0|max:50"
              label="Pays"
              :only="'FR'"
              key="countryResidence"
              @onChange="onPaysChanged"
              class="select__pays___side"
            />
          </v-col>
          <v-col cols="12" md="6">
            <InputTelephone
              v-model="user.TelephoneFixe"
              rules="required|min:0|max:50"
              label="Téléphone fixe"
            />
            <br />
            <InputTelephone
              v-model="user.TelephoneMobile"
              rules="min:0|max:50"
              label="Téléphone mobile"
            />
            <br />
            <InputText
              v-model="user.CT_Email"
              rules="required|min:0|max:50"
              label="Courriel"
            />
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-btn
            type="submit"
            class="btn btn-primary"
            style="margin-left: auto"
            :disabled="!isDisabled"
            >Suivant</v-btn
          >
        </v-row>
      </ValidateForm>
    </div>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import InputText from "@/components/utils/input";
import InputTelephone from "@/components/utils/inputTelephone";
import countrySelect from "@/components/utils/select__countries";
import AddressAutocomplete from "@/components/utils/adresseAutocomplete";
import { bus } from "@/main";
import { sleep } from "@/plugins/utils";
import { mapActions } from "vuex";

export default {
  components: {
    ValidateForm,
    InputText,
    countrySelect,
    InputTelephone,
    AddressAutocomplete,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
  },

  props: {
    userRenouvellement: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  async created() {
    this.departementsListTemp = await this.getDepartementsList();
    this.departementsList = this.departementsListTemp?.map((departement) => {
      return {
        ...departement,
        NomCombined: `${departement.CodeDepartement}-${departement.NomDepartement}`,
      };
    });
    if (this.userRenouvellement && this.userRenouvellement.id) {
      this.user.EST_Civilite = this.userRenouvellement.CT_EST_Civilite;
      this.user.CT_Nom = this.userRenouvellement.CT_Nom;
      this.user.CT_NomDeNaissance = this.userRenouvellement.CT_NomDeNaissance;
      this.user.CT_Prenom = this.userRenouvellement.CT_Prenom;

      this.DN_DateNaissance = this.$moment(
        this.userRenouvellement?.DN_DateNaissance
      ).format("YYYY-MM-DD");

      this.user.nationalite = this.userRenouvellement.ID_PaysNationalite;
      this.user.ADR_Adresse1 = this.userRenouvellement.ADR_Adresse[0].Adresse1;
      this.user.ADR_Adresse2 = this.userRenouvellement.ADR_Adresse[0].Adresse2;
      this.codePostal = this.userRenouvellement.ADR_Adresse[0].CodePostal;
      this.user.ADR_Ville = this.userRenouvellement.ADR_Adresse[0].Ville;
      this.user.ADR_Pays = this.userRenouvellement.ADR_Adresse[0].ID_Pays;
      this.user.TelephoneFixe = this.userRenouvellement.CT_TelephoneFixe;
      this.user.TelephoneMobile = this.userRenouvellement.CT_TelephoneMobile;
      this.user.CT_Email = this.userRenouvellement.CT_Email;
      this.$emit("onSetData", this.user);
    }
    setTimeout(() => this.$emit("end-loading"), [1000]);
  },

  data: () => ({
    user: {
      EST_Civilite: null,
      CT_Nom: "",
      CT_Prenom: "",
      CT_NomDeNaissance: "",
      ADR_Adresse2: "",
      ADR_Adresse1: "",
    },
    DN_DateNaissance: "",
    refreshCodePostal: 99,
    refreshVille: 0,
    restrictCountry: "FR",
    departementsList: [],
    test: 1,
    codePostal: "",
    mandatory: false,
  }),
  computed: {
    isReadOnly() {
      if (this.$route.params.user_id) return true;
      return false;
    },
  },
  methods: {
    ...mapActions("basics", ["getDepartementsList"]),

    next() {
      let userAge = this.$moment().diff(this.user.DN_DateDeNaissance, "years");

      this.$emit("onNext");

      const userInfo = {
        ...this.user,
        DN_DateNaissance: this.$moment(this.DN_DateNaissance).toISOString(),
        LicenceNumero: this.userRenouvellement?.LicenceNumero
          ? this.userRenouvellement.LicenceNumero
          : null,
        ID_Utilisateur: this.userRenouvellement?.id
          ? this.userRenouvellement.id
          : null,
        licence: this.userRenouvellement?.UTI_Licences
          ? this.userRenouvellement.UTI_Licences
          : [],
        ADR_CodePostal: this.codePostal,
      };
      this.$emit("onSetData", userInfo);
    },

    departementSelected(item) {},

    // async watchCodePostal(item) {
    //   let codePostal = item.toString().slice(0, 2);
    //   let resultDepts = this.departementsListTemp.map((dep, index) => ({
    //     code: dep.CodeDepartement,
    //     idx: index,
    //   }));
    //   const departementsList = this.departementsListTemp.map((dep) => ({
    //     ...dep,
    //     NomCode: `${dep.CodeDepartement} - ${dep.NomDepartement}`,
    //   }));
    //   let indexCode = resultDepts.filter((dep) => dep.code === codePostal);
    //   this.user.ADR_Departement = { ...departementsList[indexCode[0]?.idx] };
    // },

    async adresseSelected(item) {
      if (typeof item === "string") return (this.user.ADR_Adresse1 = item);

      await sleep(10);
      let adresse1 = item[0].long_name + " " + item[1].long_name;
      this.$refs.adresseAuto1.currentValue = adresse1.toUpperCase();
      this.user.ADR_Adresse1 = adresse1.toUpperCase();
      this.user.ADR_Adresse2 = "";
      // this.user.ADR_Departement = item[3].long_name.toUpperCase()
      this.user.ADR_Ville = item[2].long_name.toUpperCase();
      this.codePostal = item[6] ? item[6].long_name.toUpperCase() : "";
      this.refreshCodePostal++;
      this.refreshVille++;
    },

    onPaysChanged(item) {
      this.user.ID_Pays = item.id;
      this.restrictCountry = item.CodePays;
      this.$refs.adresseAuto?.setRestriction(this.restrictCountry);
    },
  },
  watch: {
    DN_DateNaissance() {
      bus.$emit(
        "reload-group-name",
        this.$moment(this.DN_DateNaissance).toISOString()
      );
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
.readonly__info {
  background-color: rgba(0, 0, 0, 0.3);
}
.protected__form_inscription {
  margin-top: 9px;
  background-color: rgba(0, 0, 0, 0.1);
  font-style: italic;
}
</style>
<style>
.protected__form_inscription input {
  background: none;
}
.select___country__licence span {
  margin-right: 2px !important;
}
.select__pays___side span {
  margin-right: 2px !important;
}
</style>
