var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","small":"","dark":"","color":"indigo"},on:{"click":_vm.openDeclareTripModal}},on),[_c('v-icon',{attrs:{"dark":"","disabled":!_vm.canAccess}},[_vm._v("mdi-plus")])],1)]}}])},[(!_vm.canAccess)?_c('span',[_vm._v("Pour déclarer un voyage, vous devez avoir une licence active pour la saison en cours et posséder à minima une assurance BASE.")]):_c('span',[_vm._v("Déclarer un voyage")])])],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 list__table",attrs:{"dense":"","fixed-header":"","height":"500px","headers":_vm.headers,"items":_vm.allVoyageUser,"item-key":"id"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:"edit",staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"green","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.openVisualVoyage(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualiser la déclaration de voyage")])])]}},{key:"item.factures",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:"edit",staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"light-blue","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.printFacture(
                  item,
                  ("facture-" + (_vm.currentUser.CT_Prenom) + "-" + (_vm.currentUser.CT_Nom) + "-saison" + (_vm.currentSaison.id)),
                  _vm.currentUser
                )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf")])],1)]}}],null,true)},[_c('span',[_vm._v("Télécharger la facture de paiement")])])]}},{key:"item.attestations",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:"edit",staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"light-blue","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.printAttestation(
                  item,
                  ("attestation-" + (_vm.currentUser.CT_Prenom) + "-" + (_vm.currentUser.CT_Nom) + "-saison" + (_vm.currentSaison.id)),
                  _vm.currentUser
                )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-cloud")])],1)]}}],null,true)},[_c('span',[_vm._v("Télécharger l'attestation")])])]}}])})],1),_c('VoyageModal',{attrs:{"modalIsOpen":_vm.openModal,"context":{
      currentUserId: this.currentUserId,
      currentUser: this.currentUser,
      currentSaison: this.currentSaison,
      currentStructure: this.currentStructure,
      allVoyageUser: this.getVoyageUser,
    }},on:{"on-close":_vm.closeDetailModal}}),_c('VoyageDetailModal',{key:_vm.voyageDetail.id,attrs:{"openModal":_vm.openDetailModal,"context":_vm.voyageDetail},on:{"on-close":function () { return (_vm.openDetailModal = false); },"onPrintAttestation":_vm.printAttestation,"onPrintFacture":_vm.printFacture}}),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('PrintAttestation',{ref:"attestation",attrs:{"print":_vm.print}}),_c('PrintFacture',{ref:"facture",attrs:{"print":_vm.print}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }