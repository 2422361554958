<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :name="getName">
    <v-row align-content="center" class="adresse_autocomplete">
      <v-col cols="12" md="3" v-if="label" class="field__label-column">
        <label for
          >{{ label }}
          <span :class="{ 'red-label': isRedLabel }">
            {{ isRequired ? "*" : "" }}</span
          ></label
        >
      </v-col>

      <v-col cols="12" md="9" style="flex: 1" class="field__value-column">
        <input
          :id="inputId"
          :disabled="disabled"
          v-model="currentValue"
          style="width: 100%"
          :class="getClass(errors)"
          type="text"
          @change="changeValue"
          @keydown.enter.prevent="preventKey"
          on="preventKey()"
          class="text-auto-complete"
          placeholder="Insérer une adresse"
        />
        <span class="error-message" style="margin-top: 42px">{{
          errors[0]
        }}</span>
      </v-col>
    </v-row>
  </validation-provider>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},

  data: () => ({
    currentValue: "",
    autocomplete: "",
    inputId: Math.random().toString(12).substring(4),
  }),

  async created() {
    this.currentValue = this.item;
    // console.log(loader)
    if (!this.googleLoader) {
      await this.setGoogleLoader();
    }
    let googleLoader = await this.googleLoader.load();
    console.log(`googleLoader`, googleLoader);
    let input = document.getElementById(this.inputId);
    let type = "geocode";
    if (this.onlyCities) {
      type = "(regions)";
    }
    this.autocomplete = new googleLoader.maps.places.Autocomplete(input, {
      types: [type],
    });
    console.log(`this.autocomplete`, this.autocomplete);
    this.autocomplete.setFields([
      "address_components",
      "geometry",
      "icon",
      "name",
    ]);
    this.setRestriction();
    this.autocomplete.addListener("place_changed", this.fillAdresse);
    // console.log(this.item)
    if (this.item !== "") {
      this.currentValue = this.item;
    }
    return;
  },

  computed: {
    ...mapGetters("basics", ["googleLoader"]),

    getName() {
      return this.label.toLowerCase();
    },

    isRequired() {
      return this.rules.indexOf("required") !== -1;
    },
  },

  methods: {
    ...mapActions("basics", ["setGoogleLoader"]),

    getClass(errors) {
      let futurClasses = "";
      if (errors[0]) {
        futurClasses += " is-invalid ";
      }

      return futurClasses;
    },

    setRestriction(country) {
      if (country) {
        this.autocomplete.setComponentRestrictions({ country: country });
      } else if (this.restrictCountry) {
        this.autocomplete.setComponentRestrictions({
          country: this.restrictCountry,
        });
      }
      return;
    },

    fillAdresse() {
      // Get the place details from the autocomplete object.
      var place = this.autocomplete.getPlace();
      //console.log(place.geometry.location.lat())
      // this.nomAdresse = place.name
      // this.adresse1 = place.address_components[0].long_name +', ' + place.address_components[1].long_name
      // this.adresse2 = place.address_components[3].long_name
      // this.ville = place.address_components[2].long_name
      // this.codePostal = place.address_components[6].long_name
      this.$emit("onSelected", place.address_components);
      this.$emit("onLocationSelected", place.geometry.location);
      console.log(`place.geometry.location`, place.geometry.location);
      // console.log(place)
    },

    setCurrentValue(item) {
      this.$emit("onSelected", item);
      this.currentValue = item;
    },

    changeValue() {
      this.$emit("onChange", this.currentValue);
    },
  },

  props: {
    label: {
      type: String,
      default: "Rechercher une adresse",
    },

    rules: {
      type: String,
    },

    restrictCountry: {
      type: String,
    },

    item: {
      type: String,
      default: null,
    },

    onlyCities: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isRedLabel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: #2d4980;
  font-size: 2rem;
  top: 4px;
}

.label_column {
  width: auto;

  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}

.v-autocomplete {
  margin: 0;
  padding: 0;
}

.v-input__slot {
  max-width: 400px !important;
  padding: 0 !important;

  .v-select__slot {
    border: 1px solid #dfe5f9;

    .v-select__selections {
      padding: 4px 5px;

      .v-select__selection--comma {
        font-weight: 200;
        font-size: 0.8rem;
      }
    }
    input {
      border: none;
      padding: 4px 5px;
      font-weight: 200;
      font-size: 0.8rem;
      max-width: 400px;

      &.is-invalid {
        border-color: red;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
.text-auto-complete {
  font-family: "Roboto";
  font-size: 14px;
  // font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  line-height: 24px;
}

.error-message {
  display: flex;
  position: absolute;
  font-weight: 200;
  font-size: 0.7rem;
  font-style: italic;
  color: red;
  bottom: -5px;
}
.red-label {
  color: red;
}
</style>
