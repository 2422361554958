<template>
  <div>
    <h3>Etape 3 - Certificat médical</h3>
    <div>
      <v-alert text type="info">
        <p>Pour activer la licence, la validation du questionnaire santé est nécessaire. Seuls les licenciés suivants devront présenter un certificat médical tous les 3 ans (et attestation du questionnaire santé dans l'intervalle des 3 ans) :</p>
        <ul style="list-style: disc;">
          <li>Les licenciés (jeunes ou adultes) ayant répondu "oui" à l'une des questions du questionnaire santé</li>
          <li>Les compétiteurs adultes escalade à partir des 1/2 Finales Championnat France et Coupe de France</li>
          <li>Les compétiteurs adultes en ski-alpinisme</li>
        </ul>
      </v-alert>
    </div>
    <ValidateForm @validated="next">
      <v-row>
        <v-col cols="12">
          <div class="d-flex flex-column">
            <certificat-medical-upload
                @formCompleted="onFormCompleted"
                isLicenceHC
                :disableRequiredFields="qsValidated"
            />
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <hr />
        </v-col>
        <v-col cols="12" class="text-center" style="margin-top: -30px;">
          <span style="display: inline-block; background-color: white; padding: 0 10px;">Ou</span>
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col cols="12">
          <div class="d-flex flex-column">
            <QuestionnaireSante
              headerTitle="Questionnaire santé"
              headerContent="Télécharger le questionnaire santé"
              content="Le questionnaire santé est à répondre de votre côté."
              :btnTitle="btnText"
              @download="downloadQS"
              @clicked="validateQS"
              :is-disabled="false"
              isInfoToolTipDisabled
              :panel="1"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="my-4" no-gutters>
        <v-btn text class="btn btn-gray" @click.prevent="$emit('onPrevious')"
          >Retour</v-btn
        >
        <v-btn
          type="submit"
          class="btn btn-primary"
          :disabled="!isFormComplete && !qsValidated"
          style="margin-left: auto;"
          >Suivant</v-btn
        >
      </v-row>
    </ValidateForm>


  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";
import CertificatMedicalUpload from "@/components/utils/modals/fileUpload/CertificatMedicalUpload";
import QuestionnaireSante from "./QuestionnaireSante.vue";
import * as crypto from "crypto";

export default {
  components: { ValidateForm, CertificatMedicalUpload, QuestionnaireSante },

  data: () => ({
    uploadedCM: null,
    datenaissance: false,
    isFormComplete: false,
    userBirthdate: null,
    qsValidated: false,
  }),

  async created() {
    await this.getListTypesDocument();
    await this.setData();
    bus.$on("load-pdf-age", (DN_Naissance) => {
      this.calculateAge(DN_Naissance);
      this.userBirthdate = DN_Naissance;
    });
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("saisons", ["currentSaison"]),
    btnText() {
      if (this.qsValidated)
        return "Un questionnaire santé a été validé";
      return "J'atteste que le questionnaire santé permet la pratique";
    },
  },

  methods: {
    ...mapActions("basics", ["getListTypesDocument", "getTemplatesBySlug"]),
    calculateAge(dateNaissance) {
      // let dateNaissance = this.$moment(this.currentUser.DN_DateNaissance).format("DD/MM/YYYY");
      const userAge = this.$moment().diff(dateNaissance, "years");
      if (userAge < 18) dateNaissance = true;
      dateNaissance = false;
    },

    next() {
      this.$emit("onNext");

      this.$emit("onSetData", {
        file: this.uploadedCM,
      });
    },
    onFormCompleted(value) {
      this.isFormComplete = value.complete;
      if (value.complete) {
        this.uploadedCM = value;
      }
      console.log('onFormCompleted', value)
    },

    closeModal() {
      this.modalIsOpen = false;
    },

    async setData() {
      this.certficatsMedicaux = this.getList.LIST_DocumentType.filter(
        (cm) =>
          cm.LIST_DocumentCategory &&
          cm.LIST_DocumentCategory.SlugCategorieDocument ==
            "certificats_medicaux"
      ).filter(
        (cm) =>
          cm.SlugTypeDocument == "certificat_medical_competition" ||
          cm.SlugTypeDocument == "certificat_medical_loisir"
      );
      return;
    },
    generateUUID() {
      const bytes = crypto.randomBytes(16);

      bytes[6] = (bytes[6] & 0x0f) | 0x40;
      bytes[8] = (bytes[8] & 0x3f) | 0x80;

      return bytes.toString('hex').match(/(.{8})(.{4})(.{4})(.{4})(.{12})/).slice(1).join('-');
    },
    async downloadQS() {
      const age = this.$moment().diff(this.$moment(this.userBirthdate), "years");

      let payload = {
        ID_Saison: this.currentSaison.id,
        SlugNomTemplate: +age >= 18 ? "QS_majeur" : 'QS_mineur',
      };
      let template = await this.getTemplatesBySlug(payload);

      const link = document.createElement("a");
      link.href = template[0].CheminDocument;
      link.download = template[0].NomDocument;
      link.target = "_blank";
      link.click();
      URL.revokeObjectURL(link.href);
    },
    validateQS() {
      this.qsValidated = true;

      this.uploadedCM = {
        attestTrue: false,
        certicatsMedicalType: 60,
        cmSelected: 'Questionnaire santé',
        complete: true,
        endDateFormated: `${this.currentSaison.id}-08-31`,
        filename: this.generateUUID() + '.pdf',
        finValidite: null,
        loadedFileContent: 'data:application/pdf;base64,',
        selectedSeason: this.currentSaison.id,
        uploadedCMIDs: [],
        ID_DocumentType: 60,
      };
    },
  },

  props: {
    user: {
      type: Object,
    },
  },
};
</script>

<style scopped>
label {
  margin-bottom: 0 !important;
  font-size: 0.8rem;
}
</style>
