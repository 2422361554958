<template>
  <div>
    <h3>Etape 5 - Récapitulatif</h3>
    <ValidateForm @validated="next" :showFieldObl="false">
      <v-row>
        <v-col cols="12">
          <h5>
            Coordonnées -
            <a
              href=""
              style="font-size: 0.8rem"
              @click.prevent="$emit('goToStep', 1)"
            >
              Modifier</a
            >
          </h5>
          <v-row>
            <v-col cols="12" md="6">
              <div>
                <span class="font-weight-bold">Nom: </span> {{ user.CT_Nom }}
              </div>
              <div>
                <span class="font-weight-bold">Prénom: </span>
                {{ user.CT_Prenom }}
              </div>
              <div>
                <span class="font-weight-bold">Date de naissance: </span>
                {{ $moment(user.DN_DateNaissance).format("DD/MM/YYYY") }}
              </div>
              <div>
                <span class="font-weight-bold">Sexe: </span> {{ civilite }}
              </div>
              <div>
                <span class="font-weight-bold">Nationalité: </span>
                {{ paysNationalite && paysNationalite.NomPays }}
                <country-flag
                  v-if="paysNationalite"
                  style="margin: -16px"
                  :country="paysNationalite.CodePays"
                  size="small"
                />
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div>
                <span class="font-weight-bold">Adresse: </span>
                {{ user.ADR_Adresse1 }}
              </div>
              <div>
                <span class="font-weight-bold">Complément adresse: </span>
                {{ user.ADR_Adresse2 }}
              </div>
              <div>
                <span class="font-weight-bold">Code postal: </span>
                {{ user.ADR_CodePostal }}
              </div>
              <div>
                <span class="font-weight-bold">Ville: </span>
                {{ user.ADR_Ville }}
              </div>
              <div>
                <span class="font-weight-bold">Pays: </span>
                {{ paysAdresse.NomPays }}
                <country-flag
                  v-if="paysAdresse"
                  style="margin: -16px"
                  :country="paysAdresse.CodePays"
                  size="small"
                />
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <div>
                <span class="font-weight-bold">Téléphone fixe: </span>
                {{ user.TelephoneFixe }}
              </div>
              <div>
                <span class="font-weight-bold">Téléphone mobile: </span>
                {{ user.TelephoneMobile }}
              </div>
              <div>
                <span class="font-weight-bold">Courriel: </span>
                {{ user.CT_Email }}
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <h5>
            Groupe -
            <a
              href=""
              style="font-size: 0.8rem"
              @click.prevent="$emit('goToStep', 2)"
            >
              Modifier</a
            >
          </h5>
          <v-row>
            <v-col cols="12" md="12">
              <div>
                <span class="font-weight-bold">Groupe: </span>
                {{ user.NomGroupe }}
              </div>
              <div>
                <span class="font-weight-bold">Activité: </span>
                {{ user.Section }}
              </div>
              <!-- <div>
                <span class="font-weight-bold">Tarif d'adhésion:</span>
                {{ user.Tarif }}
              </div> -->
              <div>
                <span class="font-weight-bold">Modalité d'organisation: </span>
                {{ user.NomModaliteOraganisation }}
              </div>
              <div v-if="hideType">
                <span class="font-weight-bold">Type de pratique: </span>
                {{ user.PratiqueType }}
              </div>
              <div v-if="hideType">
                <span class="font-weight-bold">Niveau: </span>
                {{ user.PratiqueNiveau }}
              </div>
              <div>
                <span class="font-weight-bold">Age minimum </span>
                {{ user.AgeMin }} ans
              </div>
              <div>
                <span class="font-weight-bold">Age maximum: </span>
                {{ user.AgeMax }} ans
              </div>

              <div>
                <span class="font-weight-bold">Horaires de pratique: </span>
                <div v-for="(horaire, i) in user.Horaires" :key="i">
                  {{
                    `${i + 1}- ${horaire.Jour}: ${horaire.Z_HeureDebut}-${
                      horaire.Z_HeureFin
                    } (${horaire.ADR_Adresse.Adresse1} /${
                      horaire.ADR_Adresse.Ville
                    })`
                  }}
                </div>
              </div>
              <div v-if="user.OptionAdhesion.length">
                <span class="font-weight-bold">Mes options d'adhésion: </span>
                <v-chip
                  small
                  class="mx-1"
                  v-for="(option, i) in user.OptionAdhesion"
                  :key="i"
                >
                  {{ `${option.NomEtPrix}` }}
                </v-chip>
              </div>
              <div v-else>
                <span class="font-weight-bold">Mes options d'adhésion: </span>
                Aucun
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <h5>
            Licence et Assurances -
            <a
              href=""
              style="font-size: 0.8rem"
              @click.prevent="$emit('goToStep', 2)"
              >Modifier</a
            >
          </h5>
          <v-row>
            <v-col cols="12" md="12">
              <div v-if="assurance" style="margin: 6px 0">
                <span class="font-weight-bold">Licence: </span>
                <v-chip small class="mx-1">
                  {{
                    user.licence &&
                    `${user.licence.NomTarif} (${user.licence.MontantUnitaire}€)`
                  }}
                </v-chip>
              </div>
              <div v-if="assurance">
                <span class="font-weight-bold">Assurance: </span>
                <v-chip small class="mx-1">
                  {{ assurancesCategorie }} ({{ assurance.MontantUnitaire }} €)
                </v-chip>
              </div>
              <div
                v-if="user.optionAssurance && user.optionAssurance[0]"
                style="margin: 6px 0"
              >
                <span class="font-weight-bold">Option(s) d'assurance: </span>
                <span
                  v-if="user.optionAssurance.length && user.optionAssurance[0]"
                >
                  <optionAssuranceChips
                    v-for="(opt, index) in user.optionAssurance"
                    :key="index"
                    :assurance_id="opt"
                  />
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <h5>
            Certificat médical -
            <a
              href=""
              style="font-size: 0.8rem"
              @click.prevent="$emit('goToStep', 3)"
              >Modifier</a
            >
          </h5>
          <v-row>
            <v-col v-if="user.file" cols="12">
              <div>
                <span class="font-weight-bold">Type de certificat: </span
                >{{ certificatType }}
              </div>
              <!-- <div>
                <span class="font-weight-bold">Certificat: </span>{{ user.file.file.name }}
              </div> -->
              <div style="padding: 1px 0">
                <span class="font-weight-bold"
                  >Date de délivrance du certificat médical: </span
                >{{ $moment(user.file.validationDate).format("DD/MM/YYYY") }}
              </div>

              <v-alert
                v-if="
                  $moment(user.file.validationDate) <
                  $moment().subtract(1, 'years')
                "
                border="top"
                color="red lighten-2"
                dark
                class="mt-2"
              >
                Le certificat doit dater de moins d'un an
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <h5>Total à régler</h5>
          <v-row>
            <v-col cols="12" md="12">
              <div style="padding: 1px 0">
                <span class="font-weight-bold">Cotisation nationale: </span>

                <v-chip small class="mx-1">
                  {{ user.licence.MontantUnitaire }} €
                </v-chip>
              </div>
              <div style="padding: 1px 0">
                <span class="font-weight-bold">Cotisation ligue: </span>

                <v-chip small class="mx-1"> {{ prixLigue }} € </v-chip>
              </div>
              <div style="padding: 1px 0">
                <span class="font-weight-bold"
                  >Cotisation comité térritorial:
                </span>

                <v-chip small class="mx-1"> {{ prixCT }} € </v-chip>
              </div>
              <div style="padding: 3px 0">
                <span class="font-weight-bold">Cotisation club: </span>

                <v-chip small class="mx-1"> {{ user.cotisationClub }} €</v-chip>
              </div>
              <div style="padding: 1px 0">
                <span class="font-weight-bold"
                  >Total des options d'adhésion:
                </span>

                <v-chip small class="mx-1">
                  {{ user.totalOptionAdhesion }} €
                </v-chip>
              </div>
              <div style="padding: 1px 0">
                <span class="font-weight-bold">Total des assurances: </span>

                <v-chip small class="mx-1"> {{ totalAssurance }} € </v-chip>
              </div>
              <br />
              <div>
                <span class="font-weight-bold" style="font-size: 1.2rem"
                  >Total à payer:
                </span>

                <v-chip small class="mx-1" color="success">
                  {{ totalAPayer }} €
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-checkbox style="width: 100%" v-model="confirm">
            <template v-slot:label>
              <div>
                En cochant cette case, je reconnais avoir pris connaissance de
                la
                <a target="_blank" :href="`${templateUrl}`" @click.stop>
                  notice d'information d'assurance
                </a>

                de la FFME et d'en accepter les termes sans aucune réserve.
              </div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-overlay :value="recapIsLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-row no-gutters class="my-4">
        <v-btn text class="btn btn-gray" @click.prevent="$emit('onPrevious')"
          >Retour</v-btn
        >
        <v-btn
          type="submit"
          class="btn btn-primary ml-auto"
          :loading="loading"
          :disabled="!canContinue"
          >Valider</v-btn
        >
      </v-row>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import { mapActions, mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";
import optionAssuranceChips from "./assuranceChips.vue";
export default {
  components: { ValidateForm, CountryFlag, optionAssuranceChips },

  data: () => ({
    confirm: false,
    tarifs: [],
    loading: false,
    idsParents: {},
    tarifLigue: null,
    tarifCT: null,
    recapIsLoading: true,
    templateUrl: {},
  }),

  async created() {
    await this.getListTypesDocument();
    await this.getListPays();
    this.tarifs = await this.getTarifsFFME({
      ID_Saison: this.currentSaison.id,
    });
    await this.setData();
    await this.getParentOfStructure();
    let payload = {
      ID_Saison: this.currentSaison.id,
      SlugNomTemplate: "Notice_d_assurance",
    };
    const template = await this.getTemplatesBySlug(payload);

    if (template.length) {
      let splitUrl = template[0]?.CheminDocument.split("/");
      this.templateUrl =
        "https://dev.myffme.fr/" +
        splitUrl[4] +
        "?response-content-disposition=inline";
      console.log("this.templateeeeeeeeee", this.templateUrl);
    }
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("saisons", ["currentSaison"]),
    assurancesCategorie() {
      if (this.assurance) {
        return this.assurance?.LIST_TarifCategorie.AssuranceType
          .NomTypeAssurance;
      } else return "";
    },
    totalAssurance() {
      if (this.assurance?.MontantUnitaire) {
        return this.assurance.MontantUnitaire + this.user.totalAssurance;
      } else return 0;
    },
    totalAPayer() {
      if (this.user.licence.MontantUnitaire && this.user.cotisationClub) {
        return (
          this.totalAssurance +
          this.user.licence.MontantUnitaire +
          this.user.totalOptionAdhesion +
          this.prixCT +
          this.prixLigue +
          this.user.cotisationClub
        );
      } else return 0;
    },
    // recapIsLoading() {
    //   if (this.totalAPayer !== 0) {
    //     return false;
    //   } else return true;
    // },
    canContinue() {
      if (
        this.confirm &&
        this.$moment(this.user.file.validationDate) >
          this.$moment().subtract(1, "years")
      ) {
        return true;
      }
      return false;
    },
    prixCT() {
      if (this.tarifCT) {
        console.log("this.tarifCT", this.tarifCT);
        return this.tarifCT.MontantUnitaire;
      } else return 0;
    },

    prixLigue() {
      if (this.tarifLigue) {
        console.log("this.tarifLigue", this.tarifLigue);

        return this.tarifLigue.MontantUnitaire;
      } else return 0;
    },
    currentFileName() {
      return "Veuillez choisir un fichier";
    },

    currentFileDate() {
      return "";
    },

    userDepartement() {
      if (!this.user.ADR_Departement) return "-";
      return this.user.ADR_Departement.NomDepartement;
    },

    civilite() {
      if (this.user.EST_Civilite == 1) return "Homme";
      if (this.user.EST_Civilite == 0) return "Femme";
      return "";
    },

    paysNationalite() {
      if (!this.user.nationalite) return "";
      return this.getList.ADR_Pays.find(
        (pays) => pays.id == this.user.nationalite
      );
    },

    paysAdresse() {
      if (!this.user.ADR_Pays) return "";
      return this.getList.ADR_Pays.find(
        (pays) => pays.id == this.user.ADR_Pays.id
      );
    },

    licenceType() {
      if (!this.tarifs) return;
      return this.user.licence;
    },

    assurance() {
      if (!this.tarifs) return;
      return this.tarifs.find(
        (tar) =>
          tar?.LIST_TarifCategorie?.ID_TypeAssurance == this.user.assurance
      );
    },

    certificatType() {
      if (!this.user.certicatsMedicalType) return "";

      let certificat = this.getList.LIST_DocumentType.find(
        (cm) => cm.id == this.user.certicatsMedicalType
      ).NomTypeDocument.replace("Certificat médical : ", "");

      if (this.user.questionnaire === 0) {
        certificat +=
          ", Gestion des certificats médicaux / Questionnaire santé";
      }

      return certificat;
    },
    hideType() {
      const organisation = this.user.NomModaliteOraganisation;
      if (organisation == "Non-encadrée / Autonome") {
        return false;
      }
      return true;
    },
  },

  methods: {
    ...mapActions("basics", [
      "getListTypesDocument",
      "getListPays",
      "getListTypesDocument",
      "getTarifsFFME",
      "getTemplatesBySlug",
    ]),
    ...mapActions("structure", ["getStructureParent"]),
    ...mapActions("tarifs", [
      "getAllTarifLicence",
      "getTarifLicenceLigue",
      "getTarifLicenceCT",
    ]),
    async getParentOfStructure() {
      const strParentIDs = await this.getStructureParent({
        ID_Structure: +`${this.$route.params.structure_id}`,
      });

      // On collecte donc les ids des ligue ct

      if (strParentIDs[0]?.ID_ComiteTerritorial || strParentIDs[0]?.ID_Ligue) {
        // idsParents.push(strParentIDs[0].ID_ComiteTerritorial);
        // this.ID_CT = strParentIDs[0].ID_ComiteTerritorial;
        this.idsParents = {
          ID_CT: strParentIDs[0].ID_ComiteTerritorial,
          ID_Ligue: strParentIDs[0].ID_Ligue,
        };
      }
      const ligue = await this.getTarifLicenceLigue({
        ID_Saison: this.currentSaison.id,
        ID_Structure: this.idsParents.ID_Ligue,
      });
      this.tarifLigue = ligue[0];
      const CT = await this.getTarifLicenceCT({
        ID_Saison: this.currentSaison.id,
        ID_Structure: this.idsParents.ID_CT,
      });
      this.tarifCT = CT[0];
    },

    next() {
      this.loading = true;
      this.$emit("validate");

      this.$emit("onSetPrice", {
        totalAPayer: this.totalAPayer,
        cotisatioNationale: this.user.licence.MontantUnitaire,
        totalAssurance: this.totalAssurance,
        totalOptionAdhesion: this.user.totalOptionAdhesion,
        cotisationCT: this.prixCT,
        cotisationLigue: this.prixLigue,
        cotisationClub: this.user.cotisationClub,
      });

      // console.log("totalPrice step 5", totalPrice);
    },

    async setData() {
      console.log("all data step=====>", this.user);
      return;
    },
  },
  watch: {
    totalAPayer() {
      this.totalAPayer !== 0
        ? (this.recapIsLoading = false)
        : (this.recapIsLoading = true);
    },
  },
  props: {
    user: {
      type: Object,
    },
  },
};
</script>

<style scoped>
h5 {
  background-color: rgb(218, 218, 218);
  padding: 10px;
}
label {
  margin-bottom: 0 !important;
  font-size: 0.8rem;
}
.v-input__slot {
  max-width: 100% !important;
  padding: 0 !important;
}
</style>
