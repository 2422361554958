<template>
  <div>
    <h3>Etape 3 - Licence et assurance</h3>
    <ValidateForm @validated="next" :showFieldObl="mandatory">
      <v-row>
        <v-col cols="12" md="12" class="mx-3">
          <h6>Type de licence*</h6>

          <v-radio-group
            @change="licenceChange"
            v-model="licenceTypeSelected"
            dense
          >
            <v-radio
              v-for="(licence, index) in licences"
              :key="index"
              :label="`${licence.NomTarif} (${licence.MontantUnitaire}€)`"
              :value="licence.id"
            ></v-radio>
          </v-radio-group>
          <h6 style="margin-top: 5px;">Assurance*</h6>

          <v-radio-group
            @change="assuranceChange"
            v-model="assurancesSelected"
            dense
          >
            <v-radio
              v-for="(assu, index) in assurancesObligatoires"
              :key="index"
              :label="
                assu.LIST_TarifCategorie &&
                assu.LIST_TarifCategorie.AssuranceType &&
                assu.LIST_TarifCategorie.AssuranceType.NomTypeAssurance +
                  ' ( +' +
                  assu.MontantUnitaire +
                  '€)'
              "
              :value="
                assu.LIST_TarifCategorie &&
                assu.LIST_TarifCategorie.AssuranceType &&
                assu.LIST_TarifCategorie.AssuranceType.id
              "
            ></v-radio>
          </v-radio-group>
          <v-alert
            v-if="isResponsabiliteCivile"
            border="top"
            color="red lighten-2"
            dark
            class="mt-2"
          >
            En choisissant une assurance de type "Responsabilité Civile", vous
            ne pouvez pas souscrire à des options d’assurance
          </v-alert>

          <div class="mt-4">
            <h6>
              Options facultatives (uniquement avec les assurances base, base+
              et base++)
            </h6>

            <div class="d-flex align-center">
              <v-checkbox
                v-for="(assu, index) in assurancesOptions"
                :key="index"
                dense
                v-model="assurancesOptionsSelected"
                :disabled="isResponsabiliteCivile"
                :label="
                  assu.LIST_TarifCategorie.AssuranceType.NomTypeAssurance +
                  ' ( +' +
                  assu.MontantUnitaire +
                  '€)'
                "
                :value="assu.LIST_TarifCategorie.AssuranceType.id"
                style="margin-top: 0;"
              ></v-checkbox>
            </div>
            <div class="mt-4 assuranceIJ">
              <h6>Assurance complémentaire</h6>
              <v-radio-group v-model="assurancesCompSelected" row dense>
                <v-radio
                  v-for="(assu, index) in assurancesComp"
                  :key="index"
                  :disabled="isResponsabiliteCivile"
                  :label="
                    assu.LIST_TarifCategorie.AssuranceType.NomTypeAssurance +
                    ' ( +' +
                    assu.MontantUnitaire +
                    '€)'
                  "
                  :value="assu.LIST_TarifCategorie.AssuranceType.id"
                ></v-radio>
              </v-radio-group>
            </div>
            <QuestionnaireSante
              v-if="this.age <= 18"
              :pdfType="datenaissance"
              @onValidateQS="addCMItem"
              @onQuestionnaireChange="onChangeRadio"
              :showButton="false"
            />
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-4">
        <v-btn text class="btn btn-gray" @click.prevent="$emit('onPrevious')"
          >Retour</v-btn
        >
        <v-btn
          type="submit"
          class="btn btn-primary ml-auto"
          :disabled="!canContinue"
          style="margin-left: auto;"
          >Suivant</v-btn
        >
      </v-row>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import { mapGetters, mapActions } from "vuex";
import QuestionnaireSante from "@/components/User/Questionnaire_Sante.vue";
import { bus } from "@/main";

export default {
  components: { ValidateForm, QuestionnaireSante },

  data: () => ({
    mandatory: false,
    data: {},
    licencesType: [],
    assurances: [],
    assurancesSelected: null,
    assurancesOptionsSelected: [],
    licenceSelected: null,
    // NumeroLicence: "",
    assurancesCompSelected: null,
    licences: [],
    licenceTypeSelected: null,
    datenaissance: false,
    questionnaire: null,
    age: null,
    invalidLicence: false,
  }),

  async created() {
    await this.setData();
    bus.$on("reload-group-name", (DN_Naissance) => {
      // this.datenaissance = DN_Naissance,
      this.calculateAge(DN_Naissance);
      this.age = this.$moment().diff(this.$moment(DN_Naissance), "years");
      console.log("age creates", this.age);
    });
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("structure", [
      "hasCodeFederal",
      "currentStructure",
      "currentStructureId",
    ]),
    ...mapGetters("user", ["currentUser", "currentUserId", "getRole"]),

    assurancesObligatoires() {
      let assus = this.assurances
        .filter(
          (assu) =>
            !assu.LIST_TarifCategorie?.AssuranceType?.EST_Option &&
            !assu.LIST_TarifCategorie?.AssuranceType
              ?.EST_AssuranceComplementaire
        )
        .sort((a, b) => {
          return (
            a.LIST_TarifCategorie?.AssuranceType?.Ordre -
            b.LIST_TarifCategorie?.AssuranceType?.Ordre
          );
        });
      return assus.filter((assu) => assu.Ordre != null);
    },

    assurancesOptions() {
      return this.assurances.filter(
        (assu) =>
          assu.LIST_TarifCategorie?.AssuranceType?.EST_Option &&
          !assu.LIST_TarifCategorie?.AssuranceType?.EST_AssuranceComplementaire
      );
    },

    isResponsabiliteCivile() {
      let assuSelected = this.assurances.find(
        (assu) =>
          assu.LIST_TarifCategorie?.AssuranceType?.id == this.assurancesSelected
      );
      if (
        assuSelected &&
        assuSelected.LIST_TarifCategorie?.SlugCategorieTarif == "assurance_rc"
      )
        return true;
      return false;
    },

    assurancesComp() {
      return this.assurances.filter(
        (assu) =>
          assu.LIST_TarifCategorie?.AssuranceType?.EST_AssuranceComplementaire
      );
    },

    canContinue() {
      if (
        this.assurancesSelected !== null &&
        this.licenceTypeSelected !== null &&
        this.questionnaire !== null
      )
        return true;
      else if (
        this.assurancesSelected !== null &&
        this.licenceTypeSelected !== null &&
        this.age > 18
      )
        return true;
      return false;
    },
    // watchLicence() {
    //   return this.NumeroLicence
    // }
  },

  mounted() {
    this.setLicenceList();
  },

  methods: {
    ...mapActions("basics", [
      "getTarifsFFME",
      "getListTypeLicence",
      "insertDocument",
    ]),
    ...mapActions("tarifs", ["getTarifsLicence_Assurances"]),

    next() {
      this.licenceSelected = this.licencesType.find(
        (licence) => licence.id === this.licenceTypeSelected
      );

      this.$emit("onNext");

      let assu = [];

      if (this.assurancesSelected) {
        assu.push(this.assurancesCompSelected);
      }

      if (this.assurancesOptionsSelected.length) {
        assu = [...this.assurancesOptionsSelected, ...assu];
      }
      const allOptionAssurance = [
        ...this.assurancesOptions,
        ...this.assurancesComp,
      ];
      const assuranceFiltered = assu.filter((assurance) => assurance !== null);
      const optionAssu = assuranceFiltered.map((assurance) =>
        allOptionAssurance.find(
          (option) => option.LIST_TarifCategorie.ID_TypeAssurance === assurance
        )
      );
      let totalOptionAssurance = 0;
      optionAssu.map((option) => {
        return (totalOptionAssurance += option?.MontantUnitaire);
      });

      this.$emit("onSetData", {
        licence: this.licenceSelected,
        assurance: this.assurancesSelected,
        optionAssurance: assu,
        // numeroLicence: this.NumeroLicence,
        totalAssurance: totalOptionAssurance,
        questionnaire: this.questionnaire,
      });
    },

    licenceChange() {
      this.licenceSelected = this.licencesType.find(
        (licence) => licence.id === this.licenceTypeSelected
      );
      if (
        this.licenceSelected?.NomTarif === "Licence jeune" &&
        this.age >= 18
      ) {
        this.invalidLicence = true;
      } else this.invalidLicence = false;
    },

    assuranceChange(assurance) {
      let assuSelected = this.assurances.find(
        (assu) => assu.LIST_TarifCategorie.ID_TypeAssurance == assurance
      );
      if (
        assuSelected
      ) {
        this.assurancesOptionsSelected = [];
        this.assurancesCompSelected = null;
      }
    },
    setLicenceList() {
      // let userAge = this.$moment().diff(this.user.DN_DateNaissance, "years");
      const birth = this.$moment(this.user?.DN_DateNaissance).format(
        "DD/MM/YYYY"
      );
      // const finDate = `31/08/${this.currentSaison.id - 18}`;
      const dateNewSaison = `01/09/${this.currentSaison.id - 18}`;

      const realAge = this.$moment(dateNewSaison, "DD/MM/YYYY").diff(
        this.$moment(birth, "DD/MM/YYYY"),
        "years"
      );
      this.licences =
        this.$moment(birth, "DD/MM/YYYY").toDate() >=
        this.$moment(dateNewSaison, "DD/MM/YYYY").toDate()
          ? this.licencesType.filter(
              (licence) =>
                licence.NomTarif === "Licence jeune" ||
                licence.NomTarif === "Licence famille"
            )
          : this.licencesType.filter(
              (licence) =>
                licence.NomTarif === "Licence adulte" ||
                licence.NomTarif === "Licence famille"
            );
    },
    async setData() {
      let tarifs = await this.getTarifsFFME({
        ID_Saison: this.currentSaison.id,
      });
      console.log("tarifs looool :>> ", tarifs);
      this.licencesType = tarifs.filter(
        (tar) => tar.LIST_TarifCategorie.ID_TypeLicence
      );
      this.setLicenceList();
      this.assurances = tarifs.filter(
        (tar) => tar.LIST_TarifCategorie.ID_TypeAssurance
      );
      console.log("this.assurances reeeeeellll :>> ", this.assurances);

      return;
    },
    calculateAge(dateNaissance) {
      // let dateNaissance = this.$moment(this.currentUser.DN_DateNaissance).format("DD/MM/YYYY");
      const userAge = this.$moment().diff(dateNaissance, "years");
      if (userAge < 18) dateNaissance = true;
      dateNaissance = false;
    },
    async addCMItem(item) {
      this.questionnaire = item;
    },
    onChangeRadio(questionnaire) {
      this.questionnaire = questionnaire;
    },
  },

  watch: {
    user() {
      this.user?.DN_DateNaissance && this.setLicenceList();
    },
    isResponsabiliteCivile: {
      handler() {
        if (!this.isResponsabiliteCivile) return;
        this.assurancesCompSelected = null;
        return (this.assurancesOptionsSelected = []);
      },
    },
  },

  props: {
    user: {
      type: Object,
    },
  },
};
</script>

<style scoped>
label {
  margin-bottom: 0 !important;
  font-size: 0.8rem;
}
.assuranceIJ .v-input--selection-controls .v-radio > .v-label {
  font-size: 1rem !important;
}
</style>
