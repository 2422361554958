<template>
  <v-row align-content="center">
    <v-col cols="12" md="3" v-if="label" class="field__label-column">
      <label for="">{{ label }}{{ isRequired ? "*" : "" }}</label>
    </v-col>

    <v-col
      v-if="mounted"
      cols="12"
      md="9"
      style="flex: 1;"
      class="field__value-column input__telephone"
    >
      <VuePhoneNumberInput
        :class="isValid || !currentValue || !currentValue.length ? '' : '__not-valid'"
        v-model="currentValue"
        :default-country-code="currentCountryCode"
        size="sm"
        :preferred-countries="['FR', 'BE', 'DE']"
        :translations="{
          countrySelectorLabel: 'Code pays',
          countrySelectorError: 'Choisir un pays',
          phoneNumberLabel: 'Exemple', // 'Numéro de téléphone',
          example: 'Exemple :',
        }"
        :required="isRequired"
        :no-example="false"
        v-on:update="numberUpdated"
        valid-color="#dfe5f9"
        ref="phone2"
      />
    </v-col>
  </v-row>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { sleep } from "@/plugins/utils";
export default {
  name: "inputTelephone",

  components: {
    VuePhoneNumberInput,
  },

  data: () => ({
    currentValue: "",
    isRequired: false,
    currentCountryCode: "FR",
    isValid: true,
    internalInput: false,
    mounted: false,
  }),

  async created() {
    this.isRequired = this.required;
    this.mounted = true;
    await sleep(10);
    this.initValue(this.value);
  },

  computed: {},

  watch: {
    required(data) {
      this.isRequired = data;
    },
    // currentValue (data) {
    //    // this.$emit('input', data)
    //    // console.log('watch:currentValue', data);
    // },

    value(data) {
      // console.log('it:value', this.currentValue, data);
      this.initValue(data);
    },
  },

  methods: {
    numberUpdated(data) {
      // console.log('numberUpdated', data, this.value);
      this.isValid = data.isValid;
      // saving the combination of countrycallingcode and national code so that the correct spacing will be applied
      let customFormat =
          "+" + data.countryCallingCode + " " + data.formatNational;
      if (data.countryCallingCode && data.formatNational) {
        this.$emit("input", customFormat);
      } else {
        this.$emit("input", '');
      }
      // signal that this was an internal input
      if (this.value !== customFormat) {
        this.internalInput = true;
      }
    },

    /**
     * initializes the data if necessary. had to be put in a method because we need to call it also in the
     * mounted method because of some unexpected behaviour when used in the personne list and the code was
     * just in the watcher
     * @param data
     */
    initValue(data) {
      // not empty, or external value
      if (
        (data !== null &&
          data.length &&
          this.$refs.phone_number &&
          this.currentValue === "") ||
        !this.internalInput
      ) {
        // resetting to empty
        if (data === null) {
          this.currentValue = "";
        }

        // check if the inserted number is an international one, and if so separate it from the number and
        // set both of them separately
        else if (data[0] === "+") {
          let pos = data.indexOf(" ");

          let prefix = data.substr(0, pos);
          let number = data.substr(pos + 1);

          let country_dial_code = prefix.replace("+", "");
          //console.log(country_dial_code)
          let country_data = this.$refs.phone2.codesCountries.find(
            (ele) => ele.dialCode === country_dial_code
          );

          if (country_data) {
            this.currentCountryCode = country_data.iso2;
          }

          this.currentValue = number;

          // if there is no country prefix, just set the whole normal number
        } else {
          this.currentValue = data;
        }
      }
      this.internalInput = false;
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.vue-phone-number-input {
  max-width: 400px;
}
.input__telephone {
  .input-tel__input,
  .country-selector__input {
    border-radius: 0 !important;
    border: 1px solid #dfe5f9;
    padding: 4px 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }

  // no label text
  .country-selector .country-selector__country-flag {
    top: 8px !important;
  }
  .country-selector__input,
  .input-tel.has-value .input-tel__input {
    padding-top: 4px !important;
    font-weight: 300 !important;
  }
  .input-tel.has-value .input-tel__input {
    font-size: 0.8rem;
    padding-left: 7px;
  }
  .country-selector__label {
    display: none;
  }
  .input-tel__label {
    display: block;
    top: -15px;
    left: 8px;
    font-weight: 400;
    color: orange !important;
    font-size: 11px;
    opacity: 0 !important;
  }
  .country-selector__toggle {
    top: 3px !important;
  }
  .vue-phone-number-input .select-country-container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 110px;
    flex: 0 0 96px;
    width: 110px;
    min-width: 96px;
    max-width: 96px;
  }
  // height
  .country-selector.sm .country-selector__input,
  .input-tel.sm .input-tel__input,
  .country-selector.sm,
  .vue-phone-number-input {
    height: 30px;
    min-height: 30px;
  }

  .input-tel__input {
    box-shadow: none !important;
  }

  .__not-valid {
    .input-tel__input {
      border: 1px solid #ffc838 !important;
    }
    .input-tel__label {
      opacity: 1 !important;
    }
  }
}
</style>
