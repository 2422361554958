<template>
  <v-row>
    <v-col cols="12" md="12" lg="12" xl="12">
      <div class="text-center my-4" style="position: relative;">
        <!-- <InputSelect
          :items="memberTypes"
          class="select-primo"
          v-model="selectedTypes"
        /> -->
        <span class="title">
          S’AFFILIER À LA FFME
        </span>
      </div>

      <v-stepper class="step-MA" alt-labels v-model="step">
        <v-stepper-header>
          <v-stepper-step
            complete-icon="mdi-numeric-1"
            :complete="step > 1"
            step="1"
            >Informations générales</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step
            complete-icon="mdi-numeric-2"
            :complete="step > 2"
            step="2"
            >Contact</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step
            complete-icon="mdi-numeric-3"
            :complete="step > 3"
            step="3"
            >Documents à joindre</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step
            complete-icon="mdi-numeric-4"
            :complete="step > 4"
            step="4"
            >Synthèse et validation</v-stepper-step
          >
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <step1 @onNext="step += 1" />
          </v-stepper-content>

          <v-stepper-content step="2">
            <step2 @onNext="step += 1" @onPrevious="step -= 1" />
          </v-stepper-content>

          <v-stepper-content step="3">
            <step3 @onNext="step += 1" @onPrevious="step -= 1" />
          </v-stepper-content>

          <v-stepper-content step="4">
            <step4
              v-if="step === 4"
              @onNext="submit()"
              @onPrevious="step -= 1"
            />
          </v-stepper-content>

          <v-stepper-content step="5">
            <step5 />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
    <v-overlay :value="false">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import step1 from './step1.vue';
import step2 from './step2.vue';
import step3 from './step3.vue';
import step4 from './step4.vue';
import step5 from './step5.vue';
// import InputSelect from "@/components/utils/select.vue";
import { mapActions, mapState } from 'vuex';
import {
  success_notification,
  error_notification,
} from '@/plugins/notifications.js';
import { getTransactionAffiliationMABody, insertTransaction } from '@/plugins/transactionService';
import {
  PRODUCT_AFFILIATION_MA,
  OPTION_AFFILIATION_0,
  getProducts,
  getOptions,
  getPrices
} from "@/plugins/productService";

export default {
  name: 'PrimoAffiliationMAInscription',
  components: { step1, step2, step3, step4, step5 },
  data() {
    return {
      step: 1,
      memberTypes: ['Association', 'Etablissement affilié', 'Membre associé'],
      selectedTypes: 'Membre associé',
    };
  },
  watch: {
    selectedTypes() {
      if (this.selectedTypes !== 'Membre associé') {
        return this.$router.push({ name: 'primo_affiliation__inscription' });
      }
    },
  },
  computed: {
    ...mapState({
      MAaffiliation: (state) => state.structure.MAaffiliation,
    }),
  },
  methods: {
    ...mapActions('structure', [
      'saveTempAffiliation',
      'getTempAffiliation',
      'createNewStructure',
      'insertPrimoAffiliation',
      'addUserToStructureOnPrimoAffi',
    ]),
    ...mapActions('utilisateurs', [
      'createAuthUser',
      'createFluxFinancierDetail',
      'createLemonwayPaiement',
    ]),
    ...mapActions("basics", [
      "getCurrentAffiliationTarif",
    ]),
    async submit() {
      let payload = {
        // structure
        STR_NomStructure: this.MAaffiliation.structure.name,
        STR_InfoPresentation: this.MAaffiliation.structure.textIntroduction,
        JUR_Siret: this.MAaffiliation.structure.siretNumber,
        ID_TypeStructure: 6, // ID membre associe structure
        ID_Premiere_Saison: this.MAaffiliation.structure.seasonValid,
        NomStructureComplement: this.MAaffiliation.structure.acronyme,
        ChiffreAffaire: +this.MAaffiliation.structure.CA,
        // representant
        UTI_Utilisateur: JSON.stringify(this.MAaffiliation.contact),
        ADR_Adresse: JSON.stringify({
          adress: this.MAaffiliation.contact.representantAdress,
          country: this.MAaffiliation.contact.representantCountry,
          CodePostal: this.MAaffiliation.contact.representantCP,
          adressSupp: this.MAaffiliation.contact.representantAdressSupp,
        }),
        Z_DateCreationDemande: this.$moment().toISOString(),
      };
      //submission affiliation temp
      try {
        const reponseTempAffiliation = await this.saveTempAffiliation({
          affiliation: payload,
        });

        const ID_TempAffiliation =
          reponseTempAffiliation.insert_STR_AffiliationTemp.returning[0].id;

        await this.setPayment(ID_TempAffiliation);

      } catch (error) {
        error_notification("Une erreur est survenue lors de la création de la transaction");
      }

      success_notification("Demande d'affiliation engagée avec succès");
    },

    async setPayment(ID_TempAffiliation) {
      try {
        let products = await getProducts({
          'slug': PRODUCT_AFFILIATION_MA,
        });

        let productId = products[0].id;

        let prices = await getPrices({
          'seasonId': this.MAaffiliation.structure.seasonValid,
          'structureId': 1318,
          'productId': PRODUCT_AFFILIATION_MA,
          'options': OPTION_AFFILIATION_0
        });

        this.tarifStructure = prices.reduce((carry, price) => carry + price.value, 0);

        const commissionLemonway = Math.ceil((this.tarifStructure * 0.0072) + 16);

        await insertTransaction(
          getTransactionAffiliationMABody(
            this.tarifStructure + commissionLemonway,
            productId,
            undefined,
            this.MAaffiliation.structure.seasonValid,
            ID_TempAffiliation,
            this.MAaffiliation.structure.name,
          )
        );
      } catch (error) {
        throw new Error(error);
      }

      this.step += 1;

      return { ok: true };
    },
  },
};
</script>

<style lang="scss" scoped>
.select-primo {
  position: absolute;
  left: 0px;
  top: -10px;
}
.v-stepper__label {
  text-align: center !important;
  font-size: 0.9rem !important;
}
.title {
  height: 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 32px !important;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #000000;
}
.logo__ffme {
  height: 100%;
  margin-top: 0px;
  background: url('~@/assets/images/FFME_LOGO_INSTIT_PRINCIPAL_FOND_CLAIR_RVB.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;
  width: 100%;
}

::v-deep .step-MA .v-stepper__step__step {
  background: #082b6e !important;
}
::v-deep .v-stepper__step--complete {
  .v-stepper__label {
    color: #88aef6 !important;
  }
  .v-stepper__step__step {
    background: #88aef6 !important;
  }
}
::v-deep .v-stepper__label {
  font-family: Roboto !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px;

  text-align: center;
  color: #000000 !important;
}
::v-deep .v-stepper__step--inactive {
  .v-stepper__label {
    color: #bac4d7 !important;
  }
  .v-stepper__step__step {
    background: #bac4d7 !important;
  }
}
</style>
<style>
/* maquettes  */
/* maquettes */
.title-stepper {
  width: 424px;
  height: 40px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  margin: 10px 0px;
  color: #020918;
}
.text-subtitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300 !important;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #020918;
}
.text-normal {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #020918;
}
.text-original {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  line-height: 24px;
}
.text2 {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 16px;
  color: #bac4d7;
}
.text3 {
  color: #000000;
}
.text4 {
  font-size: 1rem;
}
.text5 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #768aae;
}
.btn-font {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px;
}
</style>
