<template>
     <form id="payment-form">
        <!-- <h6 class="pb-2">Paiment par carte de crédit ou débit</h6> -->
        <div id="card-element"><!--Stripe.js injects the Card Element--></div>
         <p id="card-error" role="alert"></p>
        
        <v-btn v-if="mounted" :loading="loading" type="submit" class="btn btn-primary mt-2">Payer {{this.paymentInformation.amount/100}} €</v-btn>
        <v-progress-circular v-else  indeterminate color="blue-dark" :value="40"></v-progress-circular>

        

    </form>

</template>


<script>
import { loadStripe } from "@stripe/stripe-js"
import { error_notification} from "@/plugins/notifications"
import { mapGetters } from "vuex"

export default {
    data: () => ({
    loading: false,
    publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, 
    stripe: null,
    mounted: false,
    user: null
  }),

  async created() {
      if(this.currentUser) {
          this.user = this.currentUser
      }else {
          this.user = this.propsUser
      }
      this.stripe = await loadStripe(this.publishableKey)
      await this.fetchPaymentIntent()
  },

  computed: {
      ...mapGetters('user', ['currentUser']),

      style() {
        return {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };
      }
  },
  
  methods: {

      async fetchPaymentIntent() {

        // console.log(this.paymentInformation)

        const fetch = require('node-fetch');
        
        ( async () => {

                let payload = {
                    intent: {
                        ...this.paymentInformation,
                        currency: "eur",
                        payment_method_types: ["card"]
                    }
                }
                const response = await fetch(process.env.VUE_APP_API_URL+'stripe', {
                    method: 'post',
                    body: JSON.stringify(payload),
                    headers: {'Content-Type': 'application/json'}
                });

                const json = await response.json();
                if(response.status == 200) {
                    this.prepareCard()
                    // console.log(json.intent.client_secret)
                    // console.log(payload.intent.amount)
                    var form = document.getElementById("payment-form");
                    const self = this
                    form.addEventListener("submit", function(event) {
                        event.preventDefault();
                        self.loading= true
                        // Complete payment when the submit button is clicked
                        self.payWithCard(self.stripe, self.card, json.intent.client_secret);
                    });
                }else{
                    error_notification()
                    // console.log(json.message)
                }
                //console.log(json);
        })();
      },

      prepareCard() {
        this.elements = this.stripe.elements()
        this.card = this.elements.create("card", { style: this.style });
        //this.confirmCardPayment(json.intent)
        this.card.mount("#card-element");
        this.mounted = true
        this.card.on("change", function (event) {
            // Disable the Pay button if there are no card details in the Element
            document.querySelector("button").disabled = event.empty;
            document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
        });
      },

      payWithCard(stripe, card, clientSecret) {
        //loading(true);
        let self = this
        self.stripe
            .confirmCardPayment(clientSecret, {
                payment_method: {
                    card: card,
                    billing_details: {
                        name: self.user.CT_Nom + " " + self.user.CT_prenom,
                        email:  self.user.CT_Email,
                        phone: self.user.CT_TelephoneFixe
                    },
                },
                receipt_email: self.user.CT_Email
            })
            .then(function(result) {
            if (result.error) {
                // console.log('error', result.error)
                error_notification(result.error.message)
                self.loading = false
                // Show error to your customer
                //showError(result.error.message);
            } else {
                // The payment succeeded!
                //orderComplete(result.paymentIntent.id);
                // console.log(result)
                // console.log("success", result.paymentIntent.id)
                self.$emit("onSuccess", {ID_PI_Stripe: result.paymentIntent.id})
                
            }
            });
      }
  },

  props: {

      propsUser: {
          type: Object
      },
    
      paymentInformation: {

      }
  }
}
</script>


<style scoped>


.StripeElement {
  box-sizing: border-box;

  height: 34px;

  padding: 6px 12px;

  border: 1px solid #dfe5f9;
  /* border-radius: 4px; */
  background-color: white;

  /* box-shadow: 0 1px 3px 0 #e6ebf1; */
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid, #card-error {
  border-color: #fa755a;
  color: #fa755a; 
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>