<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="!mounted"> Chargement...</v-col>
      <v-col v-else-if="mounted && !success" cols="12">
        <h2 class="text-center">Payer une licence hors club</h2>

        <v-row>
          <v-col cols="12"><h5>Licences & Assurances</h5></v-col>
          <v-col cols="12" md="12">
            <div v-if="user.LicenceNumero">
              <span class="font-weight-bold">Numéro de licence:</span>
              {{ user.LicenceNumero }}
            </div>
            <div v-if="licenceType && licenceType.LIST_LicenceType">
              <span class="font-weight-bold">Type de licence</span>:
              {{ licenceType.LIST_LicenceType.NomTypeLicence }} ({{
                licenceType.MontantUnitaire
              }}
              €)
            </div>
            <div
              v-if="assuranceSelected && assuranceSelected.ASSU_AssuranceType"
            >
              <span class="font-weight-bold">Assurance</span>:
              {{ assuranceSelected.ASSU_AssuranceType.NomTypeAssurance }} ({{
                assuranceSelected.MontantUnitaire
              }}€)
            </div>
            <div v-else>
              <span class="font-weight-bold">Assurance</span>: Assurance civile
            </div>
            <div v-if="optionAssurance && optionAssurance.length">
              <span class="font-weight-bold">Option d'assurances</span>:
              <optionAssuranceChips
                v-for="(opt, index) in optionAssurance"
                :key="index"
                :assurance_id="opt"
              />
            </div>

            <div class="py-4" style="max-width: 450px;">
              <stripeInput
                :paymentInformation="getPaymentInfo"
                ref="stripeInput"
                @onSuccess="submitAfterPayment"
                :propsUser="user"
                :key="stripeKey"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-else-if="mounted & success" class="text-center">
        Votre paiement a bien été pris en compte. Merci de suivre les
        indications reçu dans le mail envoyé à {{ user.CT_Email }} pour valider
        votre compte et vous connecter sur votre espace app.myffme.fr.
      </v-col>
    </v-row>
    <PrintFacture ref="facture" :print="print"></PrintFacture>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import optionAssuranceChips from "@/views/LicenceHorsClub/assuranceChips.vue";
import stripeInput from "@/components/utils/stripeInput";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import { send_mail } from "@/plugins/utils";
import PrintFacture from "@/components/Structure/Finance/PrintFacture.vue";

export default {
  components: { optionAssuranceChips, stripeInput, PrintFacture },

  data: () => ({
    user: {},
    userLicence: {},
    licence: {},
    assurances: {},
    tarifs: null,
    mounted: false,
    success: false,
    stripeKey: 0,
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
  }),

  async created() {
    let regexUUID = new RegExp(
      "^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i"
    );
    const uuidV4Regex = /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i;
    let regexToken = new RegExp("^[a-z0-9]*$");

    if (
      !uuidV4Regex.test(this.$route.params.user_id) ||
      !regexToken.test(this.$route.params.token)
    ) {
      console.log("hekllko");
      window.location = "/";
      return;
    }

    let user = await this.getUserTempWithToken({
      ID_Utilisateur: this.$route.params.user_id,
      PaymentToken: this.$route.params.token,
    });

    user = user.data.UTI_UtilisateursTemp;
    console.log("heeeeeeere", user);
    if (!user.length) {
      window.location = "/";
      return;
      //return this.$router.push({ name: "Home" });
    }

    this.user = user[0];
    // if(this.user.LicenceNumero) {
    //     let userLicence = await this.getUserByLicenceNumero({LicenceNumero: this.user.LicenceNumero})
    //     if(userLicence.data.UTI_Utilisateurs[0]) {
    //         this.userLicence = userLicence.data.UTI_Utilisateurs[0]
    //         console.log("user by licence", this.userLicence)
    //     }
    // }
    console.log(this.user);
    await this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("saisons", ["currentSaison"]),

    totalAmountToPay() {
      console.log("licence", this.licenceType.MontantUnitaire);
      console.log("assurance", this.assuranceSelected.MontantUnitaire);
      let total = this.licenceType.MontantUnitaire;
      total += this.assuranceSelected.MontantUnitaire;
      this.optionAssurance.forEach((assu) => {
        let tarif = this.tarifs.find((tarif) => tarif.ID_TypeAssurance == assu);
        total += tarif.MontantUnitaire;
        console.log(assu, tarif.MontantUnitaire);
      });
      console.log(total);
      return total;
    },

    getPaymentInfo() {
      let description =
        "Paiment licence hors club " +
        this.user.CT_Nom +
        " " +
        this.user.CT_Prenom;

      return {
        amount: this.totalAmountToPay * 100, // the amount you want to charge the customer in cents. $100 is 1000 (it is strongly recommended you use a product id and quantity and get calculate this on the backend to avoid people manipulating the cost)
        description: description, // optional description that will show up on stripe when looking at payments,
        metadata: {
          ID_Utilisateur: this.user.ID_Utilisateur,
        },
      };
    },

    licenceType() {
      if (!this.tarifs) return;
      return this.tarifs.find(
        (tar) =>
          tar.ID_TypeLicence == this.licence.ID_TypeLicence.ID_TypeLicence
      );
    },

    assuranceSelected() {
      if (!this.assurances) return;
      return this.assurances.find((tar) => {
        if (tar && tar.ASSU_AssuranceType) {
          return !tar.ASSU_AssuranceType.EST_Option;
        }
      });
    },

    optionAssurance() {
      if (!this.assurances) return;
      let assu = this.assurances
        .filter((tar) => {
          if (tar && tar.ASSU_AssuranceType) {
            return tar.ASSU_AssuranceType.EST_Option;
          }
        })
        .map((assu) => assu.ID_TypeAssurance);
      return assu;
    },
  },

  methods: {
    ...mapActions("utilisateurs", [
      "getUserTempWithToken",
      "getUserByLicenceNumero",
      "createFluxFinancierDetail",
      "saveTempUser",
    ]),
    ...mapActions("basics", ["getListPays", "getListTypeDocument"]),
    ...mapActions("tarifs", ["getTarifsLicence_Assurances"]),
    ...mapActions("structure", ["updateLicenceUtilisateurStructureList"]),

    async submitAfterPayment(payment) {
      try {
        //insert licences assurances payload
        let licenceAssurancesPayload = await this.addLicenceUserHorsClub(
          payment
        );
        const response = await this.updateLicenceUtilisateurStructureList({
          //ID_Structure: this.users[0].ID_Structure,
          items: [licenceAssurancesPayload],
          rmID_UTI_LicenceAssurance: [],
          estLicencie: true,
        });
        console.log("response", response);
      } catch (e) {
        error_notification(
          "Une erreur est survenue, merci de réessayer ulterieurement."
        );
        console.log("error licence", e);
        return false;
      }

      // create flux financier
      try {
        let flux = await this.createFluxFinancier(payment);
        const insertedFlux = await this.createFluxFinancierDetail({
          flux: flux,
        });
        this.printFacture(insertedFlux);
      } catch (e) {
        error_notification(
          "Une erreur est survenue, merci de réessayer ulterieurement."
        );
        console.log("error flux", e);
        return;
      }

      // On update l'utilisateur final dans UTI_UtilisateurTemp
      try {
        let payloadUserTemp = {
          id: this.user.id,
          Status: 9,
          PaymentToken: null,
          Z_DatePayment: this.$moment().toISOString(),
          ID_PI_Stripe: payment.ID_PI_Stripe,
        };
        await this.saveTempUser({ user: payloadUserTemp });
      } catch (e) {
        console.log(e);
        error_notification(
          "Une erreur est survenue, merci de réessayer ulterieurement."
        );
      }

      //
      try {
        let payloadEmail = {
          // Context:
          // User: this.user.id
        };
        //let response = await send_mail(payloadEmail, true)
      } catch (e) {
        console.log(e);
      }

      success_notification("Paiement validé.");
      this.success = true;
    },

    createFluxFinancier(payment) {
      let detailsTarif = [];
      let total = 0;

      //on push le detail de la licence
      detailsTarif.push({
        ID_Utilisateur: this.user.ID_Utilisateur,
        ID_Saison: this.currentSaison.id,
        ID_Tarif: this.licenceType.id,
        MontantHT: this.licenceType.MontantUnitaire,
        MontantTTC: this.licenceType.MontantUnitaire,
        MontantTVA: 1,
        FIN_CodeAnalytique: 0,
        FIN_CodeCompta: 0,
        NomFluxFinancierDetail: `${this.licenceType.NomTarif}`,
      });
      total += this.licenceType.MontantUnitaire;

      // on push le detail de l'assurane
      detailsTarif.push({
        ID_Utilisateur: this.user.ID_Utilisateur,
        ID_Saison: this.currentSaison.id,
        ID_Tarif: this.assuranceSelected.id,
        MontantHT: this.assuranceSelected.MontantUnitaire,
        MontantTTC: this.assuranceSelected.MontantUnitaire,
        MontantTVA: 1,
        FIN_CodeAnalytique: 0,
        FIN_CodeCompta: 0,
        NomFluxFinancierDetail: `${this.assuranceSelected.NomTarif}`,
      });
      total += this.assuranceSelected.MontantUnitaire;

      this.optionAssurance.forEach((assu) => {
        let tarif = this.tarifs.find((tarif) => tarif.ID_TypeAssurance == assu);

        detailsTarif.push({
          ID_Utilisateur: this.user.ID_Utilisateur,
          ID_Saison: this.currentSaison.id,
          ID_Tarif: tarif.id,
          MontantHT: tarif.MontantUnitaire,
          MontantTTC: tarif.MontantUnitaire,
          MontantTVA: 1,
          FIN_CodeAnalytique: 0,
          FIN_CodeCompta: 0,
          NomFluxFinancierDetail: `${tarif.NomTarif}`,
        });

        total += tarif.MontantUnitaire;
      });

      let payload = {
        ID_FluxFinancierDebit: 0,
        ID_Saison: this.currentSaison.id,
        //ID_Structure: user.ID_Structure,
        ID_Traitement: 1,
        ID_Utilisateur: this.user.ID_Utilisateur,
        MontantHT: total,
        MontantTTC: total,
        MontantTVA: 0,
        NomFluxFinancier:
          "Licence hors club " +
          this.user.CT_Nom +
          " " +
          this.user.CT_Prenom +
          " - " +
          this.currentSaison.id,
        EST_Reglement: true,
        ID_PI_Stripe: payment.ID_PI_Stripe,
        MKP_FluxFinancierDetail: {
          data: detailsTarif,
          on_conflict: {
            constraint: "MKP_FluxFinancierDetail_pkey",
            update_columns: ["ID_Tarif"],
          },
        },
      };

      return payload;
    },

    addLicenceUserHorsClub() {
      let assurances = this.assurances.map((assu) => {
        let tarif = this.tarifs.find(
          (tar) => tar.ID_TypeAssurance == assu.ID_TypeAssurance
        );
        let item = {
          ID_Saison: this.currentSaison.id,
          ID_Utilisateur: this.user.ID_Utilisateur,
          ID_Tarif: tarif.id,
          ID_TypeAssurance: assu.ID_TypeAssurance,
        };
        return item;
      });

      let variables = {
        ID_Saison: this.currentSaison.id,
        ID_TypeLicence: this.licence.ID_TypeLicence.ID_TypeLicence,
        ID_Utilisateur: this.user.ID_Utilisateur,
        ID_Tarif: this.licenceType.id,
        ID_EtapeLicence: 1,
        UTI_LicenceAssurance: {
          data: assurances,
          on_conflict: {
            constraint: "UTI_LicenceAssurance_pkey",
            update_columns: [
              "DateSouscriptionAssurance",
              "ID_Tarif",
              "ID_Saison",
            ],
          },
        },
      };
      return variables;
    },

    async setData() {
      await this.getListPays();
      this.adresse = JSON.parse(this.user.ADR_Adresse);
      this.licence = JSON.parse(this.user.UTI_Licence);
      //this.document = this.user.DOC_DocumentsTemp[0]
      this.tarifs = await this.getTarifsLicence_Assurances({
        ID_Saison: this.currentSaison.id,
      });
      console.log(this.tarifs);
      this.assurances = JSON.parse(this.user.UTI_LicenceAssurances)
        .map((assu) =>
          this.tarifs.find((tarif) => {
            if (assu) {
              return tarif.ID_TypeAssurance == assu.toString();
            }
          })
        )
        .filter((assu) => assu);
    },

    printFacture(item) {
      this.$refs.facture.printFacture(item);
    },
  },
};
</script>
