<template>
  <div style="display:flex; flex-direction:column;">
    <p>
      Précisez les informations générales relatives au voyage sportif à
      l'étranger (hors UE élargie, Monaco, Andorre, Suisse et du Royaume-uni)
      pour lequel vous souhaitez une extension de garanties de l'assurance FFME
    </p>
    <v-row>
      <v-col cols="12" lg="6">
        <v-row>
          <v-col cols="12" lg="6" xl="6">
            <span class="font-weight-bold"> Destination*</span>
            <countrySelectVoyage
              v-model="voyage.ID_Pays"
              rules="required|min:0|max:50"
              key="countrynationalite"
              placeholder="Sélectionnez un pays de destination"
              class="country__voyage"
          /></v-col>
        </v-row>

        <v-row>
          <v-col cols="6" lg="6" xl="6">
            <span class="font-weight-bold"> Date de départ*</span>
            <inputText
              v-model="voyage.DateDepart"
              rules="required|trialMinDate"
              type="date"
              :minDate="$moment(new Date()).format('YYYY-MM-DD')"
            ></inputText>
          </v-col>
          <v-col cols="6" lg="6" xl="6">
            <span class="font-weight-bold"> Date de retour*</span>
            <inputText
              v-model="voyage.DateRetour"
              :rules="{ required: true }"
              type="date"
              :minDate="$moment(new Date()).format('YYYY-MM-DD')"
            ></inputText
          ></v-col>
        </v-row>
        <!-- <div>
          <div>
            <span class="font-weight-bold" style="width:50%">
              Information annulation</span
            >
            <inputText
              v-model="voyage.DateAnnulation"
              rules="required"
              type="date"
              :maxToday="true"
              style="width:50%"
            ></inputText>
          </div>
          <v-textarea
            v-model="voyage.MotifAnnulation"
            name="input-7-1"
            placeholder="Information de l'annulation"
          ></v-textarea>
        </div> -->
      </v-col>
      <v-col cols="12" lg="6">
        <v-row style="width:100%">
          <v-col
            cols="12"
            lg="6"
            class="d-flex"
            style="align-content:center;justify-content:start"
          >
            <span class="font-weight-bold">
              Activité(s) sportive(s) pratiquée(s) lors du voyage* :</span
            ></v-col
          >
          <v-col cols="12" lg="6"
            ><InputSelect
              class="force-w-100 activities_voyage"
              v-model="activites"
              rules="required"
              :items="allActivities"
              :multiple="true"
              item_value="id"
              item_text="NomActivite"
              placeholder="Sélectionnez une ou plusieurs activités"
            ></InputSelect>
          </v-col>

          <v-col
            cols="12"
            class="d-flex"
            style="align-content:center;justify-content:start"
          >
          <span for="" class="font-weight-bold"> Descriptif* : </span>
          <span style="margin-left: 5px;">Présentation succincte des objectifs du voyage</span>
          </v-col>

        </v-row>
        <v-row cols="12">
          <v-col
            cols="12"
            class="d-flex"
            style="align-content:center;justify-content:center"
          >
            <v-textarea
              v-model="voyage.Description"
              name="input-7-1"
              placeholder="Description des objectifs de votre voyage"
            ></v-textarea>
            </v-col>
          </v-row>

        <v-list dense v-if="selectedActivities.length">
          <v-list-item-group multiple>
            <v-list-item
              v-for="(item, index) in selectedActivities"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.NomActivite"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
    <v-row> </v-row>
    <div
      style="display:flex; flex-direction:row; margin-top:24px; justify-content:space-between"
    >
      <v-btn
        type="submit"
        class="btn btn-primary"
        @click.prevent="$emit('on-previous')"
        >Précédent</v-btn
      >
      <v-btn
        type="submit"
        class="btn btn-primary"
        @click.prevent="goNext()"
        :disabled="isDisabled"
        >Suivant</v-btn
      >
    </div>
  </div>
</template>

<script>
import InputText from "@/components/utils/input";
import InputSelect from "@/components/utils/selectWithInput.vue";
import { mapActions, mapGetters } from "vuex";
import countrySelectVoyage from "../../../../components/utils/select__countries__voyage";
export default {
  name: "Step2",
  components: { InputText, InputSelect, countrySelectVoyage },
  data: () => ({
    paysDestination: [
      { Nom: "Madagascar", id: 1 },
      { Nom: "France", id: 2 },
      { Nom: "Andorre", id: 3 },
    ],
    voyage: {
      ID_Pays: null,

      DateDepart: "",
      DateRetour: "",
      Description: "",
      // MotifAnnulation: "",
      // DateAnnulation: "",
    },
    allActivities: [],
    activites: [],
  }),
  computed: {
    ...mapGetters("saisons", ["currentSaison"]),

    selectedActivities() {
      if (!this.activites.length) return [];
      const activities = this.activites.map((activite) =>
        this.allActivities.find((act) => act.id === activite.id)
      );
      return activities;
    },
    isDisabled() {
      if (
        this.voyage.ID_Pays === null ||
        this.voyage.DateDepart === "" ||
        this.voyage.DateRetour === "" ||
        this.voyage.Description === "" ||
        this.activites.length === 0
      )
        return true;
      return false;
    },
  },
  async created() {
    this.allActivities = await this.getActiviteDelegation();
  },
  methods: {
    ...mapActions("structure", ["getActiviteDelegation"]),
    goNext() {
      this.$emit("set-data", {
        ...this.voyage,
        DateDepart: this.$moment(this.voyage.DateDepart).toISOString(),
        // DateAnnulation: this.$moment(this.voyage.DateAnnulation).toISOString(),
        DateRetour: this.$moment(this.voyage.DateRetour).toISOString(),
        Activites: JSON.stringify([...this.activites]),
      });
      this.$emit("on-next");
    },
  },
};
</script>

<style lang="scss" scoped>
.country__voyage .v-input__control > .v-input__slot {
  width: 48% !important;
}

.activities_voyage >>> .v-select__slot {
  overflow: hidden;
}
</style>
