<template>
  <div>
      <v-card-text>
        <div
            class="d-flex flex-column pt-4 card-content"
            style="padding: 0 34px;"
        >
          <div class="d-flex align-center justify-space-between">
            <div
                style="width: 49%;"
                class="d-flex align-center justify-space-between"
            >
              <span
                  v-text="'Certificat medical'"
                  class="text-label"
                  style="width: 35%;"
              />
              <v-file-input
                  v-model="fileInput"
                  :required="!disableRequiredFields"
                  :rules="[(v) => !!v || 'Veuillez selectionner un fichier', (v) => v && v.size < (5 * 1024 * 1024) || 'Le fichier doit être inférieur à 5Mo']"
                  dense
                  prepend-icon=""
                  class="file-input item-without-msg"
                  outlined
                  show-size
                  style="font-size: 12px !important; width: 65%;"
                  @change="onFileChange"
                  ref="file"
                  accept="image/jpeg, image/png, application/pdf"
              >
                <template #label>
                  <v-icon small color="#bac4d7"> mdi-paperclip </v-icon>
                  <span
                      v-text="'Déposer un fichier'"
                      class="text-span text-span1 text-span3"
                  />
                </template>
              </v-file-input>
            </div>
            <div style="width: 49%;" class="d-flex align-center">
              <span
                  v-text="'Type de certificat'"
                  class="text-span text-label"
                  style="width: 35%;"
              />
              <InputSelect
                  :items="certificatsMedicauxTypes.filter(cm => this.isLicenceHC ? cm.isLicenceHC === this.isLicenceHC : true)"
                  key="slug"
                  item_text="name"
                  item_value="slug"
                  v-model="cmSelected"
                  @input="cmChanged"
                  style="width: 65%;"
                  class="item-without-msg"
              />
            </div>
          </div>
          <div style="width: 49%;">
            <span
                class="text-span2"
                style="width: 35%;"
                v-text="'Format de fichiers acceptés : JPG, JPEG, PNG, PDF'"
            />
            <br />
            <span
                class="text-span2"
                style="width: 35%;"
                v-text="'Taille maximale du fichier : 5Mo'"
            />
          </div>
          <div class="d-flex align-center justify-space-between">
            <div style="width: 49%;" class="d-flex align-center">
              <span class="text-label" style="width: 35%;">
                1<sup>ère</sup> saison de validité
              </span>
              <InputSelect
                  :items="saisonValide"
                  v-model="selectedSaison"
                  @input="seasonChanged"
                  style="width: 65%;"
                  class="item-without-msg"
              />
            </div>
            <div style="width: 49%;" class="d-flex align-center">
              <span
                  v-text="`Fin de validité :`"
                  class="text-span text-span1"
                  style="width: 35%;"
              />
              <span
                  v-if="cmSelected !== 'sport_sante'"
                  v-text="endDate"
                  class="text-span text-span1"
              />
              <DatePicker v-else v-model="finValidite" rules="min:3" minToday />
            </div>
          </div>
          <div class="d-flex align-center" v-if="!isFederalMode">
            <v-checkbox
                class="checkbox-upload"
                v-model="attestTrue"
            ></v-checkbox>
            <span
                class="text-span"
                style="margin-top: 11px;"
                v-text="
                'En envoyant ce fichier, j’atteste être responsable de son contenu'
              "
            />
          </div>
        </div>
      </v-card-text>

      <div class="information-alert px-2 d-flex align-center mb-2">
        <span v-text="'Information :'" class="font-weight-bold mr-2" />
        <span v-text="'Le certificat téléversé doit avoir moins d\'un 1 an.'" />
      </div>
  </div>
</template>

<script>
import InputSelect from "@/components/utils/select.vue";
import { error_notification } from "@/plugins/notifications.js";

import { mapActions, mapGetters } from "vuex";
import * as crypto from "crypto";

export default {
  name: "CertificatMedicalUpload",
  components: {
    InputSelect,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
  },
  props: {
    certificats: {
      type: [Object, Array],
      default: () => null,
    },
    isLicenceHC: {
      type: Boolean,
      default: () => false,
    },
    disableRequiredFields: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loadedFile: null,
      loadedFileContent: null,
      fileInput: null,
      endDateFormated: null,
      updateCMIDs: [],
      loading: false,
      endDate: "DD/MM/YYYY",
      cmSelected: null,
      uploadPercentage: 0,
      finValidite: null,
      selectedSaison: null,
      attestTrue: false,
      certificatsMedicauxTypes: [
        {
          id: 5,
          name: "Loisir (valable 3 saisons)",
          slug: "loisir",
          file_path:
            "https://www.ffme.fr/wp-content/uploads/2019/06/certificat-medical-type.pdf",
          isLicenceHC: true,
        },
        {
          id: 9,
          name: "Compétition (valable 3 saisons)",
          slug: "competition",
          file_path:
            "https://www.ffme.fr/wp-content/uploads/2019/06/certificat-medical-type.pdf",
          isLicenceHC: true,
        },
      ],
      isSportSante: false,
    };
  },
  created() {
    this.selectedSaison = this.currentSaison.id;
  },
  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("structure", ["currentStructureId"]),
    isFormCompleted() {
      return !!this.loadedFileContent && !!this.loadedFile &&
          this.selectedSaison &&
          this.cmSelected &&
          (this.attestTrue === true && !this.isFederalMode || this.isFederalMode) &&
          (this.cmSelected !== "sport_sante" || this.finValidite);
    },
    isFederalMode() {
      return localStorage.getItem("ffme_user_mode") === "federal";
    },
    saisonValide() {
      return [
        this.currentSaison.id - 2,
        this.currentSaison.id - 1,
        this.currentSaison.id,
      ];
    },
  },
  watch: {
    isFormCompleted: {
      handler(value) {
        if (value) {
          const extension = this.loadedFile.type.split("/")[1];
        
          this.$emit('formCompleted', {
            complete: value,
            selectedSaison: this.selectedSaison,
            finValidite: this.finValidite,
            cmSelected: this.cmSelected,
            certicatsMedicalType: this.certificatsMedicauxTypes.find(
              (cm) => cm.slug === this.cmSelected
            )?.id,
            updateCMIDs: this.updateCMIDs,
            attestTrue: this.attestTrue,
            endDateFormated: this.endDateFormated,
            loadedFileContent: this.loadedFileContent,
            filename: extension ? `${this.generateUUID()}.${extension}` : undefined,
          })
        } else {
          this.$emit('formCompleted', {
            complete: value,
          })
        }
      },
    },
  },
  methods: {
    ...mapActions("utilisateurs", ["getUtilisateurCertificatsM"]),
    reset() {
      this.loadedFile = null;
      this.loadedFileContent = null;
      this.selectedSaison = null;
      this.finValidite = null;
      this.cmSelected = null;
      this.updateCMIDs = [];
      this.attestTrue = false;
      this.endDateFormated = null;
      this.fileInput = null;
    },
    seasonChanged(event) {
      switch (this.cmSelected) {
        default: {
          const finDate = new Date("08/31/2022").setFullYear(+event + 2);
          this.endDateFormated = this.$moment(finDate).format("YYYY-MM-DD");
          return (this.endDate = this.$moment(finDate).format("DD/MM/YYYY"));
        }
      }
    },
    cmChanged(event) {
      switch (event) {
        default: {
          const finDate = new Date("08/31/2022").setFullYear(
            +this.selectedSaison + 2
          );

          this.endDateFormated = this.$moment(
            `08/31/${+this.selectedSaison + 2}`
          ).format("YYYY-MM-DD");

          this.endDate = this.$moment(finDate).format("DD/MM/YYYY");

          return event;
        }
      }
    },
    generateUUID() {
      const bytes = crypto.randomBytes(16);

      bytes[6] = (bytes[6] & 0x0f) | 0x40;
      bytes[8] = (bytes[8] & 0x3f) | 0x80;

      return bytes.toString('hex').match(/(.{8})(.{4})(.{4})(.{4})(.{12})/).slice(1).join('-');
    },
    onFileChange(file) {
      const fileTypeAccepted = [
        "image/jpeg",
        "image/png",
        "application/pdf",
      ];
      if (file === null) {
        this.fileInput = null;
        this.loadedFile = null;
        this.loadedFileContent = null;
        
        return;
      }
      if (!fileTypeAccepted.includes(file.type)) {
        this.fileInput = null;

        return error_notification(
          "Veuillez téléverser le bon format de fichier (jpg, jpeg, png, pdf)."
        );
      }

      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size >= maxSize) {
        this.fileInput = null;

        return error_notification(
          "Veuillez téléverser un fichier de taille inférieur à 5Mo."
        );
      }


      // read file data to memory
      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.loadedFileContent = event.target.result;
        };
        reader.readAsDataURL(file);
        this.loadedFile = file;
        // reader.readAsBinaryString( this.loadedFile);
      } else {
        alert("Sorry, FileReader API not supported");
        this.fileLoaded = false;
      }
    },
  },
};
</script>

<style scoped>
.btn-style {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-transform: initial !important;
  padding: 12px 24px !important;
}
.text-header-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #020918;
}
.text-label {
  font-family: Roboto;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #000000;
}

.header-container {
  position: relative;
}
.close-btn {
  cursor: pointer;
  position: absolute;
  left: 15px;
}
.information-alert {
  height: 36px;
  flex-grow: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary);
  background-color: rgba(8, 43, 110, 0.1);
  border-left: solid 2px var(--primary);
}
.checkbox-upload >>> .v-input__slot {
  max-width: 700px !important;
}
.text-span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.text-span1 {
  font-size: 14px;
}
.text-span2 {
  font-size: 12px !important;
  color: #768aae !important;
}
.text-span3 {
  color: #bac4d7 !important;
}
.file-input >>> fieldset {
  border: solid 0.5px #e7effd !important;
  height: 42px !important;
  width: 280px;
}
::v-deep .item-without-msg .v-text-field__details {
  display: none !important;
}
</style>
