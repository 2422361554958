<template>
  <div>
    <h3>Validation</h3>

    <v-row>
      <v-col cols="12"
        >Votre demande a bien été prise en compte. Nous vous recontacterons dans
        les plus brefs délais.</v-col
      >
    </v-row>
  </div>
</template>

<script>
  export default {
    components: {},

    data: () => ({}),

    async created() {
      await this.setData();
    },

    computed: {},

    methods: {
      async setData() {
        return;
      },
    },

    props: {
      user: {
        type: Object,
      },
    },
  };
</script>

<style scopped>
  label {
    margin-bottom: 0 !important;
    font-size: 0.8rem;
  }
</style>
