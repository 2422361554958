<template>
  <div id="print-facture" ref="print-facture">
    <div class="container">
      <img class="habillage" src="~@/assets/FFME_EN_TETE.jpg" alt="" />
      <div class="content">
        <h6
          style="
            display: flex;
            justify-content: flex-end;
            padding-right: 250px;
            padding-top: 100px;
          "
        >
          {{ currentUser.CT_Nom }} {{ currentUser.CT_Prenom }}<br />
          {{ adresse }}
        </h6>
        <div
          class="table-contact d-flex justify-content-center"
          style="width: 970px;"
        >
          <table>
            <tr>
              <th style="text-align: center;">Date</th>
              <th style="text-align: center;">Numéro de facture</th>
              <th style="text-align: center;">Contact</th>
              <th style="text-align: center;">Organisateur</th>
            </tr>
            <tr>
              <td style="text-align: center;">{{ date }}</td>
              <td style="text-align: center;">21-VOY-0006</td>
              <td style="text-align: center;">sinistres@ffme.fr</td>
              <td style="text-align: center;">Departement administratif</td>
            </tr>
          </table>
        </div>
      </div>
      <div style="position: absolute; top: 75vh; width: 970px;">
        <table class="table-details">
          <tr>
            <th>QUANTITE</th>
            <th style="width: 40%;">DESTINATION</th>
            <th>PRIX UNITAIRE</th>
            <th>MONTANT TTC</th>
          </tr>
          <tr>
            <td style="border-bottom: none; border-top: none;"></td>
            <td
              style="border-bottom: none; border-top: none; text-align: center;"
            >
              Prime d'assurance voyage lointain<br />
            </td>
            <td style="border-bottom: none; border-top: none;"></td>
            <td style="border-bottom: none; border-top: none;"></td>
          </tr>
          <tr>
            <td style="border-bottom: none; border-top: none;"><br /></td>
            <td style="border-bottom: none; border-top: none;"><br /></td>
            <td style="border-bottom: none; border-top: none;"><br /></td>
            <td style="border-bottom: none; border-top: none;"><br /></td>
          </tr>
          <tr>
            <td style="border-bottom: none; border-top: none;"></td>
            <td
              style="border-bottom: none; border-top: none; text-align: center;"
            >
              <p style="font-size: 20px;">Pays : {{ pays }}</p>
              <br />
              <p style="font-size: 20px;">
                Date : du {{ $moment(datedepart).format("DD/MM/YYYY") }} au
                {{ $moment(dateretour).format("DD/MM/YYYY") }}
              </p>
            </td>
            <td style="border-bottom: none; border-top: none;"></td>
            <td style="border-bottom: none; border-top: none;"></td>
          </tr>
          <tr>
            <td style="border-bottom: none; border-top: none;"></td>
            <td style="border-bottom: none; border-top: none;"></td>
            <td style="border-bottom: none; border-top: none;"></td>
            <td style="border-bottom: none; border-top: none;"></td>
          </tr>
          <tr>
            <td style="border-bottom: none; border-top: none;"><br /></td>
            <td style="border-bottom: none; border-top: none;"><br /></td>
            <td style="border-bottom: none; border-top: none;"><br /></td>
            <td style="border-bottom: none; border-top: none;"><br /></td>
          </tr>
          <tr>
            <td
              style="border-bottom: none; border-top: none; text-align: center;"
            >
              1
            </td>
            <td
              style="border-bottom: none; border-top: none; text-align: center;"
            >
              souscription pour: <br />
              - {{ currentUser.CT_Nom }} {{ currentUser.CT_Prenom }} ({{
                currentUser.LicenceNumero
              }})
            </td>
            <td
              style="border-bottom: none; border-top: none; text-align: center;"
            >
              50,00 €
            </td>
            <td
              style="border-bottom: none; border-top: none; text-align: center;"
            >
              50,00 €
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td style="text-align: center;">TOTAL TTC</td>
            <td style="text-align: center;">50,00 €</td>
          </tr>
        </table>

        <p
          style="
            position: absolute;
            top: 60vh;
            font-weight: bold;
            font-family: 'Times New Roman';
            font-size: 18px;
          "
        >
          Facture acquittée par carte bancaire.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
  data: () => ({
    printDisabled: false,
    voyagedetails: [],
    currentUser: [],
    adresse: "",
    date: Object,
    pays: "",
    datedepart: "",
    dateretour: "",
  }),

  props: {
    print: {
      type: Object,
    },
  },
  methods: {
    printFacture(voyagedetails, name, currentuser) {
      this.voyagedetails = voyagedetails;
      this.currentUser = currentuser;
      this.adresse = this.currentUser?.ADR_Adresse
        ? this.currentUser.ADR_Adresse[0].Adresse1
        : this.currentUser.UTI_Utilisateur?.ADR_Adresse[0].Adresse1;
      let date_creation = voyagedetails.UTI_AssuranceVoyage
        ? voyagedetails.UTI_AssuranceVoyage.ADR_Pays.Z_DateCreation
        : voyagedetails.Z_DateCreation;
      this.date = this.$moment(date_creation).format("DD/MM/YYYY");
      this.pays = voyagedetails.UTI_AssuranceVoyage
        ? voyagedetails.UTI_AssuranceVoyage.ADR_Pays.NomPays
        : voyagedetails.ADR_Pays.NomPays;
      this.datedepart = voyagedetails.UTI_AssuranceVoyage
        ? voyagedetails.UTI_AssuranceVoyage.DateDepart
        : voyagedetails.DateDepart;
      this.dateretour = voyagedetails.UTI_AssuranceVoyage
        ? voyagedetails.UTI_AssuranceVoyage.DateRetour
        : voyagedetails.DateRetour;

      this.$nextTick(() => {
        this.$refs["print-facture"].style.display = "block";
        let source = document.querySelector("#print-facture");
        html2canvas(source, {
          letterRendering: 1,
          scale: 2,
          logging: true,
          useCORS: true,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg");
          const pdf = new jsPDF({
            orientation: "portrait",
          });
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
          pdf.save(name);
          this.$refs["print-licence"].style.display = "none";
        });
      });
    },
  },
};
</script>
<style lang="scss">
#print-facture {
  width: 1000px;
  position: absolute;
  z-index: -3;
  top: 0;
  left: 0;
  display: none;
  .container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .habillage {
    width: 100%;
  }
  .content {
    width: 100%;
    position: absolute;
    top: 10vh;
    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
      width: 900px;
      font-family: "Roboto Condensed";
      color: black;
      font-size: 20px;
    }
  }
  .table-contact {
    position: absolute;
    top: 50vh;
  }
  .table-details {
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
      width: 900px;
      font-family: "Roboto Condensed";
      color: black;
      font-size: 20px;
      margin-left: 10px;
      text-align: center;
    }
  }
  .no-border-two {
    border-bottom: none;
    border-top: none;
    text-align: center;
  }
}
</style>
