<template>
  <div v-if="!isLoading">
    <h3>Etape 2 - Saisie des options d'assurance</h3>
    <ValidateForm @validated="next" v-slot="{ invalid }">
      <v-row>
        <v-col cols="12" md="12">
          <h6>Choix de l'assurance*</h6>
          <RadioGroup
            name="assurancesSelected"
            v-model="assurancesSelected"
            :checked="assurancesSelected"
            :options="formattedAssurance"
            rules="required"
            :radioGroupProps="{'row':false}"
            @change="assuranceChange"
          ></RadioGroup>
          <v-alert
            v-if="isResponsabiliteCivile"
            border="top"
            color="red lighten-2"
            dark
            class="mt-2"
          >
            En choisissant une assurance de type "Responsabilité Civile", vous
            pouvez uniquement souscrire aux options "Protection agression" et "Compétition"
          </v-alert>

          <div class="mt-4">
            <h6>
              Options facultatives (uniquement avec les assurances base, base+
              et base++)
            </h6>

            <div class="d-flex align-center">
              <v-checkbox
                v-for="(assu) in assurancesOptions"
                :key="assu.id"
                dense
                v-model="assurancesOptionsSelected"
                :disabled="(isResponsabiliteCivile || !assurancesSelected) && (assu.slug !== assurance_option_protection_agression && assu.slug !== assurance_option_competition)"
                :label="assu.label + ' (' + (prices.find((price) => price.option && price.option.id === assu.id).value / 100) + ' €)'"
                :value="assu.id"
                style="margin-top: 0;"
                class="pl-3"
              ></v-checkbox>
            </div>
            <div class="mt-4 assuranceIJ">
              <h6>Assurance complémentaire</h6>
              <RadioGroup
                  :key="'assuComp_' + assurancesCompSelectedKey"
                  v-model="assurancesCompSelected"
                  :checked="assurancesCompSelected"
                  :options="assurancesComp"
                  :disabled="isResponsabiliteCivile || !assurancesSelected"
              ></RadioGroup>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-4">
        <v-btn text class="btn btn-gray" @click.prevent="$emit('onPrevious')"
          >Retour</v-btn
        >
        <v-btn type="submit" class="btn btn-primary ml-auto" style="margin-left: auto;" :disabled="invalid">Suivant</v-btn>
      </v-row>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from '@/components/utils/form';
import { mapGetters } from 'vuex';
import RadioGroup from "@/components/utils/radioGroup.vue";
import {
  PRODUCT_CATEGORY_LICENCE,
  PRODUCT_LICENCE_HORS_CLUB_JEUNE,
  PRODUCT_LICENCE_HORS_CLUB_ADULTE,
  OPTION_TYPE_ASSURANCE,
  OPTION_TYPE_OPTION_LICENCE_HC,
  OPTION_TYPE_OPTION_ASSURANCE,
  OPTION_TYPE_IJ,
  ASSURANCE_RC,
  ASSURANCE_OPTION_PROTECTION_AGRESSION,
  getProducts,
  getOptions,
  getPrices
} from "@/plugins/productService";
import { ASSURANCE_OPTION_COMPETITION } from '../../plugins/productService';

export default {
  components: { ValidateForm, RadioGroup },

  data: () => ({
    isLoading: true,
    data: {},
    assurancesSelected: null,
    assurancesOptionsSelected: [],
    licenceSelected: null,
    assurancesCompSelected: null,
    datenaissance: false,
    questionnaire: null,
    assurancesCompSelectedKey: 1,
    products: [],
    options: [],
    prices: [],
    assurance_option_protection_agression: ASSURANCE_OPTION_PROTECTION_AGRESSION,
    assurance_option_competition: ASSURANCE_OPTION_COMPETITION,
  }),

  async created() {
    await this.setData();

    this.isLoading = false;
  },

  computed: {
    ...mapGetters('saisons', ['currentSaison']),

    formattedAssurance() {
      return this.options
          .filter((option) => option.optionType.slug === OPTION_TYPE_ASSURANCE)
          .map((option) => ({
            value: option.id,
            label: option.label + ' (' + (this.prices.find((price) => price.option && price.option.id === option.id).value / 100) + ' €)',
          }));
    },

    assurancesOptions() {
      return this.options.filter((option) => option.optionType.slug === OPTION_TYPE_OPTION_ASSURANCE || option.optionType.slug === OPTION_TYPE_OPTION_LICENCE_HC);
    },

    isResponsabiliteCivile() {
      let selectedAssurance = this.options.find(
          (option) => option.id === this.assurancesSelected
      );

      if (!selectedAssurance) {
        return false;
      }

      return selectedAssurance.slug === ASSURANCE_RC;
    },

    assurancesComp() {
      return this.options
          .filter((option) => option.optionType.slug === OPTION_TYPE_IJ)
          .map((option) => ({
            value: option.id,
            label: option.label + ' (' + (this.prices.find((price) => price.option && price.option.id === option.id).value / 100) + ' €)',
          }));
    },
  },

  methods: {
    next() {
      this.$emit('onNext');

      let assu = [];

      if (this.assurancesCompSelected) {
        assu.push(this.assurancesCompSelected);
      }

      if (this.assurancesOptionsSelected.length > 0) {
        assu = [...this.assurancesOptionsSelected, ...assu];
      }

      this.$emit('onSetData', {
        licence: this.licenceSelected,
        assurance: this.assurancesSelected,
        optionAssurance: assu,
      });
    },

    initLicenceId() {
      const birth = this.$moment(this.user.DN_DateNaissance).format(
        'DD/MM/YYYY'
      );

      const finDate = `31/08/${this.currentSaison.id}`;

      const realAge = this.$moment(finDate, 'DD/MM/YYYY').diff(
        this.$moment(birth, 'DD/MM/YYYY'),
        'years'
      );

      if (realAge >= 18) {
        this.licenceSelected = this.products.find(
          (product) => product.slug === PRODUCT_LICENCE_HORS_CLUB_ADULTE
        ).id;
      } else {
        this.licenceSelected = this.products.find(
          (product) => product.slug === PRODUCT_LICENCE_HORS_CLUB_JEUNE
        ).id;
      }
    },

    assuranceChange(assurance) {
      let selectedAssurance = this.options.find(
          (option) => option.id === assurance
      );

      if (selectedAssurance && selectedAssurance.slug === ASSURANCE_RC) {
        this.assurancesOptionsSelected = [];
        this.assurancesCompSelected = null;
        this.assurancesCompSelectedKey += 1;
      }
    },

    async setData() {
      this.products = await getProducts({
        'productCategorie.slug': PRODUCT_CATEGORY_LICENCE,
        'order[order]': 'asc',
      });

      this.options = await getOptions({
        'order[order]': 'asc',
      });

      this.initLicenceId();

      let options = [
        ...this.options.filter(
          (option) => option.optionType.slug === OPTION_TYPE_ASSURANCE
        ),
        ...this.options.filter(
          (option) => option.optionType.slug === OPTION_TYPE_OPTION_LICENCE_HC
        ),
        ...this.options.filter(
          (option) => option.optionType.slug === OPTION_TYPE_OPTION_ASSURANCE
        ),
        ...this.options.filter(
          (option) => option.optionType.slug === OPTION_TYPE_IJ
        ),
      ]

      this.prices = await getPrices({
        'itemsPerPage': 1000,
        'seasonId': this.currentSaison.id,
        'structureId': 1451,
        'productId': this.licenceSelected,
        'options': options.map((option) => option.id).join(','),
      });
    },
  },

  props: {
    user: {
      type: Object,
    },
  },

  watch: {
    user: {
      handler() {
        this.initLicenceId();
      },
      deep: true,
    },
  },
};
</script>

<style>
.assuranceIJ .v-input--selection-controls .v-radio > .v-label {
  font-size: 0.9rem !important;
}
</style>
