<template>
  <v-row style="display: block">
    <v-col class="text-center">
      <h1>Se licencier à la FFME</h1>
      <h3>Licence individuelle (licence hors-club)</h3>
      <div>
        <span
          >Licence valide du 1er septembre {{ currentSaison.id - 1 }} au 31 août
          {{ currentSaison.id }}</span
        >
      </div>
    </v-col>

    <v-stepper alt-labels v-model="step">
      <div style="font-size: 0.8rem; padding: 0.6rem">
        <p class="font-weight-bold">Souscrire à une licence hors-club:</p>

        <ul style="margin: 0; padding: 0">
          <li>
            1) Saisie des coordonnées, choix des options d'assurance, envoi du
            certificat médical et prise de connaissance de la notice
            d'information d'assurance.
          </li>
          <li class="mt-4">
            2) Notification par courriel de la validation de votre dossier
            puis télépaiement de la licence. La licence et les
            assurances ne seront actives qu'à partir de la réalisation du
            télépaiement.
          </li>
        </ul>
      </div>

      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1"
          >Coordonnées</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2"
          >Options d'assurance</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3"
          >Certificat médical</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 4" step="4"
          >Récapitulatif</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <step1
            @onNext="step += 1"
            @onSetData="setUserData"
            :userRenouvellement="userRenouvellementInfo"
            :loading="loading"
          />
        </v-stepper-content>

        <v-stepper-content step="2">
          <step2
            @onNext="step += 1"
            @onPrevious="step -= 1"
            @onSetData="setUserData"
            :user="user"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <step3
            @onNext="step += 1"
            @onPrevious="step -= 1"
            @onSetData="setUserData"
            :user="user"
          />
        </v-stepper-content>

        <v-stepper-content step="4">
          <step4
            v-if="step === 4"
            @validate="submit"
            @onSetPrice="setTotalPrice"
            @onPrevious="step -= 1"
            @goToStep="goToStep"
            :user="user"
          />
        </v-stepper-content>

        <v-stepper-content step="5">
          <step5 />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-row>
</template>

<script>
import step1 from './step1.vue';
import step2 from './step2.vue';
import step3 from './step3.vue';
import step4 from './step4.vue';
import step5 from './step5.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import {trimAndCapitalizeFirstLetter, trimAndUppercaseAllLetters} from "@/plugins/utils";
import { getTransactionLicenceHCBody, insertTransaction, OPTION_TYPE_ASSURANCE } from '@/plugins/transactionService';
import {
  getPrices,
} from "@/plugins/productService";
import {
  getStructuresFromZipcode,
} from "@/plugins/structureService";

export default {
  components: { step1, step2, step3, step4, step5 },

  data: () => ({
    step: 1,
    user: {},
    userInfo: {},
    userRenouvellementInfo: null,
    loading: true,
    structure_id: null,
    AllDataLicenceHorsCLub: {},
  }),

  props: {
    userRenouvellement: {
      type: Object,
      default: null,
    },
  },

  async created() {   
    await this.setData();
    this.structure_id = parseInt(this.$route.params.structure_id);
  },

  computed: {
    ...mapGetters('saisons', ['currentSaison', 'nextSaison']),
    ...mapGetters('structure', ['currentStructureId', 'currentStructure']),
  },

  methods: {
    ...mapActions('basics', ['getListTypeLicence', 'insertDocument']),
    ...mapActions('utilisateurs', [
      'saveTempUser',
      'getUtilisateurFullData',
      'createLemonwayPaiement',
    ]),

    setUserData(data) {
      console.log(data);
      this.user = { ...this.user, ...data };
    },

    goToStep(step) {
      this.step = step;
    },

    setTotalPrice(price) {
      this.totalPrice = price;
      console.log('this.totalPrice', this.totalPrice);
    },

    async submit() {
      let assurances = [this.user.assurance, ...this.user.optionAssurance];
      let file = await this.uploadFile();

      const structures = await getStructuresFromZipcode({
        zipcode: this.user.ADR_CodePostal,
      })

      const structureId = structures[0];
    
      this.prices = await getPrices({
        'itemsPerPage': 1000,
        'seasonId': this.currentSaison.id,
        'structureId': structureId,
        'productId': this.user.licence,
        'options': assurances.join(','),
      });

      let payload = {
        CT_Nom: trimAndUppercaseAllLetters(this.user.CT_Nom),
        CT_Prenom: trimAndCapitalizeFirstLetter(this.user.CT_Prenom),
        ID_Structure: 1451, //structure rattachement pour les licences hors-club
        DN_DateNaissance: this.user.DN_DateNaissance,
        CT_Email: this.user.CT_Email,
        CT_TelephoneFixe: this.user.TelephoneFixe,
        CT_TelephoneMobile: this.user.TelephoneMobile,
        LicenceNumero: this.user.numeroLicence,
        ID_PaysNationalite: this.user.nationalite,
        EST_Civilite: this.user.EST_Civilite,
        NomMedecinTraitant: this.user.nomMedecinTraitant,
        UTI_Licence: this.user.licence,
        UTI_LicenceAssurances: JSON.stringify(assurances),
        ID_CertificatMedical: this.user.file.certicatsMedicalType,
        Z_DateCreationDemande: this.$moment().toISOString(),
        ID_Departement: this.user.ADR_Departement.id,
        Status: 0,
        ID_PI_Stripe: null,
        ADR_Adresse: JSON.stringify({
          ID_Pays: this.user.ADR_Pays,
          Adresse1: this.user.ADR_Adresse1,
          Adresse2: this.user.ADR_Adresse2,
          CodePostal: this.user.ADR_CodePostal,
          Ville: this.user.ADR_Ville,
        }),
      };
      
      try {
        payload.DOC_DocumentsTemp = {
          data: [
            {
              CheminDocument: file.url,
              NomDocument: file.filename,
              DateFinValiditeDocument: file.finDeValidite,
              ID_DocumentType: file.ID_DocumentType
            },
          ],
        };
        console.log(`payload===>>`, payload);
        this.AllDataLicenceHorsCLub = payload;

        //PAYEMENT LEMONWAY GET ALL DATA INFORMATION
        const dataLicenceHorsCLub = JSON.stringify({
          context: `Licence Hors CLub - Saison ${this.currentSaison.id}`,
          data: this.AllDataLicenceHorsCLub,
        });
        console.log(`AlldataLicenceHorsCLub===>>`, dataLicenceHorsCLub);

        const responseLemonway = await this.createLemonwayPaiement({
          data: dataLicenceHorsCLub,
        });
        console.log(`responseLemonway LicenceHorsCLub=>`, responseLemonway);

        const idPaiement =
          responseLemonway.data.insert_MKP_Lemonway.returning[0].id;

        //data to send to LEMONWAY WEBINIT
        const dataToSend = {
          returnUrl: `${process.env.VUE_APP_FRONT_URL}lemonway/payment-success`,
          // returnUrl: `http://localhost:8080/lemonway/payment-success`,
          errorUrl: `${process.env.VUE_APP_FRONT_URL}lemonway/payment-error`,
          cancelUrl: `${process.env.VUE_APP_FRONT_URL}lemonway/payment-cancel`,
          registerCard: false,
          reference: `Licence Hors CLub - Saison ${this.currentSaison.id}`,
          accountId: `${process.env.VUE_APP_LEMONWAY_DEFAULT_ACCOUNT}`,
          totalAmount: this.totalPrice * 100,
          comment: `LicenceHorsCLub:${idPaiement}`,
          autoCommission: false,
        };
        console.log(`dataToSend LicenceHorsCLub===>>`, dataToSend);

        // ------ PAYEMENT LEMONWAY GET ALL DATA INFORMATION
        payload.PaymentInformation = JSON.stringify(dataToSend);

        const userTemp = await this.saveTempUser({ user: [payload] });

        const userTempId = userTemp.insert_UTI_UtilisateursTemp.returning[0].id;

        const transaction = await insertTransaction(
          getTransactionLicenceHCBody(
            this.totalPrice,
            this.user.licence,
            assurances,
            this.currentSaison.id,
            userTempId,
          )
        );

        if (!transaction.ok) {
          error_notification('Une erreur est survenue lors de la création de la transaction');
          this.loading = false;
          return;
        }

        success_notification(
          "L'utilisateur a été créé et le lien de paiement a été renvoyé a " + this.user.CT_Email
        );

        this.step = 5;
      } catch (e) {
        console.log(e);
        error_notification('Une erreur est survenue lors de la création de la transaction');
      }
    },

    getLastDateOfSeason() {
      var today = new Date();
      var lastDayOfMonth = new Date(today.getFullYear(), 8, 0);
      return lastDayOfMonth;
    },

    async uploadFile(user) {
      console.log('uploadFile', this.user.file);
      const hash =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);

      let payload = {
        image: this.user.file.loadedFileContent,
        key: `1451_${hash}_${this.user.file.filename}`,
        config: {
          S3_BUCKET: process.env.S3_BUCKET,
        },
      };
      console.log('file payload', payload);
      return await this.axios
        .post(process.env.VUE_APP_FFME_BACK_URL + '/api/media-upload', payload)
        .then((response) => {
          if (
            response.data &&
            response.data.location &&
            response.status &&
            response.status === 200
          ) {
            let freshFile = {
              url: response.data.location,
              filename: this.user.file.filename,
              finDeValidite: this.user.file.endDateFormated,
              ID_DocumentType: this.user.file.selectedSaison
            };
            //console.log(freshFile)
            return freshFile;
          } else {
            error_notification('Upload unsuccessful');
          }
        })
        .catch((e) => {
          console.log('error', e);
        });
    },

    async setData() {
      //await this.getListTypeLicence()
      this.userRenouvellementInfo = this.userRenouvellement && {
        ...this.userRenouvellement,
      };
      if (this.$route.params.user_id) {
        this.userInfo = await this.getUtilisateurFullData(
          this.$route.params.user_id
        );
        this.user = { ...this.userInfo.UTI_Utilisateurs[0] };
        this.userRenouvellementInfo = this.userInfo.UTI_Utilisateurs[0];
        this.loading = false;
      } else {
        this.loading = false;
      }
      if (this.userRenouvellement && this.userRenouvellement.id) {
        this.user = { ...this.userRenouvellement };
      }

      return;
    },
  },
};
</script>

<style>
.v-stepper__label {
  text-align: center !important;
}
</style>
