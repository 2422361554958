<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 mx-auto mt-5">
        <div class="payment">
          <div class="payment_header">
            <div class="check">
              <v-icon>mdi-cancel</v-icon>
            </div>
          </div>
          <div class="content">
            <h1>Paiement annulé</h1>
            <v-btn class="btn btn-primary mt-5" @click.prevent="homePage()"
              >Accueil</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    console.log('Payment cancel LEMONWAY', `${process.env.VUE_APP_FRONT_URL}`);
  },
  methods: {
    homePage() {
      location.replace(`${process.env.VUE_APP_FRONT_URL}`);
    },
  },
};
</script>

<style scoped>
body {
  background: #f2f2f2;
  border-color: #002755 !important;
}

.payment {
  border: 1px solid #f2f2f2;
  height: 280px;
  border-radius: 20px;
  background: #fff;
}
.payment_header {
  background: #002755 !important;
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}

.check {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
}

.check i {
  vertical-align: middle;
  line-height: 50px;
  font-size: 30px;
}

.content {
  text-align: center;
}

.content h1 {
  font-size: 25px;
  padding-top: 25px;
}

.content a {
  width: 200px;
  height: 35px;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px;
  background: rgba(255, 102, 0, 1);
  transition: all ease-in-out 0.3s;
}

.content a:hover {
  text-decoration: none;
  background: #000;
}
</style>
