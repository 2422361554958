<template>
  <div
    class="paiement-container d-flex flex-column align-center justify-content-center position-relative"
    style="width: 100%; height: 60vh;"
  >
    <v-card
      elevation="3"
      class="d-flex align-center justify-content-center card-container"
      width="400"
      height="200"
    >
      <span class="text-ffme" v-text="msg" />
    </v-card>

    <v-btn class="btn btn-primary mt-10" @click.prevent="homePage()"
      >Revenir sur myFFME</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "PagePaiementFFME",
  data: () => ({
    msg:
      "L'interface de paiement par carte bancaire est verrouillée pendant la phase de tests de myFFME.",
  }),
  methods: {
    homePage() {
      location.replace("https://login.myffme.fr/");
    },
  },
};
</script>

<style scoped>
.paiement-container {
  font-family: "Roboto";
  font-size: 16px !important;
  font-weight: normal;
}
.card-container {
  border-radius: 15px !important;
  padding: 24px 14px;
}
</style>
