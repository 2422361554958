<template>
  <div class="mb-3 container-step">
    <span class="title-stepper"
      >Étape 3 - Convention d’affiliation à transmettre</span
    >
    <div class="d-flex flex-column">
      <span
        class="text-normal"
        style="margin: 32px 0px;"
        v-text="
          'Convention d’affiliation au format PDF à télécharger, à imprimer en deux exemplaires, à signer puis à adresser au siège de la FFME à l’adresse suivante :'
        "
      >
      </span>
      <v-card
        elevation="5"
        class="d-flex text-normal flex-column p-4 ml-1"
        width="250"
        height="110"
        style="position: relative; font-weight: 400;"
      >
        <span class="text">
          FFME
        </span>
        <span class="text">
          8-10 Quai de la Marne
        </span>
        <span class="text">
          75019 PARIS
        </span>
        <div class="icon-container" @click="printInformation()">
          <div class="first">
            <div class="second" />
          </div>
        </div>
      </v-card>
      <v-btn
        class="btn btn-primary btn-font mt-8"
        style="margin-right: auto;"
        @click.prevent="printConventionMA()"
        >Téléchargement de la convention d’affiliation</v-btn
      >

      <span class="text5 mt-2">
        Cette convention d’affiliation sera disponible au niveau de la gestion
        de la structure
      </span>
    </div>
    <v-row no-gutters class="my-4 mt-8">
      <v-btn
        class="btn btn--primary btn-font ml-1"
        @click.prevent="$emit('onPrevious')"
        text-color="white"
        rounded
        height="42"
        >Étape précédente</v-btn
      >
      <v-btn
        :disabled="!hasDownloadedConvention"
        class="btn btn-primary btn-font"
        style="margin-left: auto;"
        @click="$emit('onNext')"
        >Suivant</v-btn
      >
    </v-row>
    <PrintConventionAffiliationMA class="convention" />
  </div>
</template>

<script>
import { bus } from "@/main";
import PrintConventionAffiliationMA from "./PrintConventionAffiliationMA.vue";
import { success_notification } from "@/plugins/notifications.js";
export default {
  name: "primoAffiliationMAStep3",

  components: { PrintConventionAffiliationMA },
  data() {
    return {
      hasDownloadedConvention: false,
    };
  },
  methods: {
    printConventionMA() {
      bus.$emit("print-convention-affiliation-MA");
      this.hasDownloadedConvention = true;
    },
    printInformation() {
      navigator.clipboard.writeText(
        "FFME \n8-10 Quai de la Marne \n75019 PARIS"
      );
      success_notification("Information copiée");
    },
  },
};
</script>

<style scoped>
.convention {
  position: absolute;
  top: 450px;
}
label {
  margin-bottom: 0 !important;
  font-size: 0.8rem;
}
.assuranceIJ .v-input--selection-controls .v-radio > .v-label {
  font-size: 1rem !important;
}
.icon-container {
  position: absolute;
  cursor: pointer;
  right: 15px;
  bottom: 15px;
}
.first {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #b8cefa;
}
.second {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: solid 2px #b8cefa;
}
.container-step {
  max-height: 450px;
  position: relative;
}
</style>
