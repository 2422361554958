<template>
  <FormLicenceHorsClub :userRenouvellement="user" />
</template>

<script>
import FormLicenceHorsClub from "@/views/LicenceHorsClub/LicenceHorsClub.vue";

export default {
  components: { FormLicenceHorsClub },
  props: {
    user: {
      type: Object,
    },
  },
  created() {
    console.log("this.user===>>", this.$route.params.user_id);
  },
};
</script>
