<template>
  <validation-provider
    :rules="rules"
    v-slot="{ errors }"
    :name="getName"
    :ref="customRef"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <div v-if="noLine" class="ffme-form__select">
      <v-select
        :id="getId"
        :items="items"
        :label="label"
        v-model="currentValue"
        @change="change"
        :class="getClass(errors)"
        :disabled="disabled"
        :multiple="multiple"
        :small-chips="chips"
        :append-icon="appendIcon"
        :item-text="item_text"
        :item-value="item_value"
        :placeholder="placeholder"
        :readonly="readonly"
        solo
        dense
        style="max-width: 400px !important;"
      >
        <template v-if="$scopedSlots.itemSlot" v-slot:item>
          <slot name="itemSlot" />
        </template>
      </v-select>
    </div>

    <v-row
      v-else
      align-content="center"
      :no-gutters="label ? false : true"
      class="ffme-form__select"
    >
      <v-col
        cols="12"
        :md="labelCols"
        v-if="label"
        :class="{ 'field__label-column': true, 'py-1': twoLines }"
      >
        <font-awesome-icon
          :icon="{ prefix: 'fab', iconName: icon }"
          class="mr-2"
          v-if="icon"
        />
        <label :class="{ 'label-secondary': isLabelSecondary }" for=""
          >{{ label }}{{ isRequired && !disabled ? "*" : "" }}</label
        >
      </v-col>

      <v-col
        cols="12"
        :md="inputCols"
        style="flex: 1;"
        :class="{ 'field__value-column': true, 'pt-0 pb-1': twoLines }"
      >
        <v-select
          :id="getId"
          :items="items"
          :label="label"
          v-model="currentValue"
          @change="change"
          :class="getClass(errors)"
          :disabled="disabled"
          :multiple="multiple"
          :small-chips="chips"
          :append-icon="appendIcon"
          :item-text="item_text"
          :item-value="item_value"
          :placeholder="placeholder"
          :readonly="readonly"
          solo
          dense
          style="max-width: 400px !important;"
          v-bind="$attrs"
        >
          <template v-if="$scopedSlots.itemSlot" v-slot:item>
            <slot name="itemSlot" />
          </template>
        </v-select>

        <span class="form__error-message">{{ errors[0] }}</span>
      </v-col>
    </v-row>
  </validation-provider>
</template>

<script>
import { string_to_slug } from "@/plugins/utils.js";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    customRef: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number, Object, Array],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    custom_class: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    autocomplete: {
      type: String,
      default: "false",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },

    items: {
      type: [Object, Array],
    },

    twoLines: {
      type: Boolean,
      default: false,
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },

    noLine: {
      type: Boolean,
      default: false,
    },

    // SELECT SPECIFIC
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: "$dropdown",
    },

    item_text: {
      type: String,
      default: "text",
    },

    item_value: {
      type: String,
      default: "value",
    },
    isLabelSecondary: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getClass(errors) {
      let futurClasses = "";
      if (errors[0]) {
        futurClasses += " is-invalid ";
      }

      futurClasses += this.custom_class;
      if (this.disabled) futurClasses += " disabled";
      if (this.fullWidth) {
        futurClasses += " full-width ";
      }

      return futurClasses;
    },

    getValue(item) {
      return item;
    },
    change(item) {
      this.$emit("onChange", item);
    },
  },

  computed: {
    getId() {
      if (this.id) {
        return this.id;
      }
      return (
        "input_" +
        this.type +
        "_" +
        Math.floor(Math.random() * 100) +
        "_" +
        string_to_slug(this.getName)
      );
    },

    getName() {
      if (this.name) {
        return this.name;
      }
      return this.label.toLowerCase();
    },

    isRequired() {
      return this.rules.indexOf("required") !== -1;
    },

    inputCols() {
      if (this.twoLines) {
        return "12";
      }
      return this.label ? "9" : "12";
    },

    labelCols() {
      return this.twoLines ? "12" : "3";
    },
  },

  mounted() {
    this.currentValue = this.value;
  },

  data: () => ({
    currentValue: "",
  }),

  watch: {
    currentValue(val) {
      // allows us to use v-model on our input.
      this.$emit("input", val);
    },
    value(val) {
      this.currentValue = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.label-secondary {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px;
  color: #000000;
}
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}

input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}
</style>

<style lang="scss" scoped>
.ffme-form__select {
  .v-input__control {
    min-height: 30px !important;
  }
  .v-input__slot {
    margin: 0 !important;
    box-shadow: none !important;
    min-height: 20px;
    border: 1px solid #dfe5f9;
    //padding: 2px 5px 1px !important;
    font-weight: 200;
    font-size: 0.8rem;
    border-radius: 0;
  }
  v

      // CHIPS
      .v-select--chips {
    .v-input__slot {
      border: none;
    }
    .v-input__icon .v-icon {
      font-size: 2rem;
      color: #0045ab;
    }
  }
  .v-chip.v-size--small {
    font-size: 0.8rem;
    background: #0045ab;
    color: white;
    font-weight: bold;
  }

  .v-input__slot .v-select__slot {
    border: none;

    .v-label {
      font-size: 0.8rem;
      padding-left: 4px;
    }
  }
  .v-select__selections {
    padding: 4px 5px !important;
  }
  .v-select__selection.v-select__selection--comma {
    margin: 0;
  }
  input {
    max-height: 20px;
  }
  .v-text-field__details {
    display: none;
  }
  .disabled {
    .v-input__slot {
      background: rgba(0, 0, 0, 0.1) !important;
    }
  }
}
</style>
