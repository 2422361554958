<template>
  <span>
    <v-chip
      class="ma-2"
      :color="colorChip"
      dark
      :close="
        colorChip !== 'blue' &&
          deletable &&
          isSameSaison &&
          UserCanDelete &&
          isPriorSeason &&
          isEspaceFederal
      "
      @click:close="DeleteEssai(context)"
    >
      {{ assuranceName }}</v-chip
    >
  </span>
</template>

<script>
// import { success_notification } from "@/plugins/notifications";
import { mapActions, mapGetters } from "vuex";
import { OPTION_TYPE_IJ, OPTION_TYPE_OPTION_ASSURANCE, OPTION_TYPE_OPTION_LICENCE_HC } from "@/plugins/productService";
export default {
  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    Item() {
      return this.context;
    },
    assurance() {
      return this.context.assurance.option;
    },

    assuranceName() {
      return this.assurance.label;
    },
    isSameSaison() {
      return this.context.ID_Saison === this.currentSaison.id;
    },
    colorChip() {
      if (
        this.assurance.optionType.slug === OPTION_TYPE_OPTION_ASSURANCE || this.assurance.optionType.slug === OPTION_TYPE_OPTION_LICENCE_HC
      )
        return "green";
      if (this.assurance.optionType.slug === OPTION_TYPE_IJ) return "gray";
      return "blue";
    },

    UserCanDelete() {
      let role = this.getRole?.SlugRole;
      if (role === "super_administrateur") return true;
      return false;
    },

    isPriorSeason() {
      if (this.currentSaison.id <= this.context?.item?.ID_Saison) return true;
      return false;
    },

    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("auth", ["roles"]),
    ...mapGetters("user", ["getRole"]),
  },

  methods: {
    ...mapActions("utilisateurs", ["deleteOptionAssurance"]),
    DeleteEssai(data) {
      this.ID_AssuranceTemp = data?.assurance?.ASSU_AssuranceType?.id;
      this.ID_Assurance = parseInt(this.ID_AssuranceTemp);
      this.ID_Utilisateur = data?.ID_Utilisateur;
      this.payload = {
        ID_Assurance: this.ID_Assurance,
        ID_Utilisateur: this.ID_Utilisateur,
        ID_Saison: data?.ID_Saison,
        ID_Tarif: data?.assurance?.ID_Tarif,
        ID_Licence: data?.assurance?.ID_Licence,
        assurance: data?.assurance,
      };

      this.$emit("chipsSelected", this.payload);
    },
  },

  data: () => ({
    ID_Utilisateur: null,
    ID_Assurance: null,
    payload: {},
    isEspaceFederal: true,
  }),
  created() {
    const url = this.$route.path.split("/");
    if (url[1] === "mes-licences") {
      this.isEspaceFederal = false;
    }
  },

  props: {
    context: {
      type: Object,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    hasCodeFederal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
