var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:_vm.customRef,attrs:{"rules":_vm.rules,"name":_vm.getName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.noLine)?_c('div',{staticClass:"ffme-form__select"},[_c('v-autocomplete',{class:_vm.getClass(errors),staticStyle:{"padding":"0 !important","height":"35px"},attrs:{"id":_vm.getId,"items":_vm.countries,"label":_vm.label,"disabled":_vm.disabled,"multiple":_vm.multiple,"small-chips":_vm.chips,"append-icon":_vm.appendIcon,"item-text":"name","item-value":"id","placeholder":_vm.placeholder,"solo":"","dense":"","readonly":_vm.readonly},on:{"change":_vm.change},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',{staticStyle:{"padding":"0"}},[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('country-flag',{attrs:{"country":item.CodePays}}),_c('span',{staticStyle:{"margin-left":"12px"}},[_vm._v(_vm._s(item.NomPays))])],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-select__selections",staticStyle:{"padding":"0 !important"}},[_c('div',{staticClass:"\n              v-select__selection v-select__selection--comma\n              d-flex\n              align-center\n            ",staticStyle:{"padding":"0"}},[_c('country-flag',{staticStyle:{"margin-right":"1px"},attrs:{"country":item.CodePays}}),_c('span',{staticStyle:{"margin-left":"12px"}},[_vm._v(_vm._s(item.NomPays))])],1)])]}}],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}})],1):_c('v-row',{staticClass:"ffme-form__select",attrs:{"align-content":"center","no-gutters":_vm.label ? false : true}},[(_vm.label)?_c('v-col',{class:{ 'field__label-column': true, 'py-1': _vm.twoLines },attrs:{"cols":"12","md":_vm.labelCols}},[(_vm.icon)?_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":{ prefix: 'fab', iconName: _vm.icon }}}):_vm._e(),_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.label)+_vm._s(_vm.isRequired ? "*" : ""))])],1):_vm._e(),_c('v-col',{class:{ 'field__value-column': true, 'pt-0 pb-1': _vm.twoLines },staticStyle:{"flex":"1"},attrs:{"cols":"12","md":_vm.inputCols}},[_c('v-autocomplete',{class:_vm.getClass(errors),staticStyle:{"padding":"0 !important","height":"35px"},attrs:{"id":_vm.getId,"items":_vm.countries,"label":_vm.label,"disabled":_vm.disabled,"multiple":_vm.multiple,"small-chips":_vm.chips,"append-icon":_vm.appendIcon,"item-text":"name","item-value":"id","placeholder":_vm.placeholder,"filter":_vm.customFilter,"solo":"","dense":"","readonly":_vm.readonly},on:{"change":_vm.change},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',{staticStyle:{"padding":"0"}},[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('country-flag',{attrs:{"country":item.CodePays}}),_c('span',{staticStyle:{"margin-left":"12px"}},[_vm._v(_vm._s(item.NomPays))])],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-select__selections",staticStyle:{"padding":"0 !important"}},[_c('div',{staticClass:"\n                v-select__selection v-select__selection--comma\n                d-flex\n                align-center\n              ",staticStyle:{"padding":"0"}},[_c('country-flag',{attrs:{"country":item.CodePays}}),_c('span',{staticStyle:{"margin-left":"12px"}},[_vm._v(_vm._s(item.NomPays))])],1)])]}}]),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}}),_c('span',{staticClass:"form__error-message"},[_vm._v(_vm._s(errors[0]))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }