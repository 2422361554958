<template>
  <div class="ffme__avatar d-flex align-center flex-column">
    <v-avatar
      :size="size"
      class="avatar-logo"
      v-bind:style="{ backgroundImage: 'url(' + avatarUrl + ')' }"
    >
      <v-skeleton-loader
        v-if="!mounted"
        :loading="true"
        :boilerplate="false"
        type="avatar"
        width="100%"
        height="100%"
      />
      <!-- <img v-show="mounted" :src="avatarUrl" alt="Avatar image" /> -->
    </v-avatar>

    <uploadAvatar
      v-if="upload"
      :noNotice="noNotice"
      :context="context"
      :label="labelButton"
      :isLogo="isLogo"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import { sleep } from "@/plugins/utils"
import uploadAvatar from '@/components/Common/Common__UpdateAvatar.vue';
import { bus } from '@/main';

export default {
  name: 'Avatar',

  components: { uploadAvatar },

  data: () => ({
    avatarUrl: '',
    mounted: false,
    defaultAvatar: require('@/assets/images/default_avatar.jpg'),
    structureDefaultAvatar: require('@/assets/images/logo-ffme-2018_transparent_couleur.png'),
  }),

  async created() {
    if (!this.context) {
      console.error(
        'You must pass a user prop to the context of the avatar component.'
      );
    } else {
      await this.setData();
      if (this.binded) {
        bus.$on('onAvatarUploaded', async () => {
          return await this.setData();
        });
      }

      this.mounted = true;
    }
  },
  // beforeDestroy() {
  //     //this.$eventHub.$off('onAvatarUploaded');
  // },

  computed: {
    ...mapGetters('basics', ['getList']),
  },

  methods: {
    ...mapActions('utilisateurs', ['getUtilisateurAvatar']),
    ...mapActions('structure', ['getStructureAvatar']),

    async setData() {
      if (this.context.ID_Utilisateur) {
        return await this.setUtilisateurData();
      }
      if (this.context.ID_Structure) {
        return await this.setStructurerData();
      }
      return;
    },

    async setUtilisateurData() {
      let avatarUrl = this.defaultAvatar;

      let categorie_id = this.getList.LIST_DocumentType.find(
        (doc_type) => doc_type.SlugTypeDocument === 'avatar_user'
      ).id;

      if (this.context.DOC_Documents) {
        let avatar = this.context.DOC_Documents.find(
          (doc) => doc.ID_Type_Document == categorie_id
        );
        if (avatar) avatarUrl = avatar.CheminDocument;
      } else {
        let avatars = await this.getUtilisateurAvatar(this.context);
        let avatar = avatars.find((av) => av.ID_Type_Document == categorie_id);
        if (avatar) avatarUrl = avatar.CheminDocument;
      }
      this.avatarUrl = avatarUrl;
      return;
    },

    async setStructurerData() {
      let avatarUrl = this.structureDefaultAvatar;

      let categorie_id = this.getList?.LIST_DocumentType?.find(
        (doc_type) => doc_type.SlugTypeDocument === 'avatar_structure'
      ).id;

      if (this.context.DOC_Documents) {
        let avatar = this.context.DOC_Documents?.find(
          (doc) => doc.ID_Type_Document == categorie_id
        );
        if (avatar) avatarUrl = avatar.CheminDocument;
      } else {
        let avatars = await this.getStructureAvatar(this.context);
        const isDematerialise = this.$route.path.includes(
          'licences-dematerialise'
        )
          ? true
          : false;
        let avatar = isDematerialise
          ? avatars[0]
          : avatars?.find((av) => av.ID_Type_Document == categorie_id);
        if (avatar) avatarUrl = avatar.CheminDocument;
      }
      this.avatarUrl = avatarUrl;
      return;
    },

    uploaded() {},
  },

  props: {
    context: {
      type: Object,
    },

    DOC_Documents: {
      type: Array,
    },

    size: {
      type: String,
      default: '52',
    },
    upload: {
      type: Boolean,
      default: false,
    },
    binded: {
      type: Boolean,
      default: true,
    },

    labelButton: {
      type: String,
      default: "Changer d'avatar",
    },

    noNotice: {
      type: Boolean,
      default: true,
    },
    isLogo: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.v-skeleton-loader__avatar {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
.avatar-logo {
  height: 140px;
  min-width: 140px;
  /* background-color: black; */
  background-size: contain;
  background-position: center;
  width: 140px;
}
</style>
