<template>
  <v-chip small class="mx-1" v-if="mounted && assurance.LIST_TarifCategorie.AssuranceType"
    >{{ assurance.LIST_TarifCategorie.AssuranceType.NomTypeAssurance }} ({{
      assurance.MontantUnitaire
    }}
    €)</v-chip
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    mounted: false,
    tarifs: [],
  }),
  async created() {
    let tarifs = await this.getTarifsFFME({
      ID_Saison: this.currentSaison.id,
    })
    this.tarifs = tarifs.filter(tarif => tarif.LIST_TarifCategorie.ID_TypeAssurance);
    this.mounted = true;
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison"]),

    assurance() {
      return this.tarifs.find(
        (tarif) => tarif.LIST_TarifCategorie.AssuranceType.id == this.assurance_id
      );
    },
  },

  methods: {
    ...mapActions("basics", ["getTarifsFFME"]),
  },

  props: {
    assurance_id: {
      type: [Number, String],
    },
  },
};
</script>
