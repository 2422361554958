<template>
  <div>
    <!-- <h3>RESERVER UN CRENEAU</h3> -->
    <ValidateForm @validated="next" :showFieldObl="mandatory">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="2">
              <Avatar
                :context="{ ID_Structure: +$route.params.structure_id }"
                size="102"
              />
            </v-col>
            <v-col cols="5">
              <div>
                <span class="font-weight-bold">Structure: </span>
                {{ structureData.NomStructure }}
              </div>

              <div>
                <span class="font-weight-bold">Adresse couriel: </span>
                {{ structureData.INFO_Email }}
              </div>
            </v-col>
            <v-col cols="5">
              <div>
                <span class="font-weight-bold">Activités principale: </span>
                {{ activitePrincipal }}
              </div>
              <div>
                <span class="font-weight-bold">Autres activités: </span>
                <div v-for="(item, i) in sectionList" :key="i">
                  {{
                    `- ${
                      item &&
                      item.LIST_ActiviteType &&
                      item.LIST_ActiviteType.NomActivite
                    }`
                  }}
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col offset="2" cols="5">
              <v-divider></v-divider>
              <div class="Nom__groupe" v-if="groupeNameList.length">
                <span
                  class="font-weight-bold"
                  style="padding-top: 2px; min-width: 30%;"
                  >Nom du groupe:
                </span>
                <InputSelect
                  placeholder="Sélectionnez un groupe"
                  v-model="groupeSelected"
                  :items="groupeNameList"
                  item_value="value"
                  item_text="Nom"
                  rules="required"
                  style="margin-left: 2px; width: 70%;"
                />
              </div>
              <div v-else>
                <span
                  v-if="userAge != 0"
                  class="font-weight-bold"
                  style="padding-top: 2px; min-width: 30%;"
                  v-text="
                    `Aucun groupe ouvert correspondant à votre tranche d'age (${userAge} ans) dans le club`
                  "
                />
              </div>
              <div v-if="groupeSelected">
                <span class="font-weight-bold">Activité: </span>
                {{ NomSection }}
              </div>

              <div v-if="groupeSelected">
                <span class="font-weight-bold">Modalité d'organisation: </span>
                {{ NomModaliteOraganisation }}
              </div>
              <div v-if="groupeSelected && hideType">
                <span class="font-weight-bold">Type de pratique: </span>
                {{ NomPratiqueType }}
              </div>
              <div v-if="groupeSelected && hideType">
                <span class="font-weight-bold">Niveau: </span>
                {{ NomPratiqueNiveau }}
              </div>
              <div v-if="groupeSelected">
                <span class="font-weight-bold">Age minimum: </span>
                {{ AgeMin }} ans
              </div>
              <div v-if="groupeSelected">
                <span class="font-weight-bold">Age maximum: </span>
                {{ AgeMax }} ans
              </div>
              <div v-if="groupeSelected">
                <span class="font-weight-bold">Description: </span>
                {{ Description }}
              </div>
            </v-col>

            <v-col v-if="groupeSelected" cols="5">
              <v-divider></v-divider>
              <div class="horaires__pratique">
                <span class="font-weight-bold">Horaires de pratique: </span>
                <div v-for="(horaire, i) in horaires" :key="i">
                  {{
                    `${i + 1}- ${horaire.Jour}: ${horaire.Z_HeureDebut}-${
                      horaire.Z_HeureFin
                    } (${horaire.ADR_Adresse.Adresse1} /${
                      horaire.ADR_Adresse.Ville
                    })`
                  }}
                </div>
              </div>
              <div>
                <span class="font-weight-bold">Mes options d'adhésion: </span>
                <InputSelect
                  v-if="AdhesionOption.length"
                  v-model="OptionsAdhesions"
                  :items="AdhesionOption"
                  item_text="NomEtPrix"
                  item_value="id"
                  key="tarifs"
                  multiple
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- old version  -->

      <!-- fin old version -->

      <v-row no-gutters class="my-4">
        <v-btn text class="btn btn-gray" @click.prevent="$emit('onPrevious')"
          >Retour</v-btn
        >
        <v-btn
          type="submit"
          class="btn btn-primary ml-auto"
          :disabled="!canContinue"
          style="margin-left: auto;"
          >Suivant</v-btn
        >
      </v-row>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import { mapGetters, mapActions } from "vuex";
import Avatar from "@/components/Common/Common__Avatar.vue";
import InputSelect from "@/components/utils/select.vue";
import { bus } from "@/main";

export default {
  components: {
    Avatar,
    ValidateForm,
    InputSelect,
  },

  data: () => ({
    mandatory: false,
    data: {},
    OptionsAdhesions: [],
    structureData: [],
    sectionList: null,
    utilisateurs: [],
    activitePrincipal: null,
    groupeSelected: null,
    infoGroupeSelected: {
      ID_Groupe: "",
    },
    groupeInfo: {
      Section: "",
      QuotasMax: "",
      Description: "",
      AgeMin: "",
      AgeMax: "",
      ModadiliteOrganisation: "",
      PratiqueType: "",
      PratiqueNiveau: "",
    },
    optionClub: [],
    groupeNameList: [],
    userAge: 0,
  }),

  async created() {
    await this.setData();
    bus.$on("reload-group-name", (DN_Naissance /*{*/) =>
      this.getGroupeName(DN_Naissance)
    );
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("structure", ["currentStructureId", "getGroupeCreneaux"]),

    canContinue() {
      if (this.groupeSelected !== null) return true;
      return false;
    },
    Tarif() {
      if (this.groupeInfo?.STR_GroupeSaisonCreneau) {
        const groupeCurrent = this.groupeInfo?.STR_GroupeSaisonCreneau.find(
          (saison) => saison.ID_Saison === this.currentSaison.id
        );
        return `${groupeCurrent?.MKP_Tarif.NomTarif}(${groupeCurrent?.MKP_Tarif.MontantUnitaire}€)`;
      } else return "";
    },
    montant() {
      if (this.groupeInfo?.STR_GroupeSaisonCreneau) {
        const groupeCurrent = this.groupeInfo?.STR_GroupeSaisonCreneau.find(
          (saison) => saison.ID_Saison === this.currentSaison.id
        );
        return groupeCurrent?.MKP_Tarif.MontantUnitaire;
      } else return null;
    },

    NomSection() {
      if (this.groupeInfo?.LIST_ActiviteType?.NomActivite) {
        return this.groupeInfo?.LIST_ActiviteType?.NomActivite;
      } else return "";
    },
    NomModaliteOraganisation() {
      if (this.groupeInfo?.ModaliteOrganisation) {
        return this.groupeInfo?.ModaliteOrganisation === 1
          ? "Encadré"
          : "Non-encadrée / Autonome";
      } else return "";
    },
    NomPratiqueType() {
      if (this.groupeInfo?.LIST_PratiqueType?.NomTypePratique) {
        return this.groupeInfo?.LIST_PratiqueType?.NomTypePratique;
      } else return "";
    },
    NomPratiqueNiveau() {
      if (this.groupeInfo?.LIST_PratiqueNiveau?.NomNiveauPratique) {
        return this.groupeInfo?.LIST_PratiqueNiveau?.NomNiveauPratique;
      } else return "";
    },
    AgeMax() {
      if (this.groupeInfo) {
        return this.groupeInfo.AgeMaximum;
      } else return "";
    },
    AgeMin() {
      if (this.groupeInfo) {
        return this.groupeInfo.AgeMinimum;
      } else return "";
    },
    Description() {
      if (this.groupeInfo) {
        return this.groupeInfo.Description;
      } else return "";
    },
    Quotas() {
      if (this.groupeInfo?.STR_GroupeSaisonCreneau) {
        const groupeCurrent = this.groupeInfo?.STR_GroupeSaisonCreneau.find(
          (saison) => saison.ID_Saison === this.currentSaison?.id
        );
        return groupeCurrent?.QuotasMax;
      } else return "";
    },
    Encadrant() {
      if (this.groupeInfo?.STR_GroupeSaisonCreneau) {
        const groupeCurrent = this.groupeInfo?.STR_GroupeSaisonCreneau.find(
          (saison) => saison.ID_Saison === this.currentSaison?.id
        );
        return `${groupeCurrent?.UTI_Utilisateur?.CT_Nom} ${groupeCurrent?.UTI_Utilisateur?.CT_Prenom} `;
      } else return "";
    },
    horaires() {
      if (this.groupeInfo?.STR_GroupeSaisonCreneau) {
        const groupeCurrent = this.groupeInfo?.STR_GroupeSaisonCreneau.find(
          (saison) => saison.ID_Saison === this.currentSaison?.id
        );
        const horaireList = groupeCurrent.STR_GroupeCreneauHoraires.map(
          (horaire) => ({
            ...horaire,
            Jour:
              horaire.ID_NumeroJour === 1
                ? "Lundi"
                : horaire.ID_NumeroJour === 2
                ? "Mardi"
                : horaire.ID_NumeroJour === 3
                ? "Mercredi"
                : horaire.ID_NumeroJour === 4
                ? "Jeudi"
                : horaire.ID_NumeroJour === 5
                ? "Vendredi"
                : horaire.ID_NumeroJour === 6
                ? "Samedi"
                : "Dimanche",
          })
        );
        const horaireFiltered = horaireList.sort((a, b) =>
          a.ID_NumeroJour > b.ID_NumeroJour ? 1 : -1
        );
        return horaireFiltered;
      } else return [];
    },
    AdhesionOption() {
      if (this.groupeInfo.OptionAdhesion.length) {
        const options = JSON.parse(this.groupeInfo.OptionAdhesion);
        const optionAdhesion = options
          ? options.map((option) => (option && option.ID) || option)
          : [];
        const Adhesion = optionAdhesion.map((adhesion) =>
          this.optionClub.find((option) => option.id === adhesion)
        );
        return [...Adhesion];
      } else return [];
    },
    getPresident() {
      return this.utilisateurs
        .filter((user) => user.ID_Fonction)
        .map((item) => {
          if (item.ID_Fonction === 1) {
            return (
              item.UTI_Utilisateur.CT_Nom + " " + item.UTI_Utilisateur.CT_Prenom
            );
          }
        });
    },
    hideType() {
      const organisation = this.NomModaliteOraganisation;
      if (organisation == "Non-encadrée / Autonome") {
        return false;
      }
      return true;
    },
  },

  methods: {
    ...mapActions("structure", [
      "getStructureByID",
      "getStructureActiviteFromStrID",
      "getGroupeCreneauFullData",
      "getAffiliationTarifStructure",
    ]),
    ...mapActions("utilisateurs", ["getUtlisateursInStructure"]),
    getGroupeName(DN_Naissance) {
      if (this.getGroupeCreneaux) {
        const Date_Naissance = DN_Naissance
          ? DN_Naissance
          : this.user.DN_DateNaissance;
        const userAge = this.$moment().diff(Date_Naissance, "years");
        this.userAge = userAge;
        const url = this.$route.path.split("/");
        const urlcount = url.length;
        const groupName = this.getGroupeCreneaux
          .filter((groupe) => {
            // display those who are available to ext if not licence renew
            if (!this.$route.params.user_id) {
              return (
                groupe.AgeMaximum >= userAge &&
                groupe.AgeMinimum <= userAge &&
                groupe.STR_GroupeSaisonCreneau[0].EST_VenteOuverteExterne
              );
            }
            return (
              groupe.AgeMaximum >= userAge &&
              groupe.AgeMinimum <= userAge &&
              groupe.STR_GroupeSaisonCreneau[0].EST_VenteOuverteClub
            );
          })
          .map((groupe) => {
            return {
              Nom: groupe.NomGroupe,
              value: groupe.id,
            };
          });
        return (this.groupeNameList = groupName);
      } else return (this.groupeNameList = []);
    },
    next() {
      this.$emit("onNext");
      const groupNameSelected = this.groupeNameList.find(
        (groupe) => groupe.value === this.groupeSelected
      );
      const groupeInfo = this.getGroupeCreneaux.find(
        (groupe) => groupe.id === this.groupeSelected
      );
      const groupeCurrentSaison = groupeInfo.STR_GroupeSaisonCreneau.find(
        (groupeSaison) => groupeSaison.ID_Saison === this.currentSaison.id
      );

      const optionAdhesionInfo = this.OptionsAdhesions.map((option) =>
        this.AdhesionOption.find(
          (adhesion) => adhesion && adhesion.id === option
        )
      );
      let totalAdhesionOption = 0;
      optionAdhesionInfo.map((option) => {
        return (totalAdhesionOption += option.MontantUnitaire);
      });
      this.$emit("onSetData", {
        structureName: this.structureData.NomStructure,
        presidentStructure: this.getPresident[0],
        phoneStructure: this.structureData.INFO_Telephone,
        emailStructure: this.structureData.INFO_Email,
        principalActivity: this.activitePrincipal,
        otherActivity: this.sectionList,
        groupeSelected: this.groupeSelected,
        Section: this.NomSection,
        NomGroupe: groupNameSelected.Nom,
        AgeMax: this.AgeMax,
        AgeMin: this.AgeMin,
        NomModaliteOraganisation: this.NomModaliteOraganisation,
        PratiqueType: this.NomPratiqueType,
        PratiqueNiveau: this.NomPratiqueNiveau,
        Encadrant: this.Encadrant,
        Horaires: this.horaires,
        // Prerequis: this.Prerequis,
        Quotas: this.Quotas,
        Description: this.Description,
        Tarif: this.Tarif,
        cotisationClub: this.montant,
        OptionAdhesion: [...optionAdhesionInfo],
        totalOptionAdhesion: totalAdhesionOption,
        ID_GroupeSaisonCreneau: groupeCurrentSaison.id,
      });
    },

    async setData() {
      await this.getListActiviteStructure();

      await this.getGroupeCreneauFullData({
        ID_Structure: parseInt(this.$route.params.structure_id),
        ID_Saison: this.currentSaison.id,
      });
      this.getGroupeName();

      this.structureData = await this.getStructureByID(
        parseInt(this.$route.params.structure_id)
      );

      this.utilisateurs = await this.getUtlisateursInStructure({
        ID_Structure: parseInt(this.$route.params.structure_id),
      });

      //options club
      let response = await this.getAffiliationTarifStructure({
        ID_Structure: parseInt(this.$route.params.structure_id),
      });
      response = response.map((list) => ({
        ...list,
        NomEtPrix: `${list.NomTarif} (${list.MontantUnitaire} €)`,
      }));

      this.optionClub = response.filter((tarif) => tarif.EST_Objet);
    },

    async getListActiviteStructure() {
      let response = await this.getStructureActiviteFromStrID(
        parseInt(this.$route.params.structure_id)
      );
      const activitePrincipal = response.filter(
        (res) => res.EST_ActivitePrincipale
      );

      const allActivite = response.filter((res) => !res.EST_ActivitePrincipale);

      this.activitePrincipal =
        activitePrincipal[0]?.LIST_ActiviteType.NomActivite;

      return (this.sectionList = [...allActivite]);
    },
  },
  watch: {
    groupeSelected() {
      this.OptionsAdhesions = [];
      const groupeInfos = this.getGroupeCreneaux.find(
        (groupe) => groupe.id === this.groupeSelected
      );
      this.groupeInfo = { ...groupeInfos };
    },
  },
  props: {
    user: {
      type: Object,
    },
  },
};
</script>

<style scoped>
label {
  margin-bottom: 0 !important;
  font-size: 0.8rem;
}
.assuranceIJ .v-input--selection-controls .v-radio > .v-label {
  font-size: 1rem !important;
}
.horaires__pratique {
  min-height: 50% !important;
}
.Nom__groupe {
  display: flex;
}
.Nom__groupe .field__value-column {
  align-items: start !important;
}
</style>
