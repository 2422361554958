<template>
  <!-- IF YOU WANT YO NOT USE THE DIRECT UPLOAD, JUST REMOVE THE PROPS directUpload, THEN IN YOUR CMPNT PARENT, JUST CALL THE METHODS UPLOAD WITH THE REFS ATTRIBUTE ASYNCRONOUSLY -->

  <v-dialog
    v-model="isOpen"
    @click:outside="closeModal"
    persistent
    content-class="ffme__modal"
  >
    <Loading
      v-if="directUpload"
      :loading="loading"
      :progress="uploadPercentage"
      title="Télécharger"
    />

    <v-card class="p-2">
      <ValidateForm @validated="upload" ref="fileUploadForm">
        <v-card-title class>
          <span class="headline">Téléverser un fichiers</span>
          <v-spacer></v-spacer>
          <!--               <v-btn small rounded class="blue darken-1 text-white font-weight-bold"  @click="$emit('onClose')">Annuler</v-btn>-->
        </v-card-title>

        <v-card-text>
          <v-file-input
            required
            :rules="[
              (v) =>
                !v ||
                v.size < 5000000 ||
                'Le fichier uploader doit faire moins de 5 Mo!',
              (v) => !!v || 'Veuillez selectionner un fichier',
            ]"
            v-model="selectedFile"
            dense
            outlined
            show-size
            @change="onFileChange"
            hint="Taille maximale du fichier à téléverser: 5Mo"
            persistent-hint
          ></v-file-input>
          <SimpleSelect
            v-if="withSaisonSelect"
            @onChange="onSaisonChange"
            :items="selectSaisons"
            v-model="selectedSaison"
            label="Pour la saison"
          ></SimpleSelect>
          <!-- <InputText v-model="fileName" rules="min:1|max:60|required" label="Nom" /> -->

          <div v-if="withExpirationDate">
            <DatePicker
              v-if="withExpirationDate === true"
              v-model="expirationDate"
              @onUpdate="updateDateExpiration"
              rules="required"
              withIcon
              label="Date de fin de validité:"
            />
            <InputText
              v-else
              label="Date de fin de validité:"
              type="date"
              :minDate="$moment().format('YYYY-MM-DD')"
              @input="updateExpirationDate"
              v-model="expirationDate"
              :isProtected="isEndDateDisabled"
              :protectedText="withExpirationDate"
            />
          </div>

          <DatePicker
            v-if="withValidationDate && !isCertifHC"
            v-model="validationDate"
            @onUpdate="updateDateValidation"
            rules="required"
            withIcon
            label="Date de signature:"
          />

          <DatePicker
            v-if="withValidationDate && isCertifHC"
            v-model="validationDate"
            :maxDate="$moment().format('YYYY-MM-DD')"
            @input="updateDateValidation"
            rules="required"
            withIcon
            label="Date de signature:"
            :areErrors="errorSignatureDate"
            :errorsMessages="[
              'La date de signature du contrat doit être antérieure à la date d\'aujourd\'hui',
            ]"
          />
        </v-card-text>

        <v-card-actions class="p-4 pb-4 pt-0">
          <v-spacer></v-spacer>

          <v-btn
            v-if="!directUpload"
            @click.prevent="onSelect"
            :disabled="errorSignatureDate || !canUpload"
            class="btn btn-primary __icon-left"
          >
            <font-awesome-icon icon="upload" />Selectionner
          </v-btn>

          <v-btn
            v-if="directUpload"
            :disabled="!canUpload"
            type="submit"
            class="btn btn-primary __icon-left"
          >
            <font-awesome-icon icon="upload" />Envoyer
          </v-btn>
        </v-card-actions>
      </ValidateForm>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InputText from "@/components/utils/input.vue";
import SimpleSelect from "@/components/utils/select.vue";
import Loading from "@/components/LoadingIndicator.vue";
import ValidateForm from "@/components/utils/form.vue";
import { error_notification } from "@/plugins/notifications.js";

export default {
  components: {
    Loading,
    InputText,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
    ValidateForm,
    SimpleSelect,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    withExpirationDate: {
      type: [Boolean, String],
      default: false,
    },
    withValidationDate: {
      type: [Boolean, String],
      default: false,
    },
    withSaisonSelect: {
      type: [Boolean, String],
      default: false,
    },

    directUpload: {
      type: Boolean,
      default: false,
    },
    isEndDateDisabled: {
      type: Boolean,
      default: true,
    },
    isCertifHC: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loading: true,

    fileName: "",
    expirationDate: "",
    validationDate: "",
    uploadPercentage: 0,
    isPDF: false,
    isImage: false,
    selectedFile: null,
    loadedFile: null,
    loadedFileContent: null,
    selectedSaison: null,
    errorSignatureDate: false,
    canUpload: true,
  }),

  async created() {
    this.loading = false;
    this.selectedSaison = this.currentSaison.id;
    this.$emit("saison-change", this.currentSaison.id);
    await this.getListTypesDocument();
  },

  computed: {
    ...mapGetters("basics", ["getDocument", "getDocumentType"]),
    ...mapGetters("saisons", ["currentSaison", "nextSaison"]),
    ...mapGetters("structure", ["currentStructureId"]),
    selectSaisons() {
      if (this.nextSaison) {
        return [this.currentSaison.id, this.nextSaison.id];
      } else {
        return [this.currentSaison.id];
      }
    },
  },

  methods: {
    ...mapActions("basics", ["insertDocument", "getListTypesDocument"]),

    closeModal() {
      this.$emit("onClose");
    },

    updateDateValidation(date) {
      if (this.isCertifHC) {
        console.log("today: ", this.$moment().format("YYYY-MM-DD"));
        console.log("selected: ", date);
        if (this.$moment().isBefore(date)) {
          this.errorSignatureDate = true;
        } else {
          this.errorSignatureDate = false;
        }
      }
      this.validationDate = date;
    },
    updateDateExpiration(date) {
      this.expirationDate = date;
    },

    onSelect() {
      if (this.withValidationDate) {
        if (!this.validationDate) {
          alert(
            "Vous devez selectionner une date pour selectionner le fichier"
          );
          return;
        }
        this.$emit("onFileSelected", {
          file: this.loadedFile,
          fileDataURL: this.loadedFileContent,
          validationDate: this.validationDate,
        });
      } else {
        this.$emit("onFileSelected", {
          file: this.loadedFile,
          fileDataURL: this.loadedFileContent,
        });
      }
      this.$emit("onClose");
    },

    async upload() {
      console.log("this.selectedFile :>> ", this.selectedFile);
      if (this.selectedFile.size >= 5000000) {
        return error_notification(
          "Le fichier téléversé doit faire moins de 5Mo."
        );
      }
      this.loading = true;
      const hash =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      let payload = {
        image: this.loadedFileContent,
        key: `${this.currentStructureId}_${hash}_${this.fileName}`,
        config: {
          S3_BUCKET: process.env.S3_BUCKET,
        },
      };

      this.uploadPercentage = 0;
      this.axios
        .post(process.env.VUE_APP_FFME_BACK_URL + "/api/media-upload", payload, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
          },
          onUploadProgress: (progressEvent) => {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
        })
        .then((response) => {
          if (
            response.data &&
            response.data.location &&
            response.status &&
            response.status === 200
          ) {
            let freshFile = {
              url: response.data.location,
              filename: this.fileName,
              date: this.validationDate,
              saison: this.selectedSaison,
            };
            this.$emit("onFileUploaded", freshFile);
            return freshFile;
          } else {
            error_notification("Upload unsuccessful");
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          error_notification(error.toString());
        });
    },

    onFileChange(file) {
      if (file === null) {
        return;
      }
      if (this.selectedFile.size >= 5000000) {
        this.canUpload = false;
        return error_notification(
          "Le fichier téléversé doit faire moins de 5Mo."
        );
      }
      this.loadedFile = file;
      if (this.loadedFile.type.indexOf("image/") !== -1) {
        this.isImage = false;
      }
      if (this.loadedFile.type !== "application/pdf") {
        this.isPDF = false;
      }

      this.fileName = this.loadedFile.name;

      // read file data to memory
      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.loadedFileContent = event.target.result;
        };
        reader.readAsDataURL(this.loadedFile);
        this.canUpload = true;

        // reader.readAsBinaryString( this.loadedFile);
      } else {
        alert("Sorry, FileReader API not supported");
        this.fileLoaded = false;
      }
    },

    onSaisonChange(saison) {
      this.$emit("saison-change", saison);
    },

    sanitizeFilename(name) {
      // todo implement filename restrictions
      return name;
    },

    // getExpirationDate() {
    //     if (this.withExpirationDate === false) {
    //         return null
    //     }
    //     return this.withExpirationDate === true
    //         ? this.$moment(this.fieldExpirationDate).tz('Europe/Paris').format()
    //         : this.$moment(translateDate(this.withExpirationDate, true)).tz('Europe/Paris').format()
    // },
    updateExpirationDate() {
      this.$emit("updateExpirationDate", this.expirationDate);
    },
  },
};
</script>
