<template>
  <div>
    <v-row no-gutters>
      <SubBar
        title="Options d'assurance"
        route_name="mes_assurances__dashboard"
      />
    </v-row>
    <v-row class="px-6">
      <v-col cols="12">
        <OptionsAssurances
          :context="{
            ID_Utilisateur: currentUser.id,
            ID_Saison: currentSaison.id,
            ID_Structure: currentStructure
              ? currentStructure.ID_Structure
              : null,
          }"
          @onUpdate="onUpdate"
          :key="optionAssuKey"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OptionsAssurances from "@/components/Assurances/Assurances__List.vue";
import SubBar from "@/components/Common/Common__SubBar.vue";

import { mapGetters } from "vuex";

export default {
  components: { OptionsAssurances, SubBar },

  data: () => ({
    optionAssuKey: 1,
  }),

  methods: {
    onUpdate() {
      this.optionAssuKey += 1;
    },
  },

  computed: {
    ...mapGetters("user", ["currentUser"]),
    ...mapGetters("saisons", ["selectedSaison", "currentSaison"]),
    ...mapGetters("structure", ["currentStructure"]),
  },
};
</script>
