<template>
  <div id="print-facture-1" ref="print-facture-1">
    <div class="container d-flex justify-content-center">
      <img class="habillage" src="~@/assets/FFME_EN_TETE.jpg" alt="" />
      <div class="header">
        <h1 class="d-flex justify-content-center text-transform-uppercase">
          Facture
        </h1>
        <p class="adresses" style="padding-top: 40px;">
          {{ structureName }}
        </p>
        <p class="adresses">
          {{ adresse }}
        </p>
        <p class="adresses">
          {{ codePostal }}
          {{ ville }}
        </p>
      </div>
      <div class="content">
        <div
          style="width: 100%;"
          class="table-contact d-flex justify-content-center"
        >
          <table>
            <tr>
              <th style="text-align: center; width: 30% !important;">Date</th>
              <th style="text-align: center;">Numéro de la facture</th>
              <th style="text-align: center;">Contact</th>
              <th style="text-align: center;">Objet de la facture</th>
            </tr>
            <tr>
              <td style="text-align: center;">{{ date }}</td>
              <td style="text-align: center;">{{ numerofacture }}</td>
              <td style="text-align: center;">info@ffme.fr</td>
              <td style="text-align: center;">-</td>
            </tr>
          </table>
        </div>
        <div
          style="width: 100%; margin-top: 15px;"
          class="table-contact d-flex justify-content-center"
        >
          <table class="table-details">
            <tr>
              <th style="width: 30% !important;">QUANTITE</th>
              <th style="width: 40%;">DESTINATION</th>
              <th>PU</th>
              <th>MONTANT</th>
            </tr>
            <tr>
              <td>1</td>
              <td style="text-align: center; font-weight: bold;">
                {{ designation }}<br /><br />
              </td>
              <td>{{ total }} €</td>
              <td>{{ total }} €</td>
            </tr>
            <tr v-for="(fluxDetail, i) in fluxDetails" :key="i">
              <td>{{ fluxDetail.Quantite }}</td>
              <td>
                {{ fluxDetail.NomFluxFinancierDetail }}
              </td>
              <td>{{ fluxDetail.MontantHT }} €</td>
              <td>{{ fluxDetail.MontantHT }} €</td>
            </tr>
            <tr>
              <td style="border: none;"></td>
              <td
                style="
                  text-align: right;
                  padding-right: 35px;
                  border-right: none;
                  font-weight: bold;
                  font-size: 18px;
                "
              >
                TOTAL
              </td>
              <td style="border-left: none;"></td>
              <td style="text-align: center;">{{ total }} €</td>
            </tr>
            <tr>
              <td style="border: none;"></td>
              <td
                style="
                  text-align: right;
                  border-right: none;
                  font-weight: bold;
                  font-size: 18px;
                "
              >
                TOTAL A PAYER
              </td>
              <td style="border-left: none;"></td>
              <td style="text-align: center;">{{ total }} €</td>
            </tr>
          </table>
        </div>
        <p class="table-footer">TVA NON APPLICABLE, art 293B du CGI</p>
        <div class="law-container" v-if="canPrint">
          <p>
            En application de la loi, cette facture est payable à l’Échéance
            indiquée ci-dessous sans escompte pour paiement anticipé
          </p>
          <br />
          <p>Taux de pénalités de retard : 3 fois le taux d’intérêt légal</p>
          <br />
          <p>
            Indemnité forfaitaire de recouvrement en cas de retard de paiement
            40€
          </p>
        </div>

        <p
          style="
            font-size: 16px;
            width: fit-content;
            margin-top: 4vh;
            margin-left: 30px;
          "
          v-if="canPrint"
        >
          En votre aimable règlement par virement bancaire à notre compte
        </p>
        <p
          style="
            font-size: 16px;
            width: fit-content;
            margin-top: 4vh;
            margin-left: 30px;
          "
          v-if="isMa"
        >
          Facture acquittée par carte bancaire
        </p>
        <div v-if="canPrint" class="reglement_reception">
          <v-row>
            <v-col cols="6" lg="6" sm="12">
              <img
                src="~@/assets/attestattion/reglement_reception.png"
                alt=""
              />
            </v-col>
            <v-col
              cols="6"
              lg="6"
              sm="12"
              style="padding-top: 3vh; padding-right: 40px;"
            >
              <div class="footer">
                <p>DE FACTURE</p>
              </div>
              <table class="table-details">
                <tr>
                  <th
                    style="
                      color: red;
                      border-right: none;
                      text-align: right;
                      padding-left: 90px;
                      font-size: 18px;
                    "
                  >
                    REGLEMENT
                  </th>
                  <th
                    style="
                      border-left: none;
                      color: red;
                      padding-right: 110px;
                      font-size: 18px;
                    "
                  >
                    A RECEPTION
                  </th>
                </tr>
                <tr>
                  <td>Références à rappeler :</td>
                  <td>{{ numerofacture }}</td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
  data: () => ({
    data: Object,
    date: Object,
    designation: "",
    fluxDetails: [],
    total: 0,
    numerofacture: "",
    canPrint: false,
    isMa: false,
    adresse: "",
    codePostal: "",
    ville: "",
    structureName: "",
  }),
  props: {
    print: {
      type: Object,
    },
  },

  methods: {
    printFacture(item, currentSaison) {
      console.log("item printFacture", item);
      console.log("currentSaison printFacture", currentSaison);
      this.data = item.data
        ? item.data.insert_MKP_FluxFinancier.returning[0]
        : item;

      const saison = currentSaison
        ? currentSaison
        : this.data.FEDE_Saison?.NomSaisonShort.split("-")[1];

      this.date = this.$moment(this.data.Z_DateCreation).format("MM/DD/YYYY");
      // this.date = this.data.Z_DateCreation;
      let fluxdetails = this.data.MKP_FluxFinancierDetail;
      if (fluxdetails.length > 1) this.fluxDetails = fluxdetails;
      this.total = this.data.MontantHT;
      this.numerofacture = saison + "-FFME-" + this.data.id;

      if (this.data.ID_CategoryFlux === 4) this.canPrint = true;
      else if (this.data.ID_CategoryFlux === 5) this.canPrint = false;
      if (
        this.data?.NomFluxFinancier.includes("Affiliation") ||
        this.data?.NomFluxFinancier.includes("AFFILIATION")
      ) {
        this.canPrint = false;
        this.isMa = true;
        this.structureName = this.data.STR_Structure.NomStructure;
        this.codePostal =
          this.data.STR_Structure.NomCorrespondant.CT_Prenom +
          " " +
          this.data.STR_Structure.NomCorrespondant.CT_Nom;
        this.ville = "";
        this.designation = this.data.NomFluxFinancier;
      } else {
        this.adresse = this.data.STR_Structure.ADR_Adresse?.Adresse1;
        this.structureName = this.data.STR_Structure.NomStructure;
        this.codePostal = this.data.STR_Structure.ADR_Adresse?.CodePostal;
        this.ville = this.data.STR_Structure.ADR_Adresse?.Ville;
        this.designation = this.data.NomFluxFinancier;
      }
      const name = "Facture " + this.data.id + "-" + this.date;

      this.$nextTick(() => {
        this.$refs["print-facture-1"].style.display = "block";
        let source = document.querySelector("#print-facture-1");
        html2canvas(source, {
          letterRendering: 1,
          scale: 2,
          logging: true,
          useCORS: true,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg");
          const pdf = new jsPDF({
            orientation: "portrait",
          });
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth + 4, pdfHeight + 90);
          pdf.save(name);
          this.$refs["print-facture-1"].style.display = "none";
        });
      });
    },
  },
};
</script>

<style lang="scss">
#print-facture-1 {
  width: 980px;
  height: 100vh;
  position: absolute;
  z-index: -3;
  top: 0;
  left: 0;
  display: none;
  font-family: Roboto !important;
  font-family: normal !important;
  font-size: 14px !important;

  .container {
    position: relative;
    width: 1000px;
    height: 1000px;
  }
  .habillage {
    width: 100%;
  }
  .header {
    width: 100%;
    position: absolute;
    top: 10vh;
    h1 {
      font-size: 68px;
    }
    .adresses {
      display: flex;
      justify-content: flex-end;
      padding-right: 250px;
      padding-top: 7px;
      font-size: 16px;
    }
  }
  .table-contact {
    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
      width: 900px;
      font-size: 16px;
    }
  }
  .table-details {
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
      width: 900px;
      font-size: 16px;
      margin-left: 10px;
      text-align: center;
    }
  }
  .content {
    top: 40vh;
    position: absolute;
  }
  .table-footer {
    // background-color: yellow;
    // display: flex;
    // justify-content: center;
    text-align: center;
    width: fit-content;
    font-size: 14px;
    color: black;
    margin-left: 40%;
  }
  .law-container {
    margin-top: 3vh;
    // position: relative;
    p {
      // background-color: yellow;
      font-size: 16px;
      width: fit-content;
      margin-bottom: 0;
      padding-bottom: 0;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  .reglement_reception {
    // width: 50%;
    // position: relative;
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    margin-right: 30px;
    height: 75px;
  }
  .footer {
    position: absolute;
    // bottom: 0;
    // right: 200px;
    left: 350px;
    align-self: end;
    width: 140px;
    border: 3px solid rgb(68, 109, 68);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffff;
    // margin: 20px;
    p {
      background-color: yellow;
      font-size: 16px;
      width: fit-content;
      margin-bottom: 0;
      padding-bottom: 0;
      color: red;
      font-weight: bold;
    }
  }
}
</style>
