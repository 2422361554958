<template>
  <div class="container">
    <v-skeleton-loader
      v-if="loading === true"
      :loading="loading"
      v-bind="attrs"
      type="card-avatar, actions"
      class="mx-auto"
      max-width="300"
    ></v-skeleton-loader>

    <div class="row" v-else>
      <div class="col-md-6 mx-auto mt-5">
        <div class="payment">
          <div class="payment_header">
            <div class="check">
              <v-icon>check</v-icon>
            </div>
          </div>
          <div class="content">
            <h1 class="mb-5">Paiement effectué avec succès.</h1>
            <p>Date de la transaction : {{ LemonwayObject.date }}</p>
            <p>
              Numéro d'autorisation : {{ LemonwayObject.transactionNumber }}
            </p>
            <p>3-D Secure : {{ LemonwayObject.TroisDS }}</p>
            <p>Type de carte : {{ LemonwayObject.cardType }}</p>
            <p>Numéro de carte : {{ LemonwayObject.cardNumber }}</p>
            <p>Date d'expiration : {{ LemonwayObject.expirationDate }}</p>
            <p>Référence FFME : {{ LemonwayObject.reference }}</p>
            <p>
              Montant de la transaction :
              {{ LemonwayObject.transactionAmount }} €
            </p>
            <v-btn class="btn btn-primary my-5" @click.prevent="homePage()">
              {{ buttonName }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- <PrintFacture ref="facture" :print="print"></PrintFacture> -->
  </div>
</template>

<script>
import { send_email } from "@/plugins/utils";
import { mapGetters, mapActions } from "vuex";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import {
  formatFluxFromAffiliation,
  insertFlux
} from "@/plugins/fluxService";

export default {
  components: {
    // PrintFacture,
  },
  data: () => ({
    moneyinsDetail: null,
    loading: true,
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
    dataOptionAssurance: {},
    dataAssuranceVoyage: {},
    dataLicenceDematerialise: {},
    dataLMAInscription: {},
    dataLicenceHorsClub: {},
    LemonwayObject: {
      cardType: "",
      transactionAmount: "",
      reference: "",
      cardNumber: "",
      transactionNumber: "",
    },
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
    idCurrentLemonway: null,
  }),

  // beforeMount() {
  //   this.CallSuccessPage();
  // },

  async created() {
    await this.CallMoneyIns();
  },

  computed: {
    ...mapGetters("saisons", ["currentSaisonId", "saisons"]),

    buttonName() {
      return "Revenir sur myFFME";
    },
  },

  methods: {
    ...mapActions("voyage", ["createVoyageUser"]),
    ...mapActions("utilisateurs", [
      "createAuthUser",
      "getLemonwayPaiement",
      "updateLemonwayStatus",
      "addOptionUserAssurance",
      "createFluxFinancierDetail",
      "getUtilisateursTempById",
      "insertOptionAssurance",
      "insertUserLicence",
      "updateUser",
      "saveTempUser",
      "getUtilisateur",
      "updateUtilisateurCoordonnees",
    ]),
    ...mapActions("structure", [
      "getTempAffiliation",
      "getLastStructure",
      "createNewStructure",
      "insertPrimoAffiliation",
      "addUserToStructureOnPrimoAffi",
      "updateLicenceUtilisateurStructureList",
      "updateNewLicenceNumero",
      "getLastAffiliationStructure",
      "insertStructureAffiliation",
    ]),
    ...mapActions("basics", ["insertDocument", "getTarifsFFME"]),
    ...mapActions("tarifs", ["getTarifsLicence_Assurances"]),
    ...mapActions("utilisateurs", ["updateLastLicenceNumber"]),

    async createOptionAssurance() {
      // creation option assurance
      await this.addOptionUserAssurance({
        AssurancesIDS: this.dataOptionAssurance.AssurancesIDS,
        data: this.dataOptionAssurance.data,
      });
      // NEW FLUX BACK FFME
      await insertFlux(this.dataOptionAssurance.newFlux);

      const insertedFlux = await this.createFluxFinancierDetail({
        flux: this.dataOptionAssurance.fluxPrincipalUser,
      });
      let payload = {
        id: this.idCurrentLemonway,
        status: true,
      };
      console.log("payload after payment", payload);
      await this.updateLemonwayStatus(payload);

      await this.sendEmail();

      // await this.printFacture(insertedFlux, this.currentSaisonId);
    },

    async createAssuranceVoyage() {
      await this.createVoyageUser(this.dataAssuranceVoyage.data);
      const insertedFlux = await this.createFluxFinancierDetail({
        flux: [
          { ...this.dataAssuranceVoyage.fluxPrincipalUser },
          {
            ID_FluxFinancierDebit: 0,
            ID_Saison: this.dataAssuranceVoyage.fluxPrincipalUser.ID_Saison,
            ID_Structure: this.dataAssuranceVoyage.fluxPrincipalUser
              .ID_Structure,
            ID_Traitement: 0,
            ID_Utilisateur: this.dataAssuranceVoyage.fluxPrincipalUser
              .ID_Utilisateur,
            MontantHT: 0.52,
            MontantTTC: 0.52,
            MontantTVA: 0,
            NomFluxFinancier: `Commission Lemonway pour la déclaration de voyage (N°${this.dataAssuranceVoyage.lastDossierNumber})`,
            EST_Reglement: false,
            ID_CategoryFlux: 5,
            EST_Debit: false,
          },
        ],
      });
      // NEW FLUX BACK FFME
      await insertFlux(this.dataAssuranceVoyage.newFlux);
      let payload = {
        id: this.idCurrentLemonway,
        status: true,
      };
      await this.updateLemonwayStatus(payload);
      await this.sendEmail();
      // this.printFacture(insertedFlux, this.currentSaisonId);
    },

    async CallSuccessPage() {
      try {
        const fetch = require("node-fetch");
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
        };

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/validate`,
          {
            method: "get",
            headers: headers,
          }
        );
        const success = await response.json();
        console.log("CallSuccessPage success", success);
        return { ok: true };
      } catch (error) {
        console.log("erro r:", error);
        return { ok: false };
      }
    },
    // async printFacture(item, season) {
    //   console.log('lemonway success print facture');
    //   await this.$refs.facture.printFacture(item, season);
    // },

    async CallMoneyIns() {
      try {
        const fetch = require("node-fetch");
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
        };

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/moneyins`,
          {
            method: "get",
            headers: headers,
          }
        );
        let responseLemonWay = await response.json();
        let lemonwayValue = responseLemonWay.transactions.value[0];
        console.log("lemonwayValue ===>", lemonwayValue);
        this.LemonwayObject.cardType = lemonwayValue.card.type;
        this.LemonwayObject.transactionAmount =
          Number(lemonwayValue.creditAmount) / 100;
        const timestamp = lemonwayValue.date;
        const milliseconds = timestamp * 1000;
        const dateObject = new Date(milliseconds);
        this.LemonwayObject.date = dateObject.toLocaleString("fr-FR");

        this.LemonwayObject.cardNumber = lemonwayValue.card.maskedNumber;
        this.LemonwayObject.transactionNumber =
          lemonwayValue.card.authorizationNumber;
        lemonwayValue.card.is3DS === true
          ? (this.LemonwayObject.TroisDS = "OUI")
          : (this.LemonwayObject.TroisDS = "NON");

        this.LemonwayObject.expirationDate = lemonwayValue.card?.expiration
          ?.toString()
          .split("/")
          .reverse()
          .join("/");
        responseLemonWay = lemonwayValue.comment.split(":");
        this.moneyinsDetail = {
          comment: responseLemonWay[0],
          idLemonWay: +responseLemonWay[1],
        };
        console.log("moneyinsDetail ===>", this.moneyinsDetail);
        if (this.moneyinsDetail.comment === "OptionAssurance") {
          console.log(`atooo OptionAssurance===>`);
          const resLemonway = await this.getLemonwayPaiement({
            id: this.moneyinsDetail.idLemonWay,
          });
          this.idCurrentLemonway = resLemonway[0]?.id;
          console.log(`resLemonway ===>`, resLemonway);

          this.dataOptionAssurance = JSON.parse(resLemonway[0].data);
          console.log(
            `this.dataOptionAssurance ===>`,
            this.dataOptionAssurance
          );

          this.LemonwayObject.reference = this.dataOptionAssurance.context;
          // paiement
          if (resLemonway[0]?.status === false) {
            await this.createOptionAssurance();
          } else {
            error_notification("Paiement déjà réalisé");
          }
        } else if (this.moneyinsDetail.comment === "AssuranceVoyage") {
          console.log(`atooo AssuranceVoyage===>`);
          const resLemonway = await this.getLemonwayPaiement({
            id: this.moneyinsDetail.idLemonWay,
          });
          console.log(`resLemonway Voyage ===>`, resLemonway);
          this.idCurrentLemonway = resLemonway[0]?.id;

          this.dataAssuranceVoyage = JSON.parse(resLemonway[0].data);
          this.LemonwayObject.reference = this.dataAssuranceVoyage.context;

          // paiement
          if (resLemonway[0]?.status === false) {
            await this.createAssuranceVoyage();
          } else {
            error_notification("Paiement déjà réalisé");
          }
        } else if (this.moneyinsDetail.comment === "LicenceDematerialise") {
          const resLemonway = await this.getUtilisateursTempById({
            id: this.moneyinsDetail.idLemonWay,
          });

          console.log(`resLemonway ===>`, resLemonway);

          if (resLemonway.status === 1) {
            this.dataLicenceDematerialise = resLemonway[0];
            const allPaymentInformation = JSON.parse(
              resLemonway[0].PaymentInformation
            );
            console.log(`allPaymentInformation ===>`, allPaymentInformation);
            this.LemonwayObject.reference = allPaymentInformation?.reference;
            console.log(
              `resLemonway dataLicenceDematerialise ===>`,
              this.dataLicenceDematerialise
            );

            // update status of userTemp to payment valid

            //CREATE USER COGNITO
            let payloadUserCognito = {
              user: {
                CT_Email: this.dataLicenceDematerialise.CT_Email,
                CT_Prenom: this.dataLicenceDematerialise.CT_Prenom,
                CT_Nom: this.dataLicenceDematerialise.CT_Nom,
              },
              ID_Admin: "0c3d061f-d338-4676-856e-62d18924ea62",
              ID_Structure: this.dataLicenceDematerialise.ID_Structure,
            };

            try {
              let userID = await this.createAuthUser(payloadUserCognito);
              if (typeof userID === "object") {
                return error_notification(userID.message);
              }

              //create licence utilisateur
              const licenceInfo = JSON.parse(
                this.dataLicenceDematerialise.UTI_Licence
              );
              const licenceUser = {
                ID_Structure: this.dataLicenceDematerialise?.ID_Structure,
                ID_TypeLicence:
                  licenceInfo?.ID_TypeLicence?.LIST_TarifCategorie
                    ?.ID_TypeLicence,
                ID_Saison: licenceInfo?.ID_TypeLicence?.ID_Saison,
                DateLicenceValidation: this.$moment(new Date()).toISOString(),
                ID_EtapeLicence: 2, //valide,
                ID_Utilisateur: userID,
              };
              const licence = await this.insertUserLicence([
                { ...licenceUser },
              ]);

              const user = {
                id: this.dataLicenceDematerialise.id,
                PaymentToken: this.LemonwayObject.transactionNumber,
                Status: 3,
                EST_Licencie: true,
              };
              await this.saveTempUser({ user });
              // create link to structure
              const userStructurePayload = {
                ID_Structure: this.dataLicenceDematerialise.ID_Structure,
                EST_Actif: true,
                EST_RoleFederal: false,
                ID_Utilisateur: userID,
                DescriptionFonction: "Licencie",
                ID_LastLicence: licence[0].id,
              };
              await this.addUserToStructureOnPrimoAffi({
                user: [userStructurePayload],
              });

              // // create option assurance

              const allOptionAssurance = JSON.parse(
                this.dataLicenceDematerialise.UTI_LicenceAssurances
              );
              const optionAssurance = allOptionAssurance.filter(
                (option) => option
              );

              const optionAssurancePayload = optionAssurance.map((option) => ({
                ID_Licence: licence[0].id,
                ID_TypeAssurance: option,
                ID_Saison: licenceInfo?.ID_TypeLicence?.ID_Saison,
                ID_Utilisateur: userID,
              }));
              await this.insertOptionAssurance(optionAssurancePayload);
              // update user info from temp
              const adressTemp = JSON.parse(
                this.dataLicenceDematerialise.ADR_Adresse
              );
              const userAddress = {
                ID_Pays: adressTemp.ID_Pays.id,
                ID_Structure: this.dataLicenceDematerialise.ID_Structure,
                Adresse1: adressTemp.Adresse1,
                Adresse2: adressTemp.Adresse2,
                CodePostal: adressTemp.CodePostal,
                Ville: adressTemp.CodePostal,
                Etat: adressTemp.ID_Pays.NomPays,
              };
              const userInfo = {
                id: userID,
                CT_Prenom: this.dataLicenceDematerialise.CT_Prenom,
                CT_Nom: this.dataLicenceDematerialise.CT_Nom,
                ID_PaysNationalite: this.dataLicenceDematerialise
                  .ID_PaysNationalite,
                CT_EST_Civilite: this.dataLicenceDematerialise.EST_Civilite,
                DN_DateNaissance: this.$moment(
                  new Date(this.dataLicenceDematerialise.DN_DateNaissance)
                ).toISOString(),
                ADR_Adresse: {
                  data: { ...userAddress },
                },
                LicenceNumero: licence[0].id,
                CT_TelephoneFixe: this.dataLicenceDematerialise
                  .CT_TelephoneFixe,
                CT_TelephoneMobile: this.dataLicenceDematerialise
                  .CT_TelephoneMobile,
              };
              await this.updateUser({ user: [{ ...userInfo }] });
              // create certificat medical document
              const documentPayload = {
                ID_Structure: this.dataLicenceDematerialise?.ID_Structure,
                EST_Actif: true,
                EST_DocumentValide: true,
                categorie: "Certificat medical",
                ID_Saison: licenceInfo?.ID_TypeLicence?.ID_Saison,
                ID_Utilisateur: userID,
                DocumentType: this.dataLicenceDematerialise
                  ?.ID_CertificatMedical,
                CheminDocument:
                  this.dataLicenceDematerialise?.DOC_DocumentsTemp &&
                  this.dataLicenceDematerialise?.DOC_DocumentsTemp[0]
                    ?.CheminDocument,
                NomDocument: this.dataLicenceDematerialise?.DOC_DocumentsTemp[0]
                  ?.NomDocument,
                DateFinValiditeDocument: this.dataLicenceDematerialise
                  ?.DOC_DocumentsTemp[0]?.DateFinValiditeDocument
              };
              console.log("this.documentPayload :>> ", this.documentPayload);
              await this.insertDocument(documentPayload);

              // create MKP flux financier
              const fluxFinancierPayload = {
                ID_Traitement: 0,
                ID_Saison: this.currentSaisonId || 2022,
                ID_Structure: response.ID_Structure,
                EST_Reglement: true,
                MontantHT: this.LemonwayObject.transactionAmount,
                MontantTVA: 0,
                ID_Utilisateur: userID,
                NomFluxFinancier: `Adhésion dématérialisée ${this.dataLicenceDematerialise.CT_Prenom} ${this.dataLicenceDematerialise.CT_Nom}- ${this.currentSaisonId}`,
                ID_FluxFinancierDebit: 0,
                ID_CategoryFlux: 5,
              };
              const totalInitial = +(
                (this.LemonwayObject.transactionAmount - 0.16) /
                1.0072
              ).toFixed(2);
              const commissionLemonway =
                this.LemonwayObject.transactionAmount - totalInitial;
              const fluxCommissionLemonway = {
                ID_Traitement: 0,
                ID_Saison: this.currentSaisonId || 2022,
                ID_Structure: response.ID_Structure,
                EST_Reglement: true,
                MontantHT: commissionLemonway,
                MontantTVA: 0,
                ID_Utilisateur: userID,
                NomFluxFinancier: `Commission lemonway pour adhésion dématérialisée ${this.dataLicenceDematerialise.CT_Prenom} ${this.dataLicenceDematerialise.CT_Nom}- ${this.currentSaisonId}`,
                ID_FluxFinancierDebit: 0,
                ID_CategoryFlux: 5,
                EST_Debit: false,
              };

              await this.createFluxFinancierDetail({
                flux: [fluxFinancierPayload, fluxCommissionLemonway],
              });
              this.idCurrentLemonway = resLemonway[0]?.id;
              let payload = {
                id: this.idCurrentLemonway,
                status: true,
              };
              await this.updateLemonwayStatus(payload);
              success_notification("Adhésion enregistrée avec succès");
            } catch (e) {
              console.log("e :>> ", e);
              error_notification("");
            }

            //send_email
            await this.sendEmail("LicenceDematerialise");
          } else {
            error_notification("Paiement déjà réalisé");
          }
        } else if (this.moneyinsDetail.comment === "réaffiliation") {
          await this.reaffiliationMA();
        } else if (this.moneyinsDetail.comment === "MAInscription") {
          const lastStructure = await this.getLastStructure();
          const resLemonway = await this.getLemonwayPaiement({
            id: this.moneyinsDetail.idLemonWay,
          });
          const tempAffi = await this.getTempAffiliation({
            id: JSON.parse(resLemonway[0].data).id,
          });
          if (resLemonway[0]?.status === false) {
            const response = tempAffi[0];
            this.dataLMAInscription = JSON.parse(tempAffi[0].UTI_Utilisateur);
            this.LemonwayObject.reference = `Affiliation saison ${tempAffi[0]?.ID_Premiere_Saison}`;
            //CREATE USER COGNITO
            let payloadUserCognito = {
              user: {
                CT_Email: this.dataLMAInscription.representantEmail,
                CT_Prenom: this.dataLMAInscription.representantLastName,
                CT_Nom: this.dataLMAInscription.representantFirstName,
              },
              ID_Admin: "0c3d061f-d338-4676-856e-62d18924ea62",
              ID_Structure: "1318",
            };

            try {
              let userID = await this.createAuthUser(payloadUserCognito);
              const currentLicenceNumero = await this.getUtilisateur({ID_Utilisateur: userID})
              console.log(`userID MAInscription===>`, userID);
              if (typeof userID === "object") {
                return error_notification(userID.message);
              }

              const userPayload = {
                CT_Email: this.dataLMAInscription.representantEmail,
                CT_Prenom: this.dataLMAInscription.representantLastName,
                CT_Nom: this.dataLMAInscription.representantFirstName,
                id: userID,
                LicenceNumero: currentLicenceNumero.LicenceNumero,
              };
              await this.updateUser({user: {...userPayload}});
              // create structure
              const structurePayload = {
                id: +lastStructure.id + 1, // incrementation
                NomStructure: response?.STR_NomStructure,
                NomAcronyme: response?.NomStructureComplement,
                INFO_Presentation: response?.STR_InfoPresentation,
                JUR_Siret: response?.JUR_Siret,
                ChiffreAffaire: +response?.ChiffreAffaire,
                ID_TypeStructure: response?.ID_TypeStructure,
                ID_NomCorrespondant: userID,
              };
              const structureID = await this.createNewStructure({
                structure: [structurePayload],
              });
              console.log("structureID cree :>> ", structureID);
              // create link to structure
              const userStructurePayload = {
                ID_Fonction: 1, //President,
                ID_Structure: structureID,
                EST_Actif: true,
                EST_RoleFederal: true,
                ID_Utilisateur: userID,
                DescriptionFonction: "Correspondant membre associe",
                ID_Role: 22,
              };
              await this.addUserToStructureOnPrimoAffi({
                user: [userStructurePayload],
              });
              // primoaffiliation
              const primoAffiliationMAPayload = {
                ID_EtapeAffiliation: 3,
                ID_Saison: this.currentSaisonId || 2022,
                FIN_ChiffreAffaire: response?.ChiffreAffaire,
                ID_Structure: structureID,
                ID_Premiere_Saison: response.ID_Premiere_Saison,
              };
              await this.insertPrimoAffiliation({
                affiliation: [primoAffiliationMAPayload],
              });

              // create MKP flux financier
              const fluxFinancierPayload = {
                ID_Traitement: 0,
                ID_Saison: this.currentSaisonId || 2022,
                ID_Structure: structureID,
                EST_Reglement: true,
                MontantHT: 125,
                MontantTTC: 125,
                MontantTVA: 0,
                ID_Utilisateur: userID,
                NomFluxFinancier: `Affiliation ${response?.STR_NomStructure} - Saison ${this.currentSaisonId}`,
                ID_FluxFinancierDebit: 0,
                ID_CategoryFlux: 5,
                EST_Debit: true,
              };
              const fluxCommissionLemonway = {
                ID_Traitement: 0,
                ID_Saison: this.currentSaisonId || 2022,
                ID_Structure: structureID,
                EST_Reglement: true,
                MontantHT: 1.06,
                MontantTTC: 1.06,
                MontantTVA: 0,
                ID_Utilisateur: userID,
                NomFluxFinancier: `Commission lemonway pour affiliation ${response?.STR_NomStructure} - Saison ${this.currentSaisonId}`,
                ID_FluxFinancierDebit: 0,
                ID_CategoryFlux: 5,
                EST_Debit: true,
              };
              await this.createFluxFinancierDetail({
                flux: [
                  {...fluxFinancierPayload},
                  {...fluxCommissionLemonway},
                ],
              });
              // NEW FLUX BACK FFME
              await insertFlux(formatFluxFromAffiliation({
                structireId: structureID,
                seasonId: this.currentSaisonId,
                commission: true
              }));
              await this.updateLemonwayStatus({
                id: resLemonway[0]?.id,
                status: true,
              });
              success_notification("Paiement effectué");
              // this.$emit("onCreated", { id: userID, ...this.data });
            } catch (e) {
              console.log("e :>> ", e);
              error_notification();
            }
          } else {
            return error_notification("Paiement déjà réalisé");
          }
        } else if (this.moneyinsDetail.comment === "LicenceHorsCLub") {
          const resUserTemp = await this.getUtilisateursTempById({
            id: this.moneyinsDetail.idLemonWay,
          });
          const allPaymentInformation = JSON.parse(
              resUserTemp[0].PaymentInformation
          );
          this.LemonwayObject.reference = allPaymentInformation?.reference;
        } else {
          success_notification("Paiement effectué");
        }

        this.loading = false;
        console.log("CallMoneyIns success");

        return { ok: true };
      } catch (error) {
        console.log("erro r:", error);
        return { ok: false };
      }
    },

    // reaffiliation MA
    async reaffiliationMA() {
      this.LemonwayObject.reference = `Affiliation saison ${this.currentSaisonId}`;
      const resLemonway = await this.getLemonwayPaiement({
        id: this.moneyinsDetail.idLemonWay,
      });
      const value = resLemonway[0];
      const dataValue = JSON.parse(value.data);
      if (value?.status) {
        return error_notification("Paiement déjà réalisé");
      }

      let payload = {
        ID_Structure: +dataValue.data,
        ID_Saison: this.currentSaisonId,
        ID_ETAPE_Affiliation: 3,
      };
      const lastAffi = await this.getLastAffiliationStructure({
        ID_Structure: +dataValue.data,
      });
      const hasAffiliationThisSeason = lastAffi.find(
        (affiliation) => affiliation.ID_Saison === this.currentSaisonId
      );
      if (hasAffiliationThisSeason) {
        console.log("tafidtr :>> ", hasAffiliationThisSeason);
        payload.id = hasAffiliationThisSeason?.id;
      }
      // reaffiliation && flux financier
      await Promise.all[
        (this.insertStructureAffiliation(payload),
        this.createFluxFinancierDetail({
          flux: dataValue.fluxPrincipalUser,
        }))
      ];
      // NEW FLUX BACK FFME
      await insertFlux(formatFluxFromAffiliation({
        structureId: +dataValue.data,
        seasonId: this.currentSaisonId,
        commission: true
      }));
      await this.updateLemonwayStatus({
        id: resLemonway[0]?.id,
        status: true,
      });
      success_notification("Affiliation effectuée avec succès.");
    },

    async sendEmail(context) {
      let email = "";
      let userID = "";
      let reference = "";
      let nom = "";
      let prenom = "";
      if (this.moneyinsDetail?.comment === "OptionAssurance") {
        email = this.dataOptionAssurance.currentUser.CT_Email;
        userID = "0c3d061f-d338-4676-856e-62d18924ea62";
        reference = this.dataOptionAssurance.context;
        nom = this.dataOptionAssurance.currentUser.CT_Nom;
        prenom = this.dataOptionAssurance.currentUser.CT_Prenom;
      }
      if (this.moneyinsDetail?.comment === "AssuranceVoyage") {
        email = this.dataAssuranceVoyage.currentUser.CT_Email;
        userID = "0c3d061f-d338-4676-856e-62d18924ea62";
        reference = this.dataAssuranceVoyage.context;
        nom = this.dataAssuranceVoyage.CT_Nom;
        prenom = this.dataAssuranceVoyage.CT_Prenom;
      }
      if (this.moneyinsDetail?.comment === "LicenceDematerialise") {
        email = this.dataLicenceDematerialise.CT_Email;
        userID = "0c3d061f-d338-4676-856e-62d18924ea62";
        reference = this.LemonwayObject.reference;
        nom = this.dataLicenceDematerialise.CT_Nom;
        prenom = this.dataLicenceDematerialise.CT_Prenom;
      }
      let payload = {
        Email: email,
        Context: "lemonwayResponse",
        User: userID,
        CreditAmount: this.LemonwayObject.transactionAmount.toString(),
        CardNumber: this.LemonwayObject.cardNumber,
        CardType: this.LemonwayObject.cardType,
        StartDate: this.LemonwayObject.date,
        TransactionID: this.LemonwayObject.transactionNumber,
        Secure3D: this.LemonwayObject.TroisDS,
        CardExpiration: this.LemonwayObject.expirationDate,
        Comment: reference,
        Nom: nom,
        Prenom: prenom,
      };
      console.log("payload email", payload);

      const response = await send_email(payload);
      console.log("payload response", response);
      response.ok === true
        ? success_notification(
            context === "LicenceDematerialise"
              ? `Un récapitulatif de paiement vous a été envoyé par email à l'adresse ${email}`
              : `Un email vous a été envoyé sur ${email}`
          )
        : error_notification("une erreur est survenue");
    },

    homePage() {
      // this.$router.replace({ name: 'Home' });
      location.replace(`${process.env.VUE_APP_FRONT_URL}`);
    },
  },
};
</script>

<style scoped>
body {
  background: #f2f2f2;
  border-color: #002755 !important;
}

.payment {
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  background: #fff;
}
.payment_header {
  background: #002755 !important;
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}

.check {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
}

.check i {
  vertical-align: middle;
  line-height: 50px;
  font-size: 30px;
}

.content {
  text-align: center;
}

.content h1 {
  font-size: 25px;
  padding-top: 25px;
}

.content a {
  width: 200px;
  height: 100px;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px;
  background: rgba(255, 102, 0, 1);
  transition: all ease-in-out 0.3s;
}

.content a:hover {
  text-decoration: none;
  background: #000;
}
.content p {
  /* font-style: italic; */
  text-align: left;
  margin-left: 70px;
}
.container {
  height: 100vh !important;
}
</style>
