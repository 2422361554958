<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :name="getName">
    <div v-if="noLine">
      <v-combobox
        v-model="currentValue"
        :items="items"
        :id="getId"
        ref="select"
        class="input__select"
        height="20px"
        :no-filter="no_filter"
        hide-details="true"
        :multiple="multiple"
        :item-text="item_text"
        :item-value="item_value"
        :placeholder="placeholder"
        v-on:update:search-input="searchInput"
        @keypress="checkInput($event)"
        v-on:change="selectedChanged"
        flat
        dense
        solo
        :disabled="disabled"
      ></v-combobox>
      <span class="error-message">{{ errors[0] }}</span>
    </div>

    <v-row align-content="center" v-else>
      <v-col cols="12" md="3" v-if="label" class="label_column">
        <label for>{{ label }}{{ isRequired && !disabled ? "*" : "" }}</label>
      </v-col>

      <v-col cols="12" md="9" style="flex: 1">
        <v-combobox
          v-model="currentValue"
          :items="items"
          :id="getId"
          ref="select"
          class="input__select"
          height="20px"
          :no-filter="no_filter"
          hide-details="true"
          :multiple="multiple"
          :item-text="item_text"
          :item-value="item_value"
          :placeholder="placeholder"
          v-on:update:search-input="searchInput"
          v-on:change="selectedChanged"
          @keypress="checkInput($event)"
          flat
          dense
          solo
          :disabled="disabled"
        ></v-combobox>
        <span class="error-message">{{ errors[0] }}</span>
      </v-col>
    </v-row>
  </validation-provider>
</template>

<script>
import { string_to_slug } from "@/plugins/utils.js";

export default {
  data: () => ({
    currentValue: "",
    otherValue: "",
  }),

  mounted() {
    this.currentValue = this.value;
  },

  methods: {
    getClass(errors) {
      let futurClasses = "";
      if (errors[0]) {
        futurClasses += " is-invalid ";
      }

      futurClasses += this.custom_class;
      return futurClasses;
    },

    /**
     * loop throught the update:search-input event
     */
    searchInput(data) {
      this.$emit("update:search-input", data);
    },
    /**
     * loop throught the change event
     */
    selectedChanged(data) {
      this.$emit("change", data);
    },

    checkInput: function(evt) {
      if (this.type == "number") {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
  },

  computed: {
    isRequired() {
      return this.rules.indexOf("required") !== -1;
    },

    getId() {
      if (this.id) {
        return this.id;
      }
      return (
        "input_" +
        this.type +
        "_" +
        Math.floor(Math.random() * 100) +
        "_" +
        string_to_slug(this.getName)
      );
    },

    getName() {
      return this.label.toLowerCase();
    },
  },

  watch: {
    currentValue(val) {
      // allows us to use v-model on our input.

      this.$emit("input", val);
    },
    value(val) {
      this.currentValue = val;
    },

    otherValue(value) {
      this.currentValue = value;
      this.$refs.select.initialValue = value;
    },
  },

  props: {
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    rules: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number, Array, Object],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
    },
    no_filter: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    item_text: {
      type: [String, Object, Function],
      default: "",
    },
    item_value: {
      type: String,
      default: "value",
    },
    noLine: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}

.v-input__slot {
  max-width: 400px !important;
  padding: 0 !important;

  .v-select__slot {
    border: 1px solid #dfe5f9;
    .v-select__selections {
      padding: 4px 5px;
      .v-select__selection--comma {
        font-weight: 200;
        font-size: 0.8rem;
      }
    }
    input {
      border: none;
      padding: 4px 5px;
      font-weight: 200;
      font-size: 0.8rem;
      max-width: 400px;

      &.is-invalid {
        border-color: red;
      }
      &:focus {
        outline: none;
      }
    }
  }
}

.error-message {
  display: block;
  position: absolute;
  font-weight: 200;
  font-size: 0.7rem;
  font-style: italic;
  color: red;
}
</style>
