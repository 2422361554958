<template>
  <v-row style="width: 100%;">
    <v-col cols="12" md="12" lg="12" xl="12" style="width: 100%;">
      <v-row style="display: block; width: 100%;">
        <v-col
          cols="12"
          class="d-flex justify-content-between"
          style="width: 100%; height: 15vh;"
        >
          <div
            class="avatar__structure"
            style="margin-left: 60px; align-self: center;"
          >
            <Avatar
              :context="{ ID_Structure: parseInt($route.params.structure_id) }"
              size="102"
              style="width: 15%;"
            />
          </div>
          <div
            class="title__adhesion"
            style="
              display: flex;
              width: 60%;
              align-items: center;
              justify-content: center;
            "
          >
            <h1>
              ADHÉSION - {{ structureData && structureData.NomStructure }}
              {{
                structureData && structureData.EST_AdhesionOnlineOuverte
                  ? ''
                  : '(fermé)'
              }}
            </h1>
          </div>
          <div
            class="logo__ffme"
            style="width: 15%; z-index: 18; height: 190%;"
          ></div>
        </v-col>
      </v-row>
      <v-stepper alt-labels v-model="step">
        <div style="font-size: 0.8rem; padding: 0.6rem; height: 16vh;">
          <p class="font-weight-bold">Mode d'emploi de l'adhésion en ligne:</p>

          <ul style="margin: 0; padding: 0;">
            <li>
              A) Saisie de vos cordonnées personnelles, choix de votre groupe,
              choix de l'assurance, des options d'assurance et envoi de votre
              certificat médical.
            </li>
            <li class="mt-4">
              B) {{ structureData && structureData.NomStructure }} réceptionne
              votre dossier et le vérifie.
            </li>
            <li class="mt-4">
              C) Lorsque le dossier sera validé, vous serez invité, par mail, à
              régler en ligne votre adhésion.
            </li>
          </ul>
        </div>

        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1"
            >Coordonnées</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">Groupe</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 3" step="3"
            >Licence et assurance</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 4" step="4"
            >Certificat médical</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 5" step="5"
            >Récapitulatif</v-stepper-step
          >
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <step1
              @onNext="step += 1"
              @onSetData="setUserData"
              :userRenouvellement="userRenouvellementInfo"
              :loading="loading"
              :pageLoading="pageLoading"
              :isDisabled="
                structureData && structureData.EST_AdhesionOnlineOuverte
              "
              @end-loading="endLoading"
            />
          </v-stepper-content>

          <v-stepper-content step="2">
            <step2
              v-if="step >= 2"
              @onNext="step += 1"
              @onPrevious="step -= 1"
              @onSetData="setUserData"
              :user="user"
            />
          </v-stepper-content>

          <v-stepper-content step="3">
            <step3
              @onNext="step += 1"
              @onPrevious="step -= 1"
              @onSetData="setUserData"
              :user="user"
            />
          </v-stepper-content>

          <v-stepper-content step="4">
            <step4
              @onNext="step += 1"
              @onPrevious="step -= 1"
              @onSetData="setUserData"
              :user="user"
            />
          </v-stepper-content>

          <v-stepper-content step="5">
            <step5
              v-if="step === 5"
              @validate="submit"
              @onSetPrice="setTotalPrice"
              @onPrevious="step -= 1"
              @goToStep="goToStep"
              :user="user"
            />
          </v-stepper-content>

          <v-stepper-content step="6">
            <step6 />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
    <v-overlay :value="pageLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import step1 from './step1.vue';
import step2 from './step2.vue';
import step3 from './step3.vue';
import step4 from './step4.vue';
import step5 from './step5.vue';
import step6 from './step6.vue';
import { mapActions, mapGetters } from 'vuex';
import { error_notification } from '@/plugins/notifications';
import Avatar from '@/components/Common/Common__Avatar.vue';
import {trimAndCapitalizeFirstLetter, trimAndUppercaseAllLetters} from "@/plugins/utils";
import { parse } from 'url';
import { getTransactionLicenceDematerialiseBody, insertTransaction } from '../../plugins/transactionService';

export default {
  components: { step1, step2, step3, step4, step5, step6, Avatar },

  data: () => ({
    step: 1,
    user: {},
    userInfo: {},
    userRenouvellementInfo: null,
    loading: true,

    structureData: null,
    pageLoading: true,
    totalPrice: 0,
    AllDataLicenceDematerialise: {},
    structure_id: null,
  }),

  props: {
    userRenouvellement: {
      type: Object,
      default: null,
    },
  },

  async created() {
    await this.setData();
    console.log(
      'this.$route.params.structure_id',
      this.$route.params.structure_id
    );
    (this.structure_id = parseInt(this.$route.params.structure_id)),
      (this.structureData = await this.getStructureByID(
        +this.$route.params.structure_id
      ));
    document.title = `${this.structureData.NomStructure}- Adhesion en ligne`;
  },

  computed: {
    ...mapGetters('saisons', ['currentSaison', 'nextSaison']),
  },

  methods: {
    ...mapActions('basics', ['getListTypeLicence', 'insertDocument']),
    ...mapActions('utilisateurs', [
      'saveTempUser',
      'getUtilisateurFullData',
      'createLemonwayPaiement',
    ]),
    ...mapActions('structure', ['getStructureByID']),

    setUserData(data) {
      this.user = { ...this.user, ...data };
    },

    setTotalPrice(price) {
      console.log('price step 5 data', price);
      this.totalPrice = price;
    },

    goToStep(step) {
      this.step = step;
    },

    capitalizeFirstLetter(string) {
      let str = string.split(' ');
      for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0]?.toUpperCase() + str[i]?.substr(1);
      }
      if (str.length > 1) {
        return str.join(' ');
      }
      return str[0];
    },
    endLoading() {
      this.pageLoading = false;
    },
    async submit() {
      let assurances = [...[this.user.assurance], ...this.user.optionAssurance];
      const optionAdhesionID =
        this.user.OptionAdhesion.length > 0
          ? this.user.OptionAdhesion.map((option) => ({
              ID: option.id,
            }))
          : [];
      let file = await this.uploadFile();
      console.log('file', file);

      let payload = {
        CT_Nom: trimAndUppercaseAllLetters(this.user.CT_Nom),
        CT_Prenom: trimAndCapitalizeFirstLetter(this.user.CT_Prenom),
        DN_DateNaissance: this.user?.DN_DateNaissance,
        CT_Email: this.user.CT_Email,
        CT_TelephoneFixe: this.user.TelephoneFixe,
        CT_TelephoneMobile: this.user.TelephoneMobile,
        LicenceNumero: `${this.user.LicenceNumero}`,
        ID_PaysNationalite: this.user.nationalite,
        EST_Civilite: this.user.EST_Civilite,
        NomMedecinTraitant: this.user.nomMedecinTraitant,
        UTI_Licence: JSON.stringify({ ID_TypeLicence: this.user.licence }),
        UTI_LicenceAssurances: JSON.stringify(assurances),
        ID_CertificatMedical: this.user.certicatsMedicalType,
        Z_DateCreationDemande: this.$moment().toISOString(),
        // ID_Departement: this.user.ADR_Departement.id,
        Status: 0,
        ID_PI_Stripe: null,
        ID_GroupeCreneauSaison: this.user.ID_GroupeSaisonCreneau,
        ADR_Adresse: JSON.stringify({
          ID_Pays: this.user.ADR_Pays,
          Adresse1: this.user.ADR_Adresse1,
          Adresse2: this.user.ADR_Adresse2,
          CodePostal: this.user.ADR_CodePostal,
          Ville: this.user.ADR_Ville,
        }),
        OptionAdhesion: JSON.stringify(optionAdhesionID),
        ID_Utilisateur: this.user.ID_Utilisateur,
        ID_Structure: parseInt(this.$route.params.structure_id),
      };
      try {
        let file = await this.uploadFile();
        payload.DOC_DocumentsTemp = {
          data: [
            {
              CheminDocument: file.url,
              NomDocument: file.filename,
              DateFinValiditeDocument: file.date,
            },
          ],
        };
        this.AllDataLicenceDematerialise = payload;
        console.log(
          `AllDataLicenceDematerialise====>>>`,
          this.AllDataLicenceDematerialise
        );

        //PAYEMENT LEMONWAY GET ALL DATA INFORMATION
        const dataLicenceDematerialise = JSON.stringify({
          context: `Adhésion ${this.structureData.NomStructure} - Saison ${this.currentSaison.id}`,
          data: this.AllDataLicenceDematerialise,
        });

        const responseLemonway = await this.createLemonwayPaiement({
          data: dataLicenceDematerialise,
        });

        const totalWithCommission = +(this.totalPrice.totalAPayer * 1.0072).toFixed(2) + 0.16;

        await insertTransaction(
          getTransactionLicenceDematerialiseBody(
          Math.ceil(totalWithCommission * 100),
            this.user.licence,
            this.user.ID_Utilisateur,
            this.currentSaison.id,
            optionAdhesionID
          )
        );

        const idPaiement =
          responseLemonway.data.insert_MKP_Lemonway.returning[0].id;
        console.log(`idPaiement LicenceDematerialise=>`, idPaiement);

        //data to send to LEMONWAY WEBINIT
        const commissionLemonway = +(
          (this.totalPrice.totalAPayer * 1.0072).toFixed(2) +
          0.16 -
          this.totalPrice.totalAPayer
        );
        const dataToSend = {
          returnUrl: `${process.env.VUE_APP_FRONT_URL}lemonway/payment-success`,
          // returnUrl: `http://localhost:8080/lemonway/payment-success`,
          errorUrl: `${process.env.VUE_APP_FRONT_URL}lemonway/payment-error`,
          cancelUrl: `${process.env.VUE_APP_FRONT_URL}lemonway/payment-cancel`,
          registerCard: false,
          reference: `Adhésion ${this.structureData.NomStructure} - Saison ${this.currentSaison.id}`,
          accountId: `${process.env.VUE_APP_LEMONWAY_DEFAULT_ACCOUNT}`,
          totalAmount: (this.totalPrice.totalAPayer + commissionLemonway) * 100,
          commissionAmount: 0,
          comment: `LicenceDematerialise:${idPaiement}`,
          autoCommission: false,
          cotisationNationale: this.totalPrice.cotisatioNationale * 100,
          cotisationCT: this.totalPrice.cotisationCT * 100,
          cotisationLigue: this.totalPrice.cotisationLigue * 100,
          cotisationClub:
            this.totalPrice.cotisationClub * 100 +
            this.totalPrice.totalOptionAdhesion * 100,
          totalAssurance: this.totalPrice.totalAssurance * 100,
        };
        // ------ PAYEMENT LEMONWAY GET ALL DATA INFORMATION

        // payload.ID_Payment = idPaiement;
        payload.PaymentInformation = JSON.stringify(dataToSend);

        const response = await this.saveTempUser({ user: [payload] });
        let payloadDoc = {
          CheminDocument: '',
          NomDocument: 'Gestion des certificats médicaux / Questionnaire santé',
          ID_Type_Document: 60,
          ID_Structure: this.structure_id,
          ID_Utilisateur: response.ID_Utilisateur,
          ID_News: null,
          DateFinValiditeDocument: new Date(`08/31/${this.currentSaison.id}`),
          DateValidation: null,
          EST_Actif: true,
          EST_DocumentValide: true,
          Categorie: 'questionnaire',
        };

        let responsedoc = await this.insertDocument(payloadDoc);
        console.log('response !!!!!!!!!', responsedoc);
        // this.$router.push({ name: 'page_de_paiement_ffme' });
        // await this.setPayment();
        this.step = 6;
      } catch (e) {
        console.log(e);
        error_notification('Oups, une erreur est survenue...');
      }

      console.log(payload);
    },

    async uploadFile(user) {
      console.log(this.user.file);
      const hash =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);

      let payload = {
        image: this.user.file.fileDataURL,
        key: `${this.$route.params.structure_id}_${hash}_${this.user.file.file.name}`,
        config: {
          S3_BUCKET: process.env.S3_BUCKET,
        },
      };
      console.log('file payload', payload);
      return await this.axios
        .post(process.env.VUE_APP_FFME_BACK_URL + '/api/media-upload', payload)
        .then((response) => {
          if (
            response.data &&
            response.data.location &&
            response.status &&
            response.status === 200
          ) {
            let freshFile = {
              url: response.data.location,
              filename: this.user.file.file.name,
              date: this.user.file.validationDate,
            };
            //console.log(freshFile)
            return freshFile;
          } else {
            error_notification('Upload unsuccessful');
          }
        })
        .catch((e) => {
          console.log('error', e);
        });
    },

    async setPayment() {
      console.log('totalPrice setPayment', this.totalPrice);
      try {
        const fetch = require('node-fetch');
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Content-Type',
        };

        const dataLicenceDematerialise = JSON.stringify({
          context: `Adhésion ${this.structureData.NomStructure} - Saison ${this.currentSaison.id}`,
          data: this.AllDataLicenceDematerialise,
        });
        console.log(
          'dataLicenceDematerialise alefa==>',
          dataLicenceDematerialise
        );

        const optionAdhesionID =
        this.user.OptionAdhesion.length > 0
          ? this.user.OptionAdhesion.map((option) => ({
              ID: option.id,
            }))
          : [];

          const totalWithCommission = +(this.totalPrice.totalAPayer * 1.0072).toFixed(2) + 0.16;

          await insertTransaction(
            getTransactionLicenceDematerialiseBody(
            Math.ceil(totalWithCommission * 100),
            this.user.licence,
            this.user.ID_Utilisateur,
            this.currentSaison.id,
            optionAdhesionID
          )
        );

        const responseLemonway = await this.createLemonwayPaiement({
          data: dataLicenceDematerialise,
        });
        const idPaiement =
          responseLemonway.data.insert_MKP_Lemonway.returning[0].id;
        console.log(`idPaiement LicenceDematerialise=>`, idPaiement);
        const totalToPay = +(
          this.totalPrice.totalAPayer * 1.0072 +
          0.16
        ).toFixed(2);

        //data to send to LEMONWAY WEBINIT
        const dataToSend = {
          returnUrl: `${process.env.VUE_APP_FRONT_URL}payment-success`,
          errorUrl: `${process.env.VUE_APP_FRONT_URL}payment-error`,
          cancelUrl: `${process.env.VUE_APP_FRONT_URL}payment-cancel`,
          registerCard: false,
          reference: `Adhésion ${this.structureData.NomStructure} - Saison ${this.currentSaison.id}`,
          accountId: `${process.env.VUE_APP_LEMONWAY_DEFAULT_ACCOUNT}`,
          totalAmount: totalToPay * 100,
          commissionAmount: 0,
          comment: `LicenceDematerialise:${idPaiement}`,
          autoCommission: false,
          cotisationNationale: this.totalPrice.cotisatioNationale * 100,
          cotisationCT: this.totalPrice.cotisationCT * 100,
          cotisationLigue: this.totalPrice.cotisationLigue * 100,
          cotisationClub:
            this.totalPrice.cotisationClub * 100 +
            this.totalPrice.totalOptionAdhesion * 100,
          totalAssurance: this.totalPrice.totalAssurance * 100,
        };
        console.log(`response dataToSend=>`, dataToSend);

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/bigData`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(dataToSend),
          }
        );

        const jsonBigData = await response;
        console.log(`response dataToSend=>`, jsonBigData.ok);
        if (jsonBigData.ok === true) {
          await this.callPayment();
        }

        return { ok: true };
      } catch (error) {
        console.log('erro r:', error);
        return { ok: false };
      }
    },

    async callPayment() {
      try {
        const fetch = require('node-fetch');
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Content-Type',
        };

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/webinit`,
          {
            method: 'get',
            headers: headers,
          }
        );
        const json = await response.json();

        if (json.webKitToken !== null) {
          window.open(
            `${process.env.VUE_APP_LEMONWAY_WEBKIT}/?moneyintoken=${json.webKitToken}`
          );
        }

        console.log('json response payment====>', json);
        return { ok: true };
      } catch (error) {
        console.log('erro r:', error);
        return { ok: false };
      }
    },

    async setData() {
      // await this.getListTypeLicence();
      console.log('currentSaison', this.currentSaison.id);

      this.userRenouvellementInfo = this.userRenouvellement && {
        ...this.userRenouvellement,
      };

      if (this.$route.params.user_id) {
        this.userInfo = await this.getUtilisateurFullData(
          this.$route.params.user_id
        );

        this.user = { ...this.userInfo.UTI_Utilisateurs[0] };
        this.userRenouvellementInfo = this.userInfo.UTI_Utilisateurs[0];
        this.loading = false;
        console.log('this.user licencedematerialise', this.user);
      } else {
        this.loading = false;
      }
      if (this.userRenouvellement && this.userRenouvellement.id) {
        this.user = { ...this.userRenouvellement };
        console.log('this.user licencedematerialise', this.user);
      }

      return;
    },

    getLastDateOfSeason() {
      var today = new Date();
      var lastDayOfMonth = new Date(today.getFullYear(), 8, 0);
      return lastDayOfMonth;
    },
  },
};
</script>

<style scoped>
.v-stepper__label {
  text-align: center !important;
  font-size: 0.9rem !important;
}
.logo__ffme {
  height: 100%;
  margin-top: 0px;
  background: url('~@/assets/images/FFME_LOGO_INSTIT_PRINCIPAL_FOND_CLAIR_RVB.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;
  width: 100%;
}
</style>
