<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :name="name">
    <v-row align-content="center">
      <v-col cols="12" md="3" v-if="label" class="label_column">
        <label for=""
          >{{ label }}{{ isRequired() && !disabled ? "*" : "" }}</label
        >
      </v-col>

      <v-col cols="12" :md="label ? 9 : 12">
        <v-radio-group
            v-model="currentValue"
            class="ma-0"
            v-bind="radioGroupProps"
            v-on:change="selectedChanged"
        >
          <v-radio
            v-for="option in options"
            :key="option.value"
            :label="option.label"
            :disabled="disabled"
            :value="
              option.value === 'true'
                ? true
                : option.value === 'false'
                ? false
                : option.value
            "
            class="pl-3"
          ></v-radio>
        </v-radio-group>
        <span class="input__error-message">{{ errors[0] }}</span>
      </v-col>
    </v-row>
  </validation-provider>
</template>

<script>

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    custom_class: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
    },
    checked: {
      type: [Boolean, String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    radioGroupProps: {
      type: Object,
      default: () => ({
        'row': true,
      }),
    }
  },

  data: function() {
    return {
      currentValue: this.checked,
    };
  },

  watch: {
    currentValue(val) {
      // allows us to use v-model on our input.
      this.$emit("input", val);
    },
  },

  methods: {
    isRequired() {
      return this.rules.indexOf("required") !== -1;
    },

    getClass(errors) {
      let futurClasses = "";
      if (errors[0]) {
        futurClasses += " is-invalid ";
      }

      futurClasses += this.custom_class;
      return futurClasses;
    },

    selectedChanged(data) {
      this.$emit("change", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    font-family: "Roboto";
    font-style: normal;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
