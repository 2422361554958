<template>
  <div style="display: flex; flex-direction: column;">
    <h4>Paiement</h4>
    <v-row class="p-3">
      <v-col cols="12">
        <span v-if="!alreadyPaid">
          Le tarif de l'extension de garantie d'assurance pour les voyages à
          l'étranger est de {{ TarifVoyage }}€ avec une commission bancaire de {{ commission }}€, soit un
          total de {{ totalTarifVoyage }}€
        </span>
        <span v-else>
          Comme vous avez déjà souscrit à une assurance voyage pour cette saison,
          aucuns frais supplémentaires, y compris les frais bancaires, ne seront appliqués.
          Veuillez cliquer sur le bouton suivant pour finaliser votre déclaration de voyage.
        </span>
      </v-col>
    </v-row>
    <div
      style="
        display: flex;
        flex-direction: row;
        margin-top: auto;
        justify-content: space-between;
      "
    >
      <v-btn
        type="submit"
        class="btn btn-primary"
        @click.prevent="$emit('on-previous')"
        >Précédent</v-btn
      >
      <v-btn
        type="submit"
        class="btn btn-primary"
        style="margin-left: auto; margin-top: ;"
        @click.prevent="$emit('on-submit')"
        >Suivant</v-btn
      >
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ASSURANCE_VOYAGE, getOptions, getPrices} from "@/plugins/productService";

export default {
  name: 'Step3',
  data: () => ({
    tarifVoyage: null,
  }),
  async created() {
    let optionVoyage = await getOptions({
      slug: ASSURANCE_VOYAGE,
    });
    this.tarifVoyage = await getPrices({
      options: optionVoyage[0]['id'],
      seasonId: this.selectedSaison.id,
      structureId: 1318,
    });
  },
  computed: {
    ...mapGetters("saisons", ["selectedSaison"]),
    TarifVoyage() {
      return this.tarifVoyage?.[0]?.value / 100;
    },
    commission() {
      const comm = this.TarifVoyage * 1.0072;
      const lemonw = parseFloat(comm) + 0.16;
      return (lemonw - this.TarifVoyage).toFixed(2);
    },
    totalTarifVoyage() {
      return (this.TarifVoyage * 1.0072 + 0.16).toFixed(2);
    },
  },
  methods: {
  },
  props: {
    alreadyPaid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
