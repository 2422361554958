<template>
  <FormLicenceDematerialise :userRenouvellement="user" />
</template>

<script>
import FormLicenceDematerialise from "@/views/LicenceDematerialise/LicenceDematerialise.vue";

export default {
  components: { FormLicenceDematerialise },
  props: {
    user: {
      type: Object,
    },
  },
  data: () => ({
    structureData: {},
  }),
};
</script>
