<template>
  <div
    ref="convention-affiliation-MA"
    class="convention-affiliation"
    style="background: #fcfeff !important;"
  >
    <!-- <div > -->
    <div
      class="pdf-to-print"
      style="
        position: absolute;
        left: 50%;
        margin-left: -297px;
        top: 0px;
        width: 595px;
        height: 841px;
        overflow: hidden;
      "
    >
      <div style="position: absolute; left: -10px; top: -15px;">
        <img src="~@/assets/FFME_EN_TETE.jpg" width="595" height="860" />
      </div>
      <div
        style="position: absolute; left: 390.65px; top: 8.76px;"
        class="cls_002"
      >
        <span class="cls_002">Convention "membre associé" - page 1/3</span>
      </div>
      <div
        style="position: absolute; left: 128.36px; top: 64.87px;"
        class="cls_003"
      >
        <span class="cls_003">CONVENTION D'AFFILIATION</span>
      </div>
      <div
        style="position: absolute; left: 170.83px; top: 93.22px;"
        class="cls_003"
      >
        <span class="cls_003">« MEMBRE ASSOCIÉ »</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 190.95px;"
        class="cls_004"
      >
        <span class="cls_004">Entre</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 219.45px;"
        class="cls_005"
      >
        <span class="cls_005"
          >La fédération française de la montagne et de l'escalade (FFME),
          fondée le 3 avril 1942 sous le titre fédération</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 234.45px;"
        class="cls_005"
      >
        <span class="cls_005"
          >française de la montagne (titre modifié le 13 juin 1987), a pour
          objet de regrouper les personnes morales et</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 249.45px;"
        class="cls_005"
      >
        <span class="cls_005"
          >physiques qui pratiquent, en France ou à l'étranger, les disciplines
          sportives et de plein air se déroulant en</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 264.45px;"
        class="cls_005"
      >
        <span class="cls_005">montagne et autres zones adaptées :</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 282.92px;"
        class="cls_005"
      >
        <span class="cls_005">- alpinisme,</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 297.92px;"
        class="cls_005"
      >
        <span class="cls_005">- canyonisme,</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 312.92px;"
        class="cls_005"
      >
        <span class="cls_005">- escalade,</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 327.92px;"
        class="cls_005"
      >
        <span class="cls_005">- expéditions,</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 342.92px;"
        class="cls_005"
      >
        <span class="cls_005">- randonnée de montagne,</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 357.92px;"
        class="cls_005"
      >
        <span class="cls_005">- raquettes à neige,</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 372.92px;"
        class="cls_005"
      >
        <span class="cls_005">- ski-alpinisme.</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 398.9px;"
        class="cls_005"
      >
        <span class="cls_005"
          >La fédération a pour objectif l'accès de tous à la pratique des
          activités physiques et sportives. Elle s'interdit</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 413.9px;"
        class="cls_005"
      >
        <span class="cls_005"
          >toute discrimination. Elle veille au respect, par ses membres et
          licenciés, de ces principes et de son code moral</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 428.9px;"
        class="cls_005"
      >
        <span class="cls_005">et valeurs fédérales.</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 447.37px;"
        class="cls_005"
      >
        <span class="cls_005"
          >Son siège social se situe au 8-10 quai de la Marne, 75019 Paris,
          représenté par Monsieur Pierre YOU, son</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 462.37px;"
        class="cls_005"
      >
        <span class="cls_005">président,</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 480.84px;"
        class="cls_005"
      >
        <span class="cls_005">Cette partie sera dénommée : la </span
        ><span class="cls_004">FFME</span><span class="cls_005">,</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 503.34px;"
        class="cls_005"
      >
        <span class="cls_005">Et</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 525.84px;"
        class="cls_004"
      >
        <span
          class="cls_004"
          v-text="MAaffiliation.structure && MAaffiliation.structure.name"
        />
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 548.34px;"
        class="cls_005"
      >
        <span class="cls_005">Dont le siège social est situé </span
        ><span
          class="cls_004"
          v-text="
            `${
              MAaffiliation.contact && MAaffiliation.contact.representantAdress
            } ${
              MAaffiliation.contact &&
              MAaffiliation.contact.representantAdressSupp
            } - ${
              MAaffiliation.contact && MAaffiliation.contact.representantCP
            } ${
              MAaffiliation.contact && MAaffiliation.contact.representantCountry
            }`
          "
        />
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 570.84px;"
        class="cls_005"
      >
        <span class="cls_005">Représenté par </span
        ><span
          class="cls_004"
          v-text="
            `${
              MAaffiliation.contact &&
              MAaffiliation.contact.representantLastName
            } ${
              MAaffiliation.contact &&
              MAaffiliation.contact.representantFirstName
            }`
          "
        />
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 593.34px;"
        class="cls_005"
      >
        <span class="cls_005"
          >Cette partie sera dénommée : « le membre associé ».</span
        >
      </div>
      <div
        style="position: absolute; left: 240.08px; top: 638.34px;"
        class="cls_011"
      >
        <span class="cls_011">Il est convenu ce qui suit :</span>
      </div>
      <div
        style="position: absolute; left: 58.61px; top: 731.82px;"
        class="cls_004 article"
      >
        <span class="cls_004">ARTICLE 1 - OBJET</span>
      </div>
    </div>
    <!-- page 2 -->
    <div
      class="pdf-to-print"
      style="
        position: absolute;
        left: 50%;
        margin-left: -297px;
        top: 851px;
        width: 595px;
        height: 841px;
        overflow: hidden;
        background: #fcfeff !important;
      "
    >
      <div
        style="position: absolute; left: 390.65px; top: 8.76px;"
        class="cls_002"
      >
        <span class="cls_002">Convention "membre associé" - page 2/3</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 42.45px;"
        class="cls_005"
      >
        <span class="cls_005"
          >La présente convention a pour but de rappeler les conditions
          d'association à la FFME prévues par les statuts et</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 57.45px;"
        class="cls_005"
      >
        <span class="cls_005">règlements fédéraux notamment :</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 75.92px;"
        class="cls_005"
      >
        <span class="cls_005">- L'article 3 des statuts et</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 90.92px;"
        class="cls_005"
      >
        <span class="cls_005">- L'article 10 du règlement intérieur.</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 109.39px;"
        class="cls_005"
      >
        <span class="cls_005"
          >Elle constitue un préalable à l'association à la FFME.</span
        >
      </div>
      <div
        style="position: absolute; left: 58.61px; top: 146.89px;"
        class="cls_004 article"
      >
        <span class="cls_004">ARTICLE 2 - ENGAGEMENTS DU MEMBRE ASSOCIÉ</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 182.14px;"
        class="cls_005"
      >
        <span class="cls_005"
          >Les droits et obligations du membre associé sont précisés au
          règlement intérieur de la FFME, disponible sur le</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 197.14px;"
        class="cls_005"
      >
        <span class="cls_005">site internet de la fédération : </span
        ><A HREF="http://www.ffme.fr">www.ffme.fr</A>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 215.61px;"
        class="cls_005"
      >
        <span class="cls_005">Le membre associé s'engage notamment à :</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 238.11px;"
        class="cls_005"
      >
        <span class="cls_005"
          >- Respecter scrupuleusement l'ensemble des lois et règlements en
          vigueur,</span
        >
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 253.11px;"
        class="cls_005"
      >
        <span class="cls_005"
          >- Respecter et à promouvoir auprès de ses adhérents le respect de
          l'ensemble des dispositions</span
        >
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 268.11px;"
        class="cls_005"
      >
        <span class="cls_005"
          >contenues dans les statuts, règlements et code moral de la
          FFME,</span
        >
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 283.11px;"
        class="cls_005"
      >
        <span class="cls_005"
          >- Promouvoir auprès de ses adhérents les actions organisées par la
          FFME,</span
        >
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 298.11px;"
        class="cls_005"
      >
        <span class="cls_005"
          >- Participer aux réunions institutionnelles de la FFME,</span
        >
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 313.11px;"
        class="cls_005"
      >
        <span class="cls_005"
          >- Prêter assistance dans la mesure de ses moyens d'actions à toute
          demande de la FFME,</span
        >
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 328.11px;"
        class="cls_005"
      >
        <span class="cls_005"
          >- Payer la cotisation d'affiliation fixée chaque année par la
          FFME.</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 346.59px;"
        class="cls_005"
      >
        <span class="cls_005"
          >Le membre associé peut se prévaloir du titre : </span
        ><span class="cls_004">« Membre associé à la FFME »</span
        ><span class="cls_005"> et peut utiliser le logo de la</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 361.59px;"
        class="cls_005"
      >
        <span class="cls_005"
          >FFME dans le respect de la charte graphique fédérale.</span
        >
      </div>
      <div
        style="position: absolute; left: 58.61px; top: 395.06px;"
        class="cls_004 article"
      >
        <span class="cls_004">ARTICLE 3 - ENGAGEMENTS DE LA FFME</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 430.31px;"
        class="cls_005"
      >
        <span class="cls_005">La FFME s'engage notamment à :</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 452.81px;"
        class="cls_005"
      >
        <span class="cls_005"
          >- Assurer la représentation du membre associé au conseil
          d'administration selon les statuts et</span
        >
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 467.81px;"
        class="cls_005"
      >
        <span class="cls_005">règlements fédéraux,</span>
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 482.81px;"
        class="cls_005"
      >
        <span class="cls_005"
          >- Fournir au membre associé un code structure lui donnant accès à
          l'intranet fédéral,</span
        >
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 497.81px;"
        class="cls_005"
      >
        <span class="cls_005"
          >- Informer le membre associé des réunions le concernant et ou sa
          compétence est requise,</span
        >
      </div>
      <div
        style="position: absolute; left: 111.11px; top: 512.81px;"
        class="cls_005"
      >
        <span class="cls_005"
          >- Adresser au membre associé les lettres d'information
          fédérales.</span
        >
      </div>
      <div
        style="position: absolute; left: 58.61px; top: 546.28px;"
        class="cls_004 article"
      >
        <span class="cls_004">ARTICLE 4 - DROITS D'AFFILIATION</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 581.53px;"
        class="cls_005"
      >
        <span class="cls_005"
          >Le montant du droit d'affiliation est fixé sur décision de
          l'assemblée générale fédérale.</span
        >
      </div>
      <div
        style="position: absolute; left: 58.61px; top: 619.03px;"
        class="cls_004 article"
      >
        <span class="cls_004">ARTICLE 5 - DURÉE</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 654.28px;"
        class="cls_005"
      >
        <span class="cls_005"
          >La présente convention prend effet à la date de signature et est
          valable jusqu'au 31 août. Elle est reconduite</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 669.28px;"
        class="cls_005"
      >
        <span class="cls_005"
          >par tacite reconduction sous réserve d'être à jour de
          cotisation.</span
        >
      </div>
      <div
        style="position: absolute; left: 58.61px; top: 702.76px;"
        class="cls_004 article"
      >
        <span class="cls_004">ARTICLE 6 - RÉSILIATION</span>
      </div>
    </div>
    <!-- page 3 -->
    <div
      class="pdf-to-print"
      style="
        position: absolute;
        left: 50%;
        margin-left: -297px;
        top: 1702px;
        width: 595px;
        height: 841px;
        overflow: hidden;
        background: #fcfeff !important;
      "
    >
      <div
        style="position: absolute; left: 390.65px; top: 8.76px;"
        class="cls_002"
      >
        <span class="cls_002">Convention "membre associé" - page 3/3</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 42.45px;"
        class="cls_005"
      >
        <span class="cls_005"
          >Chaque partie pourra dénoncer la présente convention par lettre
          recommandée avec accusé de réception, en</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 57.45px;"
        class="cls_005"
      >
        <span class="cls_005"
          >respectant un préavis d'un moins un mois avant la fin de la date
          d'expiration.</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 75.92px;"
        class="cls_005"
      >
        <span class="cls_005"
          >En cas de manquement de l'une des parties à l'une de ses obligations
          résultant des statuts et règlements de la</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 90.92px;"
        class="cls_005"
      >
        <span class="cls_005"
          >FFME ou de la présente convention, l'autre partie pourra la mettre en
          demeure d'exécuter ses obligations par</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 105.92px;"
        class="cls_005"
      >
        <span class="cls_005"
          >lettre recommandée avec accusé de réception.</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 124.39px;"
        class="cls_005"
      >
        <span class="cls_005"
          >La réception de cette lettre fait courir un délai d'un mois à
          l'expiration duquel si la partie concernée ne s'est pas</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 139.39px;"
        class="cls_005"
      >
        <span class="cls_005"
          >conformée à ses obligations, la résiliation de la convention aura
          lieu de plein droit.</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 157.86px;"
        class="cls_005"
      >
        <span class="cls_005"
          >La FFME pourra résilier de droit la présente convention 1 mois après
          la mise en demeure émanant de la</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 172.86px;"
        class="cls_005"
      >
        <span class="cls_005"
          >fédération, si le membre associé ne s'est pas acquitté de sa
          cotisation annuelle.</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 191.34px;"
        class="cls_005"
      >
        <span class="cls_005"
          >Conformément aux statuts de la FFME, la résiliation de cette
          convention d'association aura pour conséquence</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 206.34px;"
        class="cls_005"
      >
        <span class="cls_005">la perte de qualité de membre de la FFME.</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 224.81px;"
        class="cls_005"
      >
        <span class="cls_005"
          >Elle sera caduque de plein droit au cas où de nouvelles dispositions
          législatives ou réglementaires ne</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 239.81px;"
        class="cls_005"
      >
        <span class="cls_005">permettraient pas de la respecter.</span>
      </div>
      <div
        style="position: absolute; left: 58.61px; top: 273.28px;"
        class="cls_004 article"
      >
        <span class="cls_004">ARTICLE 7 - COMPÉTENCE JURIDIQUE</span>
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 308.53px;"
        class="cls_005"
      >
        <span class="cls_005"
          >Tout différend relatif à la présente convention, à défaut de
          règlement à l'amiable par le bureau de la FFME</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 323.53px;"
        class="cls_005"
      >
        <span class="cls_005"
          >relèvera de la compétence du Tribunal de grande instance de
          Paris.</span
        >
      </div>
      <div
        style="position: absolute; left: 51.11px; top: 379.51px;"
        class="cls_005"
      >
        <span class="cls_005"
          >Fait en 2 exemplaires, à Paris, le
          {{ $moment(new Date()).format("DD/MM/YYYY") }}</span
        >
      </div>
      <div
        style="position: absolute; left: 53.27px; top: 434.17px;"
        class="cls_005"
      >
        <span class="cls_005">Pour la FFME</span>
      </div>
      <div
        style="position: absolute; left: 299.15px; top: 434.17px;"
        class="cls_005"
      >
        <span class="cls_005">Pour le membre associé</span>
      </div>
      <div
        style="position: absolute; left: 53.27px; top: 455.77px;"
        class="cls_005"
      >
        <span class="cls_005">Le président,</span>
      </div>
      <div
        style="position: absolute; left: 299.15px; top: 455.77px;"
        class="cls_005"
      >
        <span class="cls_005">Le président,</span>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { bus } from "@/main";
import { mapState } from "vuex";

export default {
  name: "Test",
  created() {
    bus.$on("print-convention-affiliation-MA", async () => {
      return await this.print();
    });
  },

  computed: {
    ...mapState({
      MAaffiliation: (state) => state.structure.MAaffiliation,
    }),
  },
  methods: {
    async print() {
      this.$refs["convention-affiliation-MA"].style.display = "block";
      let pdf = new jsPDF({
        orientation: "portrait",
      });
      let sources = document.getElementsByClassName("pdf-to-print");
      let promisePdf = [];
      sources.forEach((source) => {
        return promisePdf.push(
          html2canvas(source, {
            scale: 2,
            letterRendering: 1,
            logging: true,
            useCORS: true,
          })
        );
      });
      await Promise.all(promisePdf).then((canvases) => {
        canvases.forEach((canvas, index) => {
          const imgData = canvas.toDataURL("image/jpeg");
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          if (index !== 0) pdf.addPage();
          pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
        });
      });
      pdf.save("convention d'affiliation");
      this.$refs["convention-affiliation-MA"].style.display = "none";
    },
  },
};
</script>

<style scoped>
.convention-affiliation {
  display: none;
}
.article {
  width: 85%;
  background: lightslategrey;
}
/* preconfig */
span.cls_002 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(193, 193, 193);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_002 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(193, 193, 193);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_003 {
  font-family: Arial, serif;
  font-size: 26.3px;
  color: rgb(79, 129, 188);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
div.cls_003 {
  font-family: Arial, serif;
  font-size: 26.3px;
  color: rgb(79, 129, 188);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_004 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
div.cls_004 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_005 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_005 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_011 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: underline;
}
div.cls_011 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_006 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(128, 182, 219);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_006 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(128, 182, 219);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_010 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(19, 19, 249);
  font-weight: normal;
  font-style: normal;
  text-decoration: underline;
}
div.cls_010 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(19, 19, 249);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_008 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(128, 182, 219);
  font-weight: normal;
  font-style: italic;
  text-decoration: none;
}
div.cls_008 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(128, 182, 219);
  font-weight: normal;
  font-style: italic;
  text-decoration: none;
}
span.cls_012 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(19, 19, 249);
  font-weight: normal;
  font-style: normal;
  text-decoration: underline;
}
div.cls_012 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(19, 19, 249);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
</style>
