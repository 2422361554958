var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Etape 3 - Licence et assurance")]),_c('ValidateForm',{attrs:{"showFieldObl":_vm.mandatory},on:{"validated":_vm.next}},[_c('v-row',[_c('v-col',{staticClass:"mx-3",attrs:{"cols":"12","md":"12"}},[_c('h6',[_vm._v("Type de licence*")]),_c('v-radio-group',{attrs:{"dense":""},on:{"change":_vm.licenceChange},model:{value:(_vm.licenceTypeSelected),callback:function ($$v) {_vm.licenceTypeSelected=$$v},expression:"licenceTypeSelected"}},_vm._l((_vm.licences),function(licence,index){return _c('v-radio',{key:index,attrs:{"label":((licence.NomTarif) + " (" + (licence.MontantUnitaire) + "€)"),"value":licence.id}})}),1),_c('h6',{staticStyle:{"margin-top":"5px"}},[_vm._v("Assurance*")]),_c('v-radio-group',{attrs:{"dense":""},on:{"change":_vm.assuranceChange},model:{value:(_vm.assurancesSelected),callback:function ($$v) {_vm.assurancesSelected=$$v},expression:"assurancesSelected"}},_vm._l((_vm.assurancesObligatoires),function(assu,index){return _c('v-radio',{key:index,attrs:{"label":assu.LIST_TarifCategorie &&
              assu.LIST_TarifCategorie.AssuranceType &&
              assu.LIST_TarifCategorie.AssuranceType.NomTypeAssurance +
                ' ( +' +
                assu.MontantUnitaire +
                '€)',"value":assu.LIST_TarifCategorie &&
              assu.LIST_TarifCategorie.AssuranceType &&
              assu.LIST_TarifCategorie.AssuranceType.id}})}),1),(_vm.isResponsabiliteCivile)?_c('v-alert',{staticClass:"mt-2",attrs:{"border":"top","color":"red lighten-2","dark":""}},[_vm._v(" En choisissant une assurance de type \"Responsabilité Civile\", vous ne pouvez pas souscrire à des options d’assurance ")]):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('h6',[_vm._v(" Options facultatives (uniquement avec les assurances base, base+ et base++) ")]),_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.assurancesOptions),function(assu,index){return _c('v-checkbox',{key:index,staticStyle:{"margin-top":"0"},attrs:{"dense":"","disabled":_vm.isResponsabiliteCivile,"label":assu.LIST_TarifCategorie.AssuranceType.NomTypeAssurance +
                ' ( +' +
                assu.MontantUnitaire +
                '€)',"value":assu.LIST_TarifCategorie.AssuranceType.id},model:{value:(_vm.assurancesOptionsSelected),callback:function ($$v) {_vm.assurancesOptionsSelected=$$v},expression:"assurancesOptionsSelected"}})}),1),_c('div',{staticClass:"mt-4 assuranceIJ"},[_c('h6',[_vm._v("Assurance complémentaire")]),_c('v-radio-group',{attrs:{"row":"","dense":""},model:{value:(_vm.assurancesCompSelected),callback:function ($$v) {_vm.assurancesCompSelected=$$v},expression:"assurancesCompSelected"}},_vm._l((_vm.assurancesComp),function(assu,index){return _c('v-radio',{key:index,attrs:{"disabled":_vm.isResponsabiliteCivile,"label":assu.LIST_TarifCategorie.AssuranceType.NomTypeAssurance +
                  ' ( +' +
                  assu.MontantUnitaire +
                  '€)',"value":assu.LIST_TarifCategorie.AssuranceType.id}})}),1)],1),(this.age <= 18)?_c('QuestionnaireSante',{attrs:{"pdfType":_vm.datenaissance,"showButton":false},on:{"onValidateQS":_vm.addCMItem,"onQuestionnaireChange":_vm.onChangeRadio}}):_vm._e()],1)],1)],1),_c('v-row',{staticClass:"my-4",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"btn btn-gray",attrs:{"text":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('onPrevious')}}},[_vm._v("Retour")]),_c('v-btn',{staticClass:"btn btn-primary ml-auto",staticStyle:{"margin-left":"auto"},attrs:{"type":"submit","disabled":!_vm.canContinue}},[_vm._v("Suivant")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }