<template>
  <div>
    <h3>Validation</h3>

    <v-row>
      <v-col cols="12"
        >Votre demande a bien été prise en compte. Nous vous recontacterons dans
        les plus brefs délais.</v-col
      >
    </v-row>
    <v-row>
      <v-col cols="12" v-if="$route.params.user_id">
        <a href="" @click="retour"> Retour sur mon espace personnel myFFME</a>
      </v-col>
      <v-col cols="12" v-else>
        <a href="https://www.ffme.fr/"> Retour sur le site officiel de ffme</a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import selectInput  from "@/components/utils/select"
// import InputTelephone  from "@/components/utils/inputTelephone"
// import DateDeNaissance  from "@/components/utils/datePicker"
// import countrySelect  from "@/components/utils/select__countries"

export default {
  components: {},

  data: () => ({}),

  async created() {
    await this.setData();
  },

  computed: {},

  methods: {
    async setData() {
      return;
    },
    retour() {
      this.$router.push({ name: "Home" });
    },
  },

  props: {
    user: {
      type: Object,
    },
  },
};
</script>

<style scopped>
label {
  margin-bottom: 0 !important;
  font-size: 0.8rem;
}
</style>
